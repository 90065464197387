$transition: 0.3s;
@mixin md_sm {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
@mixin xxs {
  @media only screen and (max-width: 625px) {
    @content;
  }
}

.categories-page {
  .save-card {
    position: sticky;
    top: 75px;
    z-index: 1;
    .card {
      padding: 12px;
    }
    @include md_sm {
      top: 193px;
    }
    @include xxs {
      top: 5px;
    }
  }
  .categories-wrapper {
    border-radius: 6px;
    padding: 5px;
    .wrapper-element {
      margin: 10px 0;
      padding-block: 12px;
      border-radius: 15px;
      background: white;
      transition: $transition;
      padding-inline-end: 20px;
      [data-theme="dark"] & {
        background-color: #233654aa;
      }
      &:hover {
        box-shadow: 0 0 10px 0 #9c9c9ca8;
      }
      .category {
        height: 45px;
        padding: 10px;
        border-radius: 6px;
        min-width: 320px;
        margin-bottom: 0;
        display: flex;
        justify-content: stretch;
        align-items: center;
        background: white;
        transition: $transition;
        [data-theme="dark"] & {
          background-color: #233654aa;
          svg {
            fill: white;
          }
        }
        & > div {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .drag svg circle {
          color: #c4c4c4;
        }
        li {
          width: 100%;
          > div {
            border: 0;
          }
        }
        h5 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
        }
        .drop-menu {
          text-align: end;
          max-width: 100px;
        }
        button {
          color: black;
        }
        .first-section {
          gap: 10px;
          display: flex;
          align-items: center;
          .order {
            row-gap: 5px;
            button {
              pointer-events: all;
            }

            button {
              width: 18px;
              height: 18px;
              background: #fa8440;
              border-radius: 100%;
              color: #fff;
              font-size: 11px;
              border: 1px solid #dfdfdf;
              display: flex;
              align-items: center;
              justify-content: center;
              &:disabled {
                opacity: 0.3;
              }
              &:hover {
                opacity: 0.8;
              }
              & + button {
                background: #ed5531;
              }
            }
          }
        }
      }

      & > div {
        & > .sub-list {
          .category {
            background: rgba(68, 68, 68, 0.05);
            border-right: 1.228px solid #444 !important;
            [data-theme="dark"] & {
              background: #44444463;
            }
            &:hover {
              box-shadow: 0 0 10px 0 #9c9c9ca8;
            }
          }
          .sub-list {
            .category {
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: -20px;
                translate: 0 -50%;
                width: 20px;
                height: 100%;
                background: url(../assets/svgs/dnd-arrow.svg) no-repeat;
                background-position: top;
                [data-theme="dark"] & {
                  filter: brightness(0) invert(0.7);
                }
              }
            }
          }
        }
      }
      & > .category {
        background-color: transparent !important;
      }
      label.cursor-pointer {
        // background: rgba(186, 186, 186, 0.279);
        border-radius: 50%;
        // padding: 5px;
        aspect-ratio: 1;
        display: inline-flex;
        transition: $transition;
        // &:hover {
        //   background: rgba(186, 186, 186, 0.179);
        // }
      }
    }
    .store-logo {
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
    }
    .sub-cats-check {
      display: none;
    }
    .sub-cats-check:checked {
      ~ div div div label.cursor-pointer {
        rotate: 180deg;
      }
      ~ div .sub-list {
        max-height: 0;
        opacity: 0;
        overflow: -moz-hidden-unscrollable;

        &,
        & > * {
          max-height: 0;
          margin: 0;
          opacity: 0;
          transition: $transition;
          display: none;
          animation: disappear $transition ease 1;
          @keyframes disappear {
            from {
              max-height: 1000vh;
              display: block;
              opacity: 1;
            }
            to {
              opacity: 0;
              display: none;
              max-height: 0;
            }
          }
        }
      }
    }

    .sub-list {
      position: relative;
      max-height: 1000vh;
      padding-inline-start: 20px;
      transition: $transition;
      > div > div {
        margin: 10px 0;
      }
      // &::after {
      //   background: rgba(217, 217, 217, 0.294);
      //   content: "";
      //   height: calc(100% - 13px);
      //   position: absolute;
      //   right: -1rem;
      //   top: 0;
      //   width: 2px;
      //   z-index: 0;
      // }

      > div .card {
        position: relative;
        // &::before {
        //   background-color: rgba(217, 217, 217, 0.294);
        //   content: "";
        //   height: 2px;
        //   position: absolute;
        //   right: -18px;
        //   top: 50%;
        //   transform: translateY(-50%);
        //   width: calc(2.5rem - 25px);
        // }
      }
    }
    .wrapper-element > div > .sub-list {
      &::after {
        //   display: none;
        //   height: calc(100% - 10px);
      }
    }
  }
}

.modal-dialog.productDataModal:not(.bankModal) .acc-form .card {
  background: transparent;
  border: 0;
  box-shadow: none !important;
  padding: 0;
  margin: 0;
}

.rotate-180 {
  rotate: 180deg;
}
