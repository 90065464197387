@use "/src/styles/variables.scss" as *;
.singleStore {
  :global {
    .img-container {
      position: relative;
      height: 400px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
      .img-badge {
        background-color: #233654;
        position: absolute;
        inset: 20px 20px auto auto;
      }
    }

    .branches-label {
      border-bottom: none;
      padding: 26px 20px;
    }

    .card-label {
      font-weight: 500;
      margin-bottom: 5px;
      [data-theme="dark"] & {
        color: #fff;
      }
    }
    .category-badge {
      background-color: #ebebeb;
      width: max-content;
      color: #233654;
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 500;
      [data-theme="dark"] & {
        background-color: #ebebeb1a;
        color: #fff;
      }
    }
    .store-card {
      border: 1px solid #e4e4e4;
      [data-theme="dark"] & {
        border: 1px solid $border-color_1;
      }
      p {
        color: #9d9d9d;
        font-size: 14px;
      }
    }
  }
}
