@media (max-width: 1500px) {}

@media (max-width: 1200px) {
  .acc-form .nav {
    column-gap: 0 !important;
    row-gap   : 15px !important;
  }

  .listView .productCard {
    padding: 20px 10px !important;
  }

  .listView .productCard .p-data .form-group {
    width: 49% !important;
    order: 0 !important;
  }

  .listView .productCard .p-data .form-group:last-child {
    width: 100% !important;
  }

  .content-wrap {
    /* width: 100% !important; */
  }
}

@media (max-width: 1024px) {
  .main-sidebar {
    transform : translateX(150%) !important;
    transition: all 0.3s !important;
  }

  .main-head .logo {
    display        : flex !important;
    justify-content: space-between !important;
    align-items    : center !important;
    padding        : 15px !important;
    background     : #233654 !important;
  }

  .main-head .logo .btn {
    height       : 40px !important;
    line-height  : 40px !important;
    border-radius: 5px !important;
    font-size    : 13px !important;
    padding      : 0 20px !important;
  }

  .burgerBtn .btn {
    display: none !important;
  }

  .head-wrp {
    padding: 18px !important;
  }

  .burgerBtn .toggleSidebar {
    width      : 35px !important;
    height     : 35px !important;
    line-height: 35px !important;
    filter     : brightness(0) invert(1) !important;
  }

  .burgerBtn {
    margin: 0 !important;
  }

  .content-wrap {
    width  : 100% !important;
    margin : 180px auto !important;
    padding: 28px !important;
  }

  /* ***** Sidebar **** */
  .sidebarToggled .content-wrap {
    margin : 167px auto !important;
    width  : 100% !important;
    padding: 15px !important;
  }

  .sidebarToggled .main-head .head-wrp {
    padding: 18px !important;
  }

  .sidebarToggled .main-sidebar {
    transform: none !important;
    width    : 292px !important;
  }

  .sidebarToggled .main-sidebar .logo .logo-info,
  .sidebarToggled .main-sidebar .side-nav ul li a span,
  .sidebarToggled .main-sidebar .side-actions a span {
    opacity : 1 !important;
    position: relative !important;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a,
  .sidebarToggled .main-sidebar .side-actions a,
  .sidebarToggled .main-sidebar .side-actions {
    text-align     : initial !important;
    justify-content: normal !important;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a svg,
  .sidebarToggled .main-sidebar .side-actions a svg {
    margin: 0 0 0 13px !important;
  }

  .overlay-s.fire {
    opacity   : 1 !important;
    visibility: visible !important;
    transition: all 0.3s !important;
  }

  .filter-wrp {
    margin : 0 !important;
    padding: 30px 0 10px !important;
    width  : 100% !important;
  }

  .main-sidebar .logo {
    justify-content: center !important;
    padding        : 20px !important;
  }

  .timeWrap span {
    width      : 46px !important;
    height     : 46px !important;
    line-height: 46px !important;
    font-size  : 15px !important;
  }

  .searchWrap .form-group {
    margin: 0 !important;
  }

  .offScroll {
    /* overflow: hidden !important; */
  }

  .switcher .knobs:before {
    transition: all 0.3s !important;
  }

  .stores-selection .dropdown-menu {
    left : 0 !important;
    right: auto !important;
  }

  .table-wrap .card-body header+div {
    overflow: auto hidden !important;
  }

  .thumbs .thumb {
    width : 95px !important;
    height: 95px !important;
  }

  .thumbs .thumb .btn-remove {
    width  : 20px !important;
    height : 20px !important;
    padding: 2px !important;
  }

  .sidebarToggled .main-sidebar .side-nav>ul {
    overflow  : hidden !important;
    overflow-y: auto !important;
    padding   : 0 0 0 15px !important;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a~.children {
    max-height: 400px !important;
  }

  .sidebarToggled .main-sidebar .stores-selection .dropdown-toggle {
    grid-column-gap: 10px !important;
    column-gap     : 10px !important;
    min-width      : 205px !important;
    font-size      : 13px !important;
    width          : 100% !important;
    justify-content: flex-start !important;
  }

  .stores-selection .dropdown-menu {
    right: 0 !important;
    left : auto !important;
  }

  .sidebarToggled .main-sidebar .side-nav ul li a,
  .sidebarToggled .main-sidebar .side-actions a {
    border-radius: 3px !important;
  }

  .sidebarToggled .main-sidebar .side-nav {
    padding: 0 10px !important;
  }

  .main-sidebar .side-nav ul li a.active {
    padding: 9px 12px !important;
  }

  .logo .logo-info {
    filter: none !important;
  }

  .sidebarToggled .main-sidebar .logo .logo-info {
    filter: brightness(0) invert(1) !important;
  }

  .orders-list li .order-data>div {
    flex-wrap: wrap !important;
    row-gap  : 5px !important;
  }

  .orders-list li .order-img {
    width    : 50px !important;
    height   : 50px !important;
    min-width: 50px !important;
  }

  .orders-list li {
    padding    : 15px 0 !important;
    align-items: flex-start !important;
  }

  .main-head .stores-selection {
    display: block !important;
  }

  .main-head .stores-selection .dropdown-menu {
    left     : 50% !important;
    right    : auto !important;
    top      : 60px !important;
    transform: translateX(-50%) !important;
    width    : 90% !important;
  }

  .main-head .stores-selection .dropdown {
    position: unset !important;
  }

  .head-wrp {
    position: relative !important;
  }

  .main-sidebar .stores-selection {
    display: none !important;
  }

  .main-sidebar .logo {
    margin-bottom: 20px !important;
  }

  .main-sidebar .side-nav {
    height: calc(100% - 110px) !important;
  }

  .head-wrp .burgerBtn {
    display: none !important;
  }

  .searchWrap .form-group input.form-control {
    width: 250px !important;
  }

  .breadcrumb {
    margin: 20px 0 !important;
  }

  .no-store {
    padding: 20px 0 !important;
  }

  .no-store .preview {
    height: auto !important;
  }

  .no-store .preview img {
    width     : auto !important;
    height    : auto !important;
    object-fit: unset !important;
  }
}

@media (max-width: 768px) {
  .authWrap .au-data {
    display: none !important;
  }

  .content-wrap {
    margin : 210px auto !important;
    padding: 28px !important;
  }

  .authWrap .dropdown {
    min-width: 35px !important;
  }

  .authWrap .dropdown-toggle svg {
    display: none !important;
  }

  .noificationWrap .menu-item-has-children .dropdown-toggle span,
  .noificationWrap .menu-item-has-children>span {
    width      : 35px !important;
    height     : 35px !important;
    line-height: 35px !important;
  }

  .badgo {
    width      : 18px !important;
    height     : 18px !important;
    line-height: 18px !important;
    font-size  : 11px !important;
    top        : -8px !important;
    right      : -8px !important;
  }

  .noificationWrap .btn {
    overflow: visible !important;
  }

  .noificationWrap .menu-item-has-children .dropdown-toggle span svg {
    width: 19px !important;
  }

  .authWrap .dropdown-menu {
    min-width: 160px !important;
  }

  .stores-selection .dropdown-toggle {
    min-width: 180px !important;
  }

  .searchWrap .form-group {
    min-width: 300px !important;
  }

  .searchWrap .form-group select.form-control {
    width: 80px !important;
  }

  .head-wrp {
    column-gap: 20px !important;
    flex-wrap : wrap !important;
    gap       : 15px !important;
  }

  .searchWrap .form-group input.form-control {
    width    : 150px !important;
    font-size: 12px !important;
  }

  .searchWrap .form-group select.form-control {
    min-width: auto !important;
    padding  : 0 15px !important;
    font-size: 12px !important;
  }

  .ordersFilterSidebar {
    width: 100% !important;
  }

  .order-btns,
  .orderLayout {
    display: none !important;
  }

  .modal-dialog {
    max-width: calc(100% - 40px) !important;
  }

  .productsTable td {
    min-width: 136px !important;
    font-size: 12px !important;
    padding  : 15px !important;
  }

  .productsTable {
    overflow  : hidden !important;
    overflow-x: auto !important;
  }

  .productsTable table {
    table-layout: auto !important;
  }

  .productsTable td:first-child {
    min-width: 250px !important;
    max-width: 250px !important;
  }

  .productsTable td strong {
    font-size: inherit !important;
  }

  .productsTable .product a {
    overflow     : hidden !important;
    text-overflow: ellipsis !important;
    white-space  : nowrap !important;
    max-width    : 150px !important;
  }

  .productsTable .product img {
    min-width: 60px !important;
  }

  .productsTable .product {
    column-gap: 15px !important;
  }

  .productsListHeader {
    flex-wrap: wrap !important;
  }

  .filter-toolbar__layout {
    display: none !important;
  }

  .filter-toolbar__action .btn,
  .filter-toolbar__settings .btn {
    font-size: 0 !important;
    gap      : 0 !important;
    width    : 40px !important;
    min-width: 40px !important;
  }

  .filter-toolbar__settings .btn {
    padding        : 0 !important;
    justify-content: center !important;
    height         : 40px !important;
  }

  .filter-toolbar__search .form-control {
    width: 0 !important;
  }

  .listView .col-12 {
    width: 50% !important;
  }

  .listView .productCard {
    display: block !important;
  }

  .listView .productCard .p-img {
    height : 300px !important;
    width  : 100% !important;
    display: block !important;
  }

  .listView .productCard .p-img input[type="checkbox"] {
    position: absolute !important;
    left    : 20px !important;
    top     : 20px !important;
    width   : 20px !important;
    height  : 20px !important;
  }

  .listView .productCard .p-img .mySwiper2 .img-holder {
    height: 100% !important;
  }

  .listView .productCard .p-img .swiper-thumbs {
    display: block !important;
  }

  .listView .productCard .p-data {
    padding: 15px 10px 0 !important;
    width  : 100% !important;
  }

  .listView .productCard .p-data .form-group {
    width: 100% !important;
  }
}

@media (max-width: 625px) {
  .content-wrap {
    padding: 15px !important;
  }

  .thumbs .thumb {
    width : 100% !important;
    height: 100% !important;
  }

  .thumbs {
    column-gap: 15px !important;
    row-gap   : 15px !important;
  }

  .chart-body.pieChart .recharts-wrapper>svg {
    width : 55% !important;
    float : left !important;
    height: 140px !important;
  }

  .chart-body.pieChart {
    height: 120px !important;
  }

  .customers-list .c-item .i-img {
    width : 75px !important;
    height: 75px !important;
    margin: 0 auto 12px !important;
  }

  .card-head h4,
  .reports-blocks .card h4 {
    font-size: 14px !important;
  }

  .card-head p,
  .customers-list .c-item .i-data p {
    font-size: 10px !important;
  }

  .customers-list .c-item .i-data h4,
  .project-item .i-head a {
    font-size: 12px !important;
  }

  .searchWrap .form-control {
    min-width: 140px !important;
    height   : 38px !important;
    width    : 140px !important;
  }

  .timeWrap span {
    width      : 35px !important;
    height     : 35px !important;
    line-height: 35px !important;
    font-size  : 11px !important;
    margin     : 0 2px !important;
  }

  .searchWrap .form-group button {
    height     : 29px !important;
    line-height: 29px !important;
    width      : 29px !important;
  }

  .content-wrap {
    margin: 159px auto !important;
  }

  .filter-wrp .form-group .form-control {
    min-width: 100% !important;
  }

  .control-wrp .btn.btn-big {
    height       : 46px !important;
    line-height  : 46px !important;
    margin-bottom: 30px !important;
    font-size    : 13px !important;
  }

  .control-wrp .card {
    flex-wrap      : wrap !important;
    justify-content: center !important;
  }

  .control-wrp .card h5 {
    // margin-bottom: 15px !important;
  }

  .table-pager .pagination {
    width      : 100% !important;
    overflow   : hidden !important;
    overflow-x : auto !important;
    display    : block !important;
    white-space: nowrap !important;
  }

  .table-pager .pagination .page-item {
    display: inline-block !important;
  }

  input[type="checkbox"],
  input[type="radio"],
  input[type="checkbox"]:checked::before,
  input[type="radio"]:checked::before {
    // width        : 20px !important;
    // height       : 20px !important;
    // border-radius: 3px !important;
  }

  input[type="checkbox"]:checked::before,
  input[type="radio"]:checked::before {
    line-height: 20px !important;
    font-size  : 14px !important;
  }

  input[type="checkbox"]:checked::after,
  input[type="radio"]:checked::after {
    animation: click-wave1 0.65s !important;
  }

  @keyframes click-wave1 {
    0% {
      height  : 20px !important;
      width   : 20px !important;
      opacity : 0.35 !important;
      position: relative !important;
    }

    100% {
      height : 30px !important;
      width  : 30px !important;
      opacity: 0 !important;
      margin : -6px !important;
    }
  }

  .rdt_TableCell div,
  .rdt_TableCol * {
    font-size: 13px !important;
  }

  .modal-dialog {
    min-height: 100% !important;
    max-width : 100% !important;
  }

  .productDataModal .modal-footer .btn {
    min-width: 120px !important;
  }

  .controllerHolder {
    max-width: 330px !important;
  }

  .modal-content {
    // border-radius: 0 !important;
    width     : 100% !important;
    /* height : 100% !important; */
    position  : absolute !important;
    top       : 0 !important;
    right     : 0 !important;
    overflow  : hidden !important;
    overflow-y: auto !important;
  }

  .modal-body {
    display        : flex !important;
    flex-wrap      : wrap !important;
    justify-content: center !important;
    padding        : 15px !important;
    overflow-x     : auto !important;
  }

  .modal-wrp .modal-icon {
    width : 80px !important;
    height: 80px !important;
  }

  .modal-wrp h4 {
    font-size: 18px !important;
  }

  .modal-btns .btn {
    height     : 38px !important;
    line-height: 38px !important;
    font-size  : 15px !important;
    padding    : 0 30px !important;
  }

  .content-wrap,
  .sidebarToggled .content-wrap {
    margin-top: 0 !important;
  }

  .main-head {
    position: relative !important;
  }

  .card-inner .checker input[type="checkbox"]:after {
    animation: click-wave 0.65s !important;
  }

  .card-inner .checker input[type="checkbox"] {
    width : 26px !important;
    height: 26px !important;
  }

  .card-inner .checker input[type="checkbox"]::before {
    line-height: 26px !important;
    width      : 26px !important;
    height     : 26px !important;
    font-size  : 17px !important;
  }

  .card-inner {
    min-height: 120px !important;
  }

  .images-wrp .img-holder {
    width    : 95px !important;
    min-width: 95px !important;
  }

  .stores-selection .dropdown-menu {
    width     : 335px !important;
    min-height: 165px !important;
  }

  .stores-selection .dropdown-menu .drop-scrollable::before,
  .stores-selection .dropdown-menu a:hover img,
  .searchWrap {
    display: none !important;
  }

  .stores-selection .dropdown-menu a {
    font-size: 14px !important;
    width    : 50% !important;
  }

  .stores-selection .dropdown-menu a img {
    width: 110px !important;
    left : 15px !important;
  }

  .stores-selection .dropdown-menu {
    padding: 20px 0 0 0 !important;
  }

  .drop-scrollable {
    padding: 0 15px !important;
  }

  .order-top .order-table {
    flex-wrap    : wrap !important;
    padding      : 0 !important;
    column-gap   : 0 !important;
    row-gap      : 0 !important;
    border-radius: 0 !important;
  }

  .order-top .order-table li {
    width  : 50% !important;
    border : 1px solid #dfdfdf !important;
    padding: 15px !important;
  }

  .order-top .order-table li:last-child {
    width: 100% !important;
  }

  .order-top .orderslist ul li .order-img {
    width : 70px !important;
    height: 60px !important;
  }

  .order-top .orderslist ul li {
    column-gap: 15px !important;
  }

  .order-bottom h4 {
    font-size: 14px !important;
    padding  : 15px !important;
  }

  .order-bottom h4 span {
    font-size: inherit !important;
  }

  .order-bottom ul {
    padding: 15px !important;
  }

  .noificationWrap .dropdown-menu {
    min-width: 300px !important;
    left     : -30px !important;
    right    : auto !important;
  }

  .stores-selection .dropdown .dropdown-toggle {
    display      : block !important;
    overflow     : hidden !important;
    text-overflow: ellipsis !important;
    max-width    : 100px !important;
    min-width    : 100px !important;
    white-space  : nowrap !important;
    padding      : 0 0 0 15px !important;
  }

  .stores-selection .dropdown a {
    color: #000 !important;
  }

  .stores-selection .dropdown span {
    min-width    : 40px !important;
    overflow     : visible !important;
    width        : 40px !important;
    height       : 40px !important;
    border-radius: 4px !important;
  }

  .stores-selection .dropdown span .rippon {
    width : 34px !important;
    height: 20px !important;
    top   : -5px !important;
  }

  .stores-selection .dropdown span i {
    font-size: 8px !important;
  }

  .head-wrp .left-actions {
    column-gap: 3px !important;
  }

  .darkSwitcher {
    margin: 0 !important;
  }

  .searchToggle {
    display: inline-block !important;
  }

  .searchWrap {
    position        : absolute !important;
    left            : 0 !important;
    top             : 83% !important;
    padding         : 10px !important;
    background-color: #fff !important;
    display         : block !important;
    width           : 100% !important;
    border-top      : 1px solid #eee !important;
    border-bottom   : 1px solid #eee !important;
    transition      : 0.3s !important;
    opacity         : 0 !important;
    visibility      : hidden !important;
  }

  .searchWrap .form-control {
    width: 100% !important;
  }

  .searchWrap.active {
    opacity   : 1 !important;
    visibility: visible !important;
    top       : 100% !important;

    &.dark {
      background-color: #1B293F !important;
      border          : none !important;
    }
  }

  .searchWrap .form-group {
    min-width: 100% !important;
  }

  .searchWrap .form-group input.form-control {
    width: 200px !important;
  }

  .ordersActions {
    column-gap: 15px !important;
  }

  .ordersActions a.btn {
    width: 100% !important;
  }

  .ordersActions .ordersServices,
  .ordersActions .btn-black {
    width: 50% !important;
  }

  .settingsHead .card {
    flex-wrap: wrap !important;
    row-gap  : 15px !important;
  }

  .settingsHead .nav {
    flex-wrap     : nowrap !important;
    overflow      : hidden !important;
    overflow-x    : auto !important;
    padding-bottom: 10px !important;
  }

  .settingsHead .nav::-webkit-scrollbar {
    width : 3px !important;
    height: 3px !important;
  }

  .settingsHead .nav::-webkit-scrollbar-track {
    background-color: #f7f7f7 !important;
    border-radius   : 15px !important;
  }

  .settingsHead .nav::-webkit-scrollbar-thumb {
    background-color: #fc6b14 !important;
    border-radius   : 15px !important;
  }

  .settingsHead .nav .nav-link {
    white-space  : nowrap !important;
    padding      : 7px 20px !important;
    border-radius: 50px !important;
    font-size    : 12px !important;
  }

  .card {
    padding: 15px !important;
  }

  .settingItem .card {
    padding   : 15px !important;
    min-height: 143px !important;
  }

  .settingItem h4 {
    font-size: 12px !important;
  }

  .settingItem span {
    font-size : 10px !important;
    max-height: 31px !important;
    min-height: 31px !important;
  }

  .no-store .card {
    flex-wrap     : wrap !important;
    flex-direction: column-reverse !important;
    text-align    : center !important;
    row-gap       : 20px !important;
  }

  .no-store .card .s-img {
    width : 120px !important;
    height: 120px !important;
  }

  .reports-blocks .inner .i-icon {
    position: absolute !important;
    left    : 10px !important;
    top     : 10px !important;
    width   : 50px !important;
  }

  .reports-blocks .inner .i-icon img {
    width : 100% !important;
    height: 100% !important;
  }

  .reports-blocks .inner {
    column-gap     : 0 !important;
    width          : 100% !important;
    text-align     : right !important;
    justify-content: flex-end !important;
  }

  .ordersActions {
    flex-wrap     : wrap !important;
    flex-direction: row-reverse !important;
  }

  .ordersActions .ordersServices {
    width: 100% !important;
  }

  .ordersActions>.btn {
    width    : auto !important;
    margin   : -65px 0 0 !important;
    font-size: 12px !important;
    min-width: 130px !important;
  }

  .ordersActions .ordersServices .dropdown {
    width: 50% !important;
  }

  .ordersActions .btn-black {
    justify-content: center !important;
    width          : auto !important;
    height         : 38px !important;
    min-width      : 130px !important;
  }

  .ordersServices .dropdown-toggle {
    height   : 38px !important;
    font-size: 12px !important;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon div {
    flex-wrap: wrap !important;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon::after,
  .productDataModal .form-group .form-body .col-12 .marketCoupon div::after {
    display: none !important;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon h4 {
    width        : 100% !important;
    font-size    : 16px !important;
    font-family  : "Bahij-semibold" !important;
    margin-bottom: 8px !important;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon p {
    font-size  : 12px !important;
    font-family: "Bahij-light" !important;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon {
    margin-top     : 30px !important;
    justify-content: space-between !important;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon .switcher .switcher-input:checked~.layer {
    background-color: #49ca6e !important;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon .switcher .layer,
  .productDataModal .form-group .form-body .col-12 .marketCoupon .switcher .knobs {
    position: absolute !important;
  }

  .productDataModal .nav {
    width         : 100% !important;
    order         : 1 !important;
    flex-wrap     : nowrap !important;
    overflow      : hidden !important;
    overflow-x    : auto !important;
    margin-top    : 15px !important;
    max-width     : 100% !important;
    display       : block !important;
    white-space   : nowrap !important;
    padding-bottom: 10px !important;
  }

  .productDataModal .modal-header {
    flex-wrap: wrap !important;
  }

  .productDataModal .nav .btn {
    white-space: nowrap !important;
    padding    : 0 15px !important;
  }

  .productDataModal .nav .nav-item {
    display: inline-block !important;
  }

  .productDataModal .nav::-webkit-scrollbar {
    width : 4px !important;
    height: 4px !important;
  }

  .productDataModal .nav::-webkit-scrollbar-track {
    border-radius: 15px !important;
    background   : #d5d5d5 !important;
  }

  .productDataModal .nav::-webkit-scrollbar-thumb {
    border-radius: 15px !important;
    background   : #336df4 !important;
  }

  .productDataModal .form-group .form-body .col-12 .marketCoupon div:first-child {
    max-width: 70% !important;
  }

  .modal {
    overflow: hidden !important;
  }

  .orderTop ul {
    flex-wrap: wrap !important;
    row-gap  : 15px !important;
  }

  .orderTop ul li {
    width     : 100% !important;
    text-align: inherit !important;
  }

  .orderTop ul li span {
    justify-content: flex-start !important;
  }

  .orderBlocks .card {
    min-height: auto !important;
  }

  .historyInfo li .h-info {
    flex-wrap: wrap !important;
  }

  .historyInfo li .h-info span {
    width     : 100% !important;
    margin-top: 7px !important;
    color     : #000 !important;
  }

  .productCard .p-img {
    height: auto !important;
  }

  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div {
    flex-wrap      : wrap !important;
    justify-content: space-between !important;
    text-align     : center !important;
  }

  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div label.form-uploader,
  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div .emptyPhotos,
  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div .thumbs {
    width : 48% !important;
    margin: 0 !important;
  }

  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div label.form-uploader {
    min-height: 250px !important;
  }

  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div.thumb img {
    border-radius: 10px !important;
  }

  .brandDataModal .form-group {
    margin: 0 !important;
    width : 100% !important;
  }

  .brandDataModal .modal-footer .btn {
    min-width: 150px !important;
  }

  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div label.form-uploader,
  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div .emptyPhotos,
  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div .thumbs {
    width: 100% !important;
  }

  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div label.form-uploader {
    min-height: 150px !important;
  }

  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div .emptyPhotos {
    display        : flex !important;
    justify-content: center !important;
    flex-direction : column !important;
    align-items    : center !important;
    margin-top     : 0 !important;
    padding-top    : 20px !important;
  }

  .brandDataModal .modal-content {
    height: auto !important;
  }

  .brandDataModal .modal-footer .btn {
    min-width: 100px !important;
  }

  .brandDataModal .form-group .form-body .multiUploadWrapper .form-group div .thumbs {
    margin-top: 50px !important;
  }

  .filter-toolbar__selection label {
    gap: 10px !important;
  }

  .filter-toolbar__selection label span {
    font-size: 13px !important;
  }

  .filter-toolbar {
    padding-inline: 15px !important;
    gap: 15px !important;
  }

  .filter-params .param-item,
  .filter-params .btn {
    font-size: 12px !important;
  }

  .filter-params {
    gap: 10px !important;
  }

  .listView .col-12 {
    width: 100% !important;
  }

  .ordersActions {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 425px) {
  .images-wrp .img-holder {
    width    : 73px !important;
    min-width: 73px !important;
  }
  .filter-toolbar{
    gap: 10px !important;
    .btn.btn-blue{
      padding: 0 10px !important;
    }
  }
}

@media (max-width: 375px) {
  .thumbs .thumb {
    width : 100% !important;
    height: 100% !important;
  }
  .filter-toolbar{
    gap: 5px !important;
    .btn.btn-blue{
      padding: 0 5px !important;
    }
  }

  .head-wrp {}

  .left-actions {
    gap: 0px !important;
  }
}