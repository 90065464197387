@mixin flex($direction: row, $justify: flex-start, $align: center) {
  display        : flex;
  flex-direction : $direction;
  justify-content: $justify;
  align-items    : $align;
}

@mixin xxxs {
  @media only screen and (max-width: 375px) {
    @content;
  }
}

@mixin xxss {
  @media only screen and (max-width: 425px) {
    @content;
  }
}

@mixin xxs {
  @media only screen and (max-width: 576px) {
    @content;
  }
}

@mixin xs {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin xs-big {
  @media only screen and (max-width: 625px) {
    @content;
  }
}

@mixin sm {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

@mixin md-tabled {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin md {
  @media only screen and (max-width: 1199px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (max-width: 1400px) {
    @content;
  }
}

@mixin dir {
  body[dir="ltr"] & {
    @content;
  }
}

@mixin pseudo {
  content : "";
  width   : 100%;
  height  : 100%;
  position: absolute;
  top     : 0;
  left    : 0;
  @include flex(column, center, center);
}

@mixin pseudo_center {
  top      : 50%;
  left     : 50%;
  transform: translateY(-50%) translateX(-50%);
}

@mixin keyframes($name, $name1) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

/* CUSTOM LOADER */

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform   : $transform;
  transform        : $transform;
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation   : $animation;
  animation        : $animation;
}

@mixin keyframes($name, $name1) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin opacity($opacity) {
  -webkit-opacity: $opacity;
  -moz-opacity   : $opacity;
  opacity        : $opacity;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius   : $radius;
  border-radius        : $radius;
}