@mixin lightDark($lightColor, $darkColor, $type) {
  #{$type}: $lightColor !important;
  [data-theme="dark"] & {
    #{$type}: $darkColor !important;
  }
}

.MultiLangsWrapper {
  // padding-top: 5px;
  &:after {
    display: none;
  }
  [data-theme="dark"] & label,
  [data-theme="dark"] & .label {
    color: white;
  }
  .label {
    justify-content: space-between;
    // margin-top: -12px;
  }
  .acc-form & .nav {
    margin-bottom: 0;
    grid-column-gap: 0;
    column-gap: 0;
    justify-content: unset;
    .nav-item {
      width: auto;
      .nav-link {
        background-color: transparent;
        border-radius: 0;
        text-align: center;
        padding: 3px 10px;
        transition: all 0.3s;
      }
    }
  }
  label.fields-wrapper {
    display: block;
  }
  .basic-single.required:before,
  .langs-tabs > .required:before {
    content: "*";
    color: red;
    font-weight: lighter;
    font-size: 1rem;
    position: absolute;
    left: -2px;
    top: -5px;
    box-shadow: 0 0 0 2px #f5f7ff;
    color: #fff;
    background: red;
    border-radius: 100%;
    aspect-ratio: 1;
    height: 10px;
    line-height: 1.2;
    text-align: center;
  }
  .basic-single {
    .select__control {
      border: 0;
      min-height: auto;
      .select__value-container {
        overflow: unset;
      }
      .select__indicator-separator.css-1okebmr-indicatorSeparator {
        display: none;
      }
      .select__single-value.css-qc6sy-singleValue,
      .select__menu > * > * > * {
        text-transform: capitalize;
      }
      .select__indicator.select__dropdown-indicator {
        padding: 5px !important;
      }
    }
  }
  .langs-tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin: 0 7px 7px;
    .lang-tab {
      text-align: center;
      height: 25px;
      padding: 4px;
      border-radius: 2px;
      text-transform: capitalize;
      cursor: pointer;
      aspect-ratio: 1;
      font-size: 0.8rem;
      text-overflow: ellipsis;
      @include lightDark(#e4e4e4, #4e5257, background);
      @include lightDark(#000, #fff, color);
      &.active {
        background: #e74c3c !important;
        color: white !important;
      }
    }
  }
  h5 {
    margin-bottom: 0 !important;
    // .form-group {
    //   margin-bottom: 11px;
    // }
  }
}
