.content-wrap {
  .orderBlocks {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }
  .form-control {
    option {
      [data-theme="dark"] & {
        color: white;
      }
    }
  }
}
