@mixin flex($direction: row, $justify: center, $align: center) {
    display        : flex;
    flex-direction : $direction;
    justify-content: $justify;
    align-items    : $align;
}

@mixin lightDark($lightColor, $darkColor, $type) {
    #{$type}: $lightColor !important;

    [data-theme="dark"] & {
        #{$type}: $darkColor !important;
    }
}

.image-upload-wrapper {
    .thumb {
        display: block !important;
    }
}

.products-view .outOfStockAlerts {
    background   : #fff7ec;
    color        : #fc6b14;
    fill         : #fc6b14;
    border       : 1px solid #ffe8cc;
    border-radius: 8px;
    padding      : 5px 14px;
    margin-block : 10px;
    gap          : 5px;
    @include flex(_, flex-start);

    svg {
        fill: inherit;
    }

    button {
        padding        : 0;
        height         : auto;
        color          : #a95d2e;
        text-decoration: underline;
    }

    .alerts {
        margin-inline-start: 5px;
        gap                : 5px;
        @include flex();

        .alert-div {
            display      : flex;
            border-radius: 6px;
            border       : 1px solid #9a9a9a;
            padding      : 3px 7px;

            &:hover {
                background: #0000002e;
            }

            a {
                width        : 80px;
                overflow     : hidden;
                text-overflow: ellipsis;
                text-wrap    : nowrap;
                color        : black;
            }
        }
    }

    [data-theme="dark"] & {
        background: #f0aa42;
        color     : #cd5b13;
        fill      : #cd5b13;
    }
}

.filterLength {
    position       : absolute;
    right          : 0;
    padding        : 5px;
    background     : #fc6b14;
    color          : white;
    font-size      : 13px;
    line-height    : 1;
    border-radius  : 50%;
    aspect-ratio   : 1;
    height         : 20px;
    width          : 20px;
    display        : flex;
    justify-content: center;
    align-items    : center;
    translate      : 42%;
    box-shadow     : -3px 0px 7px 2px #00000036;
}

.variations-pane {
    .variations-add-title div {
        font-size: 11px;
        color    : red;
    }

    .error-hint {
        color: yellow;
    }

    &>div>.row {
        row-gap: 18px;
    }

    & .form-body label {
        font-size: 18px !important;
        @include lightDark(#444444, #fff, color);
    }

    .variations-option .variations-wrapper input[type="text"],
    .table-wrapper>div input[type="text"],
    input[type="date"],
    input[type="number"],
    div[data-tag="allowRowEvents"] select.form-control {
        padding      : 0 15px !important;
        width        : 100%;
        border-style : solid;
        white-space  : pre;
        text-overflow: ellipsis;
        overflow     : hidden;
        background   : transparent !important;
        border-color : transparent !important;
        transition   : 0.3s !important;

        @include lightDark(black, white, color);

        &.border-danger {
            border-color: #dc3545 !important;
        }

        &:focus,
        &:active {
            padding      : 4px 10px !important;
            border-radius: 20px;
            @include lightDark(#fff, #2c3135, background);
            // @include lightDark(#eef1ff, #3c4045, border-color);
            border: 2px solid #fc6b14 !important;
        }
    }

    .variations-add,
    .variations-option {
        border       : 1px solid #eef1ff;
        border-radius: 20px;
        padding      : 20px;

        @include lightDark(white, #2c3135, background);
        @include lightDark(#eef1ff, #3c4045, border-color);

        //--------------------
        .variations-add-title {
            font-size    : 24px !important;
            margin-bottom: 28px !important;
        }

        .variations-wrapper {
            flex-wrap: wrap;
            gap      : 10px 20px;
            @include flex(row, flex-start, center);

            //---------------------
            .variation-option {
                padding      : 5px 20px;
                gap          : 10px;
                background   : #f5f7ff;
                border-radius: 45px;
                @include lightDark(black, white, color);
                @include lightDark(#eef1ff, #3c4045, background);
                @include flex();

                //----------------
                button {
                    background: transparent;
                    border    : 0;
                }

                svg {
                    position : unset;
                    top      : initial;
                    right    : initial;
                    transform: none;
                    @include lightDark(black, white, fill);
                }
            }
        }

        input[type="text"].variation-option-input {
            padding: 0 !important;
        }
    }

    .color-snippet {
        width          : 26px;
        height         : 26px;
        border         : 1px solid #f5f7ff;
        box-shadow     : inset 1px 1px 3px rgba(0, 0, 0, 0.83);
        border-radius  : 13px;
        background-size: cover !important;
        background     : #3c404549;
    }

    .variation-child {
        row-gap    : 0;
        margin     : 25px auto;
        align-items: center;

        .MultiLangsWrapper {
            flex      : 1;
            margin-top: -35px;
        }

        label {
            margin-bottom: 0 !important;
        }

        .row {
            align-items: center;
            min-height : 80px;
        }

        .btn.btn-blue.images-btn {
            max-height     : 50px !important;
            border-radius  : 0;
            width          : 45px !important;
            border-radius  : 0 !important;
            display        : flex;
            justify-content: center;
            align-items    : center;

            svg {
                top      : 50%;
                left     : 50%;
                right    : initial;
                translate: -50% 0%;
            }

            &:last-child {
                border-radius: 35px 0 0 35px !important;
            }
        }

        .btn.btn-blue {
            aspect-ratio : 1;
            height       : 57px;
            width        : auto !important;
            padding      : 0;
            line-height  : 1;
            border-radius: 20px;
        }

        .color-options-div {
            display        : flex;
            justify-content: center;
            align-items    : center;

            div {
                color    : inherit;
                font-size: inherit;
            }

            &:has(input[type="checkbox"]:checked) {
                &:after {
                    content   : "";
                    position  : absolute;
                    top       : 0;
                    left      : 0;
                    width     : 100%;
                    height    : 100%;
                    z-index   : 999;
                    background: #2c2c2c3f;
                }
            }
        }

        .color-select {
            position     : absolute;
            top          : 19px;
            left         : -17px;
            column-count : 4;
            z-index      : 9999;
            padding      : 20px;
            background   : #f5f7ff;
            border       : 3px solid #f5f7ff;
            border-radius: 10px;
            flex-wrap    : wrap;
            min-width    : 200px;
            max-width    : 230px;
            display      : block;
            cursor       : default;
            box-shadow   : 0px 3px 8px #0000002b;

            &:after {
                content     : " ";
                position    : absolute;
                top         : -13px;
                /* At the bottom of the tooltip */
                left        : 5%;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent #f5f7ff transparent;
            }

            .color-snippet {
                width : 35.94px;
                height: 35.94px;

                border       : 1.38235px solid #f1f1f1;
                box-shadow   : 0px 0px 1.38235px rgba(0, 0, 0, 0.1);
                border-radius: 4px !important;

                &.selected {
                    position: relative;

                    &:after {
                        content     : "✔";
                        position    : absolute;
                        top         : 50%;
                        left        : 50%;
                        translate   : -50% -45%;
                        font-size   : 1.2rem;
                        color       : white;
                        accent-color: white;
                        text-shadow : 0 0 2px rgba(0, 0, 0, 0.809);
                    }
                }
            }
        }

        .color-options-div:focus .color-select {
            display: block !important;
        }

        .add-option {
            border-radius: 35px;
            max-height   : 50px;
            border       : 1px solid;
            transition   : 0.3s;
            flex         : 1;
            @include lightDark(white, #3c4045, background);
            @include lightDark(#eef1ff, #4c4c4c, border-color);
            @include flex();

            &:focus-within {
                border-color: #fc6b14 !important;
            }

            input {
                flex-grow : 1;
                font-size : inherit !important;
                padding   : 16px !important;
                border    : 0 !important;
                background: transparent !important;
                min-width : 70px;
            }

            select {
                position: absolute;
            }

            .color-options-div {
                margin       : 0;
                align-self   : stretch;
                appearance   : none;
                font-size    : 16px;
                padding      : 16px;
                border-radius: 35px 0 0 35px;
                @include flex();
                @include lightDark(black, white, color);
                @include lightDark(#f5f7ff, #4e5257, background);

                .color-snippet {
                    border-radius: 8px;
                }
            }
        }

        .variations-option {
            padding      : 16px;
            border-radius: 4rem;
            min-height   : 79px;

            .variations-wrapper .variation-option {
                padding-inline: 10px;

                button svg {
                    fill: #cd1919 !important;
                }
            }
        }

        .image-uploader {
            width        : 50px;
            max-height   : 50px;
            margin-bottom: 0;
            border-radius: 0;
            @include lightDark(#f5f7ff, #4e5257, background);

            label {
                margin: 0 !important;
                border: 0;

                div {
                    padding   : 0;
                    text-align: center;
                    @include flex();
                    @include lightDark(white, #4e5257, background);
                }
            }

            img {
                width : 70%;
                height: auto;
            }

            .img-pat {
                text-align: center;
            }
        }
    }

    .variations-option .variations-wrapper input[type="text"] {
        height: 35px;
        width : 100px;
    }

    .table-wrapper {
        margin-top: 1rem;

        div.table-hint {
            margin-bottom: -28px;
            color        : red;
            font-size    : 11px;
            max-width    : 37vw;

            @media screen and (max-width: 786px) {
                max-width    : unset;
                margin-bottom: 20px;
            }
        }

        .filter-wrp {
            margin-top: -110px !important;
            width     : auto !important;
            padding   : 0 !important;
        }

        div[role="heading"]>div {
            >div:first-of-type {
                display: none;
            }

            button {
                @include flex();
                width: 114px;
                gap  : 15px;
                @include lightDark(#000, #fff, color);
                @include lightDark(#fff, #2c3135, background);
                @include lightDark(#eef1ff, #3c4045, border-color);

                svg {
                    [data-theme="dark"] & {
                        fill: #e4e4e4;
                    }
                }
            }
        }

        div[role="heading"],
        div[role="heading"]>div,
        header {
            background: transparent;

            [data-theme="dark"] & {
                background: transparent;

                >div {
                    background: transparent;
                    color     : white;
                }
            }
        }

        header+div {
            margin-top: -50px;
        }

        >div {
            svg {
                position : unset;
                transform: unset;
            }

            .form-group .btn {
                display        : flex;
                justify-content: space-around;
                align-items    : center;
            }

            button[disabled] {
                filter: grayscale(1);
            }
        }

        div[role="table"] {
            background: transparent;

            &>div:first-child {
                background: transparent;

                >div {
                    border-radius: 50px;
                    @include lightDark(#eef1ff, #3c4045, background);
                    @include lightDark(#eef1ff, #4c4c4c, border-color);
                }
            }

            .rdt_TableCol {
                padding             : 7px !important;
                padding-inline-start: 0 !important;

                &>div>div {
                    overflow     : hidden;
                    white-space  : nowrap;
                    text-overflow: ellipsis;
                }
            }

            .rdt_TableCol:first-child,
            .rdt_TableCell:first-child {
                padding-inline-start: 25px !important;
                padding-top         : 10px !important;
                padding-bottom      : 10px !important;
            }

            .rdt_TableCell:first-child {

                &,
                &+div {
                    position  : sticky;
                    z-index   : 2;
                    right     : 40px;
                    background: #f9f9f9;

                    [data-theme="dark"] & {
                        background: #1b293f;
                    }
                }

                &+div+div {
                    z-index: 1;
                }

                right: 0px !important;
            }

            // .rdt_TableCell:first-child + div {
            //   // box-shadow: -12px -1px 10px 0px rgba(132, 134, 136, 0.2);
            //   &:after {
            //     content: "";
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 20px;
            //     height: 100%;
            //     background: rgba(132, 134, 136, 0.2);
            //     filter: blur(10px);
            //   }
            // }
            .rdt_TableCol:first-child {
                padding-inline: 15px 15px !important;
            }
        }

        .rdt_Pagination span {
            direction: ltr;
        }
    }
}

.productCard {
    .p-img {
        position     : relative;
        background   : #efefef;
        border-radius: 12px 12px 0px 0px;
        height       : 300px;
        overflow     : hidden;
        transition   : 0.3s ease;

        .swiper {
            cursor: grab;
        }

        img {
            width     : 100%;
            height    : 100%;
            object-fit: contain;
            font-size : 0;
        }

        .btn {
            position       : absolute;
            z-index        : 1;
            left           : 20px;
            top            : 15px;
            display        : flex;
            align-items    : center;
            column-gap     : 12px;
            font-size      : 12px;
            min-width      : 130px;
            padding        : 0;
            justify-content: center;
            border         : 0;
        }

        .corner-actions {
            position: absolute;
            right   : 20px;
            top     : 20px;
            z-index : 1;
            display : flex;

            .drag {
                cursor: move;
            }

            [data-theme="dark"] & {
                svg * {
                    fill: #e4e4e4;
                }

                input[type="checkbox"] {
                    border: 1px solid #dfdfdf;
                }
            }
        }

        input[type="checkbox"] {
            border-radius: 4px;
            border       : 1px solid #dfdfdf;

            &::before {
                border-radius: 4px;
                transition   : 0.3s;
            }
        }
    }

    .p-data {
        padding: 15px 10px;

        .form-group {
            margin-bottom: 10px;

            div {
                display        : flex;
                align-items    : center;
                justify-content: space-between;

                >svg {
                    right: 15px;
                }

                .currency {
                    font-family: "Bahij-light";
                    position   : absolute;
                    font-size  : 12px;
                    left       : 15px;

                    &::before {
                        content   : "";
                        position  : absolute;
                        width     : 1px;
                        height    : 12px;
                        background: #e4e4e4;
                        right     : -10px;
                        top       : 4px;
                    }
                }

                .qty {
                    position  : absolute;
                    left      : 15px;
                    column-gap: 10px;

                    span {
                        font-size  : 12px;
                        font-family: "Bahij-light";
                    }

                    button {
                        width          : 26px;
                        height         : 26px;
                        border-radius  : 50%;
                        border         : 0;
                        background     : rgba(250, 104, 23, 0.08);
                        align-items    : center;
                        justify-content: center;
                        display        : flex;

                        svg {
                            position : unset;
                            right    : auto;
                            top      : auto;
                            transform: none;
                            translate: none;
                        }
                    }
                }
            }

            .form-control {
                border       : 1px solid #e4e4e4;
                border-radius: 10px;
                background   : #fff;
                font-size    : 12px;
                width        : 100%;
                padding      : 0 50px;

                &:focus {
                    border-color: #fc6b14;
                }
            }

            >div {
                &::after {
                    content   : "";
                    position  : absolute;
                    width     : 1px;
                    height    : 12px;
                    background: #e4e4e4;
                    right     : 42px;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .form-group.has-btns {
            display        : flex;
            align-items    : center;
            justify-content: space-between;
            border         : 1px solid #e4e4e4;
            border-radius  : 50px;
            height         : 45px;
            padding        : 0 15px;

            button {
                border     : 0;
                color      : #233555;
                background : transparent;
                padding    : 0;
                display    : flex;
                align-items: center;
                column-gap : 10px;
                font-size  : 12px;

                svg {
                    position : unset;
                    top      : auto;
                    transform: none;
                    right    : auto;
                    left     : auto;
                }
            }
        }
    }
}

.product-variation-table {
    margin-top: 20px;

    th {
        text-align: center;

        &.required:after {
            content    : "*";
            color      : red;
            font-weight: lighter;
            font-size  : 1.5rem;
        }
    }

    th,
    td {
        position  : relative;
        text-align: center;

        &:first-child {
            padding      : 0;
            white-space  : pre;
            text-overflow: ellipsis;
            overflow     : hidden;
            max-width    : 115px;
        }

        [data-theme="dark"] & {
            color: white;
        }

        .switcher {
            top      : 50%;
            left     : 50%;
            translate: -50% -50%;

            .switcher-input+.knobs:before {
                content    : "Used";
                color      : white;
                font-size  : 70%;
                line-height: calc(70% * 3.7);
                text-align : center;
            }

            .switcher-input:checked+.knobs:before {
                content: "New";
                color  : black;
            }
        }

        button.btn-blue {
            width: auto;

            &.length {
                text-align: end;
                width     : 80px;
            }
        }
    }

    th:first-child {
        width: 95px;
    }

    tr {
        border-bottom: 1px solid;
        @include lightDark(#eeedfd, #3c4045, border-color);

        &:last-child {
            border-bottom: 0;
        }
    }

    .image-uploader {
        width        : 50px;
        height       : 40px;
        margin-bottom: 0;
        background   : #c6c6c61e !important;

        label {
            margin: 0 !important;
        }

        img {
            width : 70%;
            height: auto;
        }

        .img-pat {
            text-align: center;
        }
    }

    input[type="text"],
    input[type="date"],
    input[type="number"] {
        width        : 100%;
        border-style : solid;
        white-space  : pre;
        text-overflow: ellipsis;
        overflow     : hidden;

        @include lightDark(black, white, color);
        background  : transparent;
        border-color: transparent;
    }
}

.modal-dialog.productDataModal {
    height     : 90vh;
    display    : flex;
    align-items: center;

    .modal-content {
        margin: auto;

    }

    .d-inline-block .switcher {
        position: relative;
        cursor  : pointer;
    }
}

.productDataModal {
    .tab-pane {

        input[readonly]:not([readonly="readonly"]),
        input[readonly="true"],
        input[readonly="true"]~*,
        input:disabled,
        input:disabled~* {
            filter           : grayscale(1);
            // pointer-events: none;
            cursor           : not-allowed;

            @include lightDark(#e4e4e4, #363636, background);

            &.flatpickr-input {
                pointer-events: none;
            }
        }
    }

    &.modal-xl .modal-content>form>.modal-body {
        overflow  : hidden;
        overflow-y: auto;
        max-height: calc(100vh - 214px);
        min-height: calc(100vh - 214px);

        .tab-content {
            max-width: 100%;
        }
    }

    .form-group {
        margin-bottom: 15px;
    }

    .nav-link.required {
        &:after {
            content      : "*";
            color        : #fff;
            font-size    : 20px;
            background   : red;
            width        : 15px;
            height       : 15px;
            border-radius: 100%;
            position     : absolute;
            left         : 10px;
            top          : -8px;
            box-shadow   : 0 0 0 2px #f5f7ff;
            line-height  : 27px;
        }
    }

    // .select__input {
    //   width: auto !important;
    // }
    .product-attachmets {
        padding: 6px;

        .form-uploader {
            height        : auto;
            flex-direction: column;
            cursor        : default;
            color         : black;
            gap           : 10px;

            [data-theme="dark"] & {
                color: white;
            }

            .form {
                @include flex();
                gap    : 10px;
                padding: 15px;
            }



            svg,
            input {
                position     : unset !important;
                border-radius: 6px;
                background   : rgba(133, 133, 133, 0.293);
                border       : none;
                width        : auto;
                height       : 35px;
                padding      : 5px;
                transform    : unset !important;

                [data-theme="dark"] & {
                    color: white;
                }

                // cursor: default;
            }

            .file-url {
                border       : 1px solid #eee;
                padding      : 10px;
                border-radius: 6px;
                color        : inherit;
                gap          : 15px;
                @include flex();

                img {
                    width     : 150px;
                    object-fit: contain;
                }

                div {
                    color: black;

                    [data-theme="dark"] & {
                        color: white;
                    }

                }

                label:first-of-type {
                    flex-direction : column;
                    gap            : 10px;
                    justify-content: center;
                    align-items    : center;
                }

            }

            @media screen and (max-width: 768px) {
                .form {
                    flex-direction: column;
                }

                .file-url {
                    flex-direction: column-reverse;

                    label:first-of-type {
                        flex-direction : column-reverse;
                        gap            : 10px;
                        justify-content: center;
                        align-items    : center;




                    }
                }
            }

            label {
                height         : auto;
                line-height    : 1;
                // aspect-ratio: 1;
                margin         : 0;
                cursor         : pointer;
                gap            : 10px;
                @include flex(column, _, flex-start);

                input[type="file"] {
                    display: none;
                }
            }

            .btn {
                // margin-top: 20px;
                border-radius: 6px;
                width        : auto;
                color        : black;
                border       : 1px solid #eee;

                [data-theme="dark"] & {
                    color: white;
                }
            }
        }

        .thumbs {
            .thumb {
                &.file {
                    padding-bottom: 20px;
                }

                text-align    : center;
                flex-direction: column;
                color         : black;

                [data-theme="dark"] & {
                    color: white;
                }

                &:after {
                    content: none !important;
                }

                &>svg {
                    fill  : black;
                    width : auto;
                    height: 70%;

                    [data-theme="dark"] & {
                        fill: white;
                    }
                }

                img {
                    object-fit: contain;
                }

                p {
                    height       : 20px;
                    text-overflow: ellipsis;
                    white-space  : nowrap;
                    overflow     : hidden;
                }

                .top-actions {
                    position   : absolute;
                    top        : -10px;
                    left       : 16%;
                    display    : flex;
                    align-items: center;
                    gap        : 5px;
                    z-index    : 2;

                    .download,
                    .preview {
                        padding      : 5px;
                        height       : 25px;
                        width        : 25px;
                        background   : #fc6b14;
                        border-radius: 100%;

                        a {
                            display        : flex;
                            align-items    : center;
                            justify-content: center;
                        }

                        z-index: 2;

                        svg {
                            fill     : black;
                            position : unset;
                            transform: unset;

                            [data-theme="dark"] & {
                                fill: white;
                            }
                        }

                        @media (max-width: 1024px) {
                            width  : 20px;
                            height : 20px;
                            padding: 2px;
                        }
                    }
                }

                .file-icon {
                    position  : relative;
                    transform : unset;
                    margin    : auto;
                    margin-top: 20px;
                    position  : unset;
                    transform : unset;
                }

                .extension {
                    position : absolute;
                    top      : 50%;
                    left     : 50%;
                    translate: -50% -50%;
                    color    : black;
                    margin   : auto;

                    [data-theme="dark"] & {
                        color: white;
                    }
                }
            }
        }
    }
}

.tab-pane>.form-group {
    margin-bottom: 20px !important;
}

// }
body {
    div.modal[role="dialog"][aria-modal="true"]:nth-last-child(1) {
        z-index: 1075;
    }

    .modal-backdrop.show:nth-last-of-type(2) {
        z-index: 1075;
    }

    .modal-backdrop.show:nth-last-of-type(3) {
        z-index: 1055;
    }

    div.modal[role="dialog"][aria-modal="true"]:nth-last-child(3) {
        z-index: 1075;
    }

    .modal-backdrop.show:nth-last-of-type(4) {
        z-index: 1050;
    }

    div.modal[role="dialog"][aria-modal="true"]:nth-last-child(5) {
        z-index: 1025;
    }

    .modal-backdrop.show:nth-last-of-type(6) {
        z-index: 1000;
    }

    &>div li.dnd-sortable-tree_folder_wrapper.dnd-sortable-tree_folder_clone {
        direction: ltr;
        opacity  : 0.5;
    }

    .modal-dialog {
        .modal-footer {
            [data-theme="dark"] & {
                border-top-color: #4a4a4a;
            }
        }
    }
}

.products-wrap {
    .table-wrap {
        .adjust-products-table {
            div[role="row"]:not(:first-child) {
                &>div:first-child {
                    & // ,

                    // & + div
                        {
                        position  : sticky;
                        z-index   : 2;
                        right     : 40px;
                        background: #fff;

                        [data-theme="dark"] & {
                            background: #1b293f;
                        }
                    }

                    &+div+div {
                        z-index: 1;
                    }

                    right: 0px !important;
                }

                &:nth-of-type(odd) {
                    &>div:first-child {

                        &,
                        &+div {
                            background: #f9f9f9;

                            [data-theme="dark"] & {
                                background: #1a273b;
                            }
                        }
                    }
                }
            }

            div[role="cell"] {
                padding            : 0 !important;
                // box-shadow: -12px -1px 10px 0px rgba(132, 134, 136, 0.2);
                border             : 1px solid rgba(132, 134, 136, 0.2);
                border-bottom-width: 0;
                border-right-width : 0;

                &>div {
                    height     : 100%;
                    display    : flex;
                    align-items: center;

                    input {
                        height        : 100%;
                        width         : 100%;
                        background    : none;
                        border        : none;
                        padding-inline: 5px;

                        [data-theme="dark"] & {
                            color: white;
                        }
                    }
                }
            }

            .product-img {
                width       : 70px;
                aspect-ratio: 1;
                object-fit  : contain;
                margin-block: 5px;
            }
        }
    }
}

.productsReviews {
    .productReview {
        pointer-events: all !important;

        .modal-header {
            @include flex(_, space-between, center);

            img {
                width        : 40px;
                height       : 40px;
                border-radius: 50%;
                object-fit   : cover;
            }

            .date-tag {
                gap: 20px;
                @include flex(_, space-between, center);

                h5 {
                    font-size: 13px;
                }

                .tag {
                    font-size    : 12px;
                    border-radius: 30px;
                    padding      : 5px 10px;
                    color        : white;
                    background   : #fc6b14;
                }
            }
        }

        .user-info {
            width  : 100%;
            padding: 20px;
            gap    : 10px;
            @include flex(_, flex-start);

            img {
                width : 40px;
                height: 40px;
            }

            div {
                flex: 1;

                h5 {
                    opacity: 0.8;
                }

                h6 {
                    max-width: 85%;

                    [data-theme="dark"] & {
                        color: white;
                    }

                    a.expand,
                    a.shrink {
                        display : none;
                        position: absolute;
                        bottom  : 0;
                        right   : 0;
                        z-index : 1;
                        color   : #cd5b13;

                        [dir="ltr"] & {
                            left : 0;
                            right: unset;
                        }
                    }

                    &.long {
                        padding-bottom: 20px;

                        a.shrink {
                            display : inline-block;
                            position: inherit;
                        }
                    }

                    &.half-hidden {
                        position     : relative;
                        max-height   : 50px;
                        overflow     : hidden;
                        text-overflow: ellipsis;
                        white-space  : normal;

                        a.shrink {
                            display: none;
                        }

                        a.expand {
                            display: inline-block;
                        }

                        --bg-color: #fff;

                        [data-theme="dark"] & {
                            --bg-color: #233654;
                        }

                        &:after {
                            content : "";
                            position: absolute;
                            bottom  : -1px;
                            left    : 0;
                            width   : 100%;
                            background: linear-gradient(to bottom,
                                    rgba(255, 255, 255, 0) 0%,
                                    var(--bg-color) 50%);
                            height            : 30px;
                            // backdrop-filter: blur(10px);
                        }
                    }
                }
            }
        }

        .actions {
            padding    : 20px;
            padding-top: 0;
            width      : 100%;
            @include flex(_, space-between, center);

            .buttons {
                gap: 10px;
                @include flex();

                .active {
                    &.allow {
                        color       : greenyellow;
                        border-color: greenyellow;
                    }

                    &.prevent {
                        color       : rgb(236, 56, 56);
                        border-color: rgb(236, 56, 56);
                    }
                }
            }

            button {
                padding      : 5px 10px;
                border       : 1px solid #000;
                border-radius: 6px;
                background   : transparent;
                font-size    : 13px;
                gap          : 5px;
                @include flex();

                [data-theme="dark"] & {
                    color       : white;
                    border-color: #fff;
                }

                &.delete {
                    color       : rgb(236, 56, 56);
                    border-color: rgb(236, 56, 56);
                }
            }

            &.reply-form div {
                width: 100%;

                .buttons {
                    margin-top     : 10px;
                    justify-content: flex-start;
                }

                textarea {
                    border-radius: 6px;
                    width        : 85%;
                    background   : transparent;
                    resize       : both;
                    padding      : 10px;

                    [data-theme="dark"] & {
                        color: white;
                    }
                }
            }
        }
    }
}

.rdw-editor-toolbar {
    margin-bottom: 0 !important;

    & * {
        direction: ltr;
    }
}

.rdw-editor-main {
    background    : rgb(199, 199, 199);
    cursor        : text;
    border-radius : 0 0 5px 5px;
    min-height    : 200px;
    max-height    : 500px;
    overflow      : auto;
    padding-inline: 10px;

    [data-theme="dark"] & {
        background: #5c5c5c;
    }

    pre {
        background   : transparent !important;
        border-radius: 0 !important;
        padding      : 0 !important;
    }
}

.DraftEditor-root * {
    color: inherit;
}