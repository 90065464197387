.reports {
  row-gap: 20px;
  .card {
    height: 100%;
    .card-head {
      .chart-filter {
        display: flex;
        gap: 10px;
        background: #f8f8ff5d;
        border-radius: 10px;
        padding: 5px 10px;
        div {
          cursor: pointer;
          border-radius: 10px;
          padding: 7px 10px;
          color: #9291a5;
          [data-theme="dark"] & {
            color: white;
          }

          &.active {
            color: white;
            background: linear-gradient(93.69deg, #fc6b14 0%, #e74c3c 98.86%);
          }
        }
      }
    }
  }
}
