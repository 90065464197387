@use "/src/styles/variables.scss"as *;

.productCard {
    :global {
        .unpublished {
            position     : absolute;
            top          : 5px;
            left         : 5px;
            background   : linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
            color        : #fff;
            border-radius: 30px;
            padding      : 4px 10px;
            font-size    : 12px;
            z-index      : 2;
            white-space  : nowrap;
        }
    }

}