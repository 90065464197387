@import "./mixins.scss";

.customers-wrap {
  a {
    text-decoration: underline !important;
    color: #fc6b14;
  }

  .card.plus {
    height: calc(100% - 30px);
    padding: 20px;
    gap: 15px;
    justify-content: center;
    align-items: center;

    h4 {
      color: black;

      [data-theme="dark"] & {
        color: white;
      }
    }

    svg {
      height: 50px;
      width: auto;
      color: black;

      [data-theme="dark"] & {
        color: white;
      }
    }
  }

  .customer-name {
    display: flex;
    flex-direction: column;

    .customer-groups {
      display: flex;
      gap: 5px;
      align-items: stretch;
      overflow: visible;

      &::-webkit-scrollbar {
        width: 3px;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba(229, 229, 229, 0.651);
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: #fc6b14;
        border-radius: 10px;
      }

      .point {
        padding: 5px 10px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: 1px solid #fc6b14;
        border-radius: 4rem;
        color: black;
        // line-height: 1;
        font-size: 12px;

        [data-theme="dark"] & {
          color: white;
        }

        &.blocked {
          border-color: #e74c3c;
        }
      }
    }
  }

  .card {
    .group-icon {
      text-align: center;

      img {
        background: rgba(161, 161, 161, 0.297);
        height: 50px;
        aspect-ratio: 1;
        border: 1px solid rgb(161, 161, 161);
        border-radius: 5px;
        padding: 7px;
      }
    }
  }

  .form-select {
    [dir="rtl"] & {
      padding-inline: 0.75rem 2.25rem;
    }
  }
}

.form-label {
  [data-theme="dark"] & {
    color: white;
  }
}

.modal-title {
  [data-theme="dark"] & {
    color: white;
  }
}

.customer-show {
  .card {
    min-height: unset !important;
  }

  .customer.block {
    .c-body {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .userImg {
      text-align: center;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      h4 {
        color: black;

        [data-theme="dark"] & {
          color: white;
        }
      }
    }

    .userDetails {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;

      .point {
        padding: 10px 15px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-color: #fc6b14;
        color: black;

        [data-theme="dark"] & {
          color: white;
        }

        &.small {
          padding: 6px 11px !important;
        }

        p {
          direction: ltr;
          color: inherit;
          line-height: 1;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      a.point {
        border: 1px solid #fc6b14;
        color: #fc6b14 !important;
        border-radius: 5rem;
      }
    }

    // p {
    // }
  }
}

.filter-toolbar {
  position: sticky;
  top: 77px;

  @include md-tabled {
    top: 186px;
  }

  @include xs {
    top: 234px;
  }

  @include xs-big {
    top: 5px;
  }

  .form-control {
    @include xs {
      min-width: 150px;
    }
  }
}

$color_1: #000;
$color_2: #fc6b14;
$font-family_1: "Bahij-semibold";
$font-family_2: "Bahij-light";

.userSocial {
  // border-top: 1px solid #f4f7fe;
  // padding-top: 30px;
  // margin-top: 50px;
  span {
    font-size: 14px;
    font-family: $font-family_1;
    margin-bottom: 30px;
    display: block;
  }

  ul {
    gap: 5px;
    @include flex();
    flex-wrap: wrap;

    li {
      // margin-bottom: 15px;
      line-height: 1;

      a {
        display: inline-flex;
        align-items: center;
        column-gap: 12px;
        color: $color_1;
        font-size: 14px;
        font-family: $font-family_2;
        transition: 0.3s;

        &:hover {
          color: $color_2;
          // padding: 0 10px;
        }
      }

      // &:last-child {
      //   margin: 0;
      // }
    }
  }
}
