.categories-wrapper {
  margin-top: 30px;
  // .row {
  //   row-gap: 30px;
  // }
  .category-card {
    .item-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline-end: 10px;
      column-gap: 5px;
      background-color: #fff;
      border-radius: 12px;
      margin-bottom: 20px;
    }
    .accordion-item {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      &::before {
        display: none;
      }
      .accordion-header {
        margin: 0;
        flex-grow: 1;
        &::before {
          display: none;
        }
        .accordion-button {
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: default;
          &::before,
          &::after {
            display: none;
          }
          .info {
            display: flex;
            align-items: center;
            gap: 15px;
            img {
              border-radius: 6px;
              width: 30px;
              height: 30px;
              border: 1px solid #dfdfdf;
              object-fit: cover;
            }
            svg {
              transition: 0.3s;
              &.drag {
                cursor: grab;
              }
            }
            h4 {
              color: #444444;
              font-size: 17px;
              font-family: "Bahij-semibold";
            }
          }

          &:not(.collapsed) {
            .info {
              .arrow {
                transform: rotate(180deg);
              }
            }
          }
        }

        &.has-subcategories {
          .accordion-button {
            cursor: pointer;
          }
        }
      }
      .actions {
        .dropdown-toggle {
          &::before {
            display: none;
          }
        }
        .dropdown-menu {
          border-radius: 6px;
          background: #fff;
          box-shadow: -4px 4px 14px 0px rgba(0, 0, 0, 0.1);
          .dropdown-item {
            transition: 0.3s;
            svg {
              background-color: transparent;
              width: auto;
              height: auto;
              border-radius: 0;
              padding: 0;
              transition: 0.3s;
              filter: brightness(0);
            }
            &:hover {
              background: #f6f6f6;
              color: #fc6b14;
              svg {
                filter: brightness(1);
              }
            }
          }
        }
      }
      // .row {
      //   row-gap: 15px;
      //   .col-lg-4 {
      //     width: 100%;
      //     max-width: 100%;
      //   }
      // }
      .accordion-body {
        padding-inline: 20px;
        li {
          margin: 0;
        }
        .category-card {
          .accordion-item {
            .item-header {
              border-radius: 6.14px;
              border-right: 2px solid #444;
              background: rgba(68, 68, 68, 0.05);
            }
          }
        }
      }
    }
    .accordion-body {
      padding: 0;
      border: 0;
    }

    &.active {
      z-index: 2;
      position: relative;
      .accordion-body {
        .active {
          &:first-child {
            z-index: 3;
          }
        }
      }
    }
  }
}
