form.products-stock-settings {
  label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .form-switch {
    padding-inline-end: 0;
    .form-check-input:checked {
      background-color: #fc6b14;
    }
  }
  .form-control {
    background-color: white;
    border-color: #efefef;
    [data-theme="dark"] & {
      border-color: #4d4d4d;
    }
  }
  .required {
    &:after {
      content: "*";
      color: #ff0303;
      margin: 0 3px;
      font-size: 17px;
      line-height: 0;
    }
  }
  .editor-additions {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    [data-theme="dark"] & {
      color: #fff;
    }
    .template-text {
      color: inherit;
      button {
        color: inherit;
        background: rgba(85, 100, 140, 0.488);
        border-radius: 50px;
        padding: 5px 10px;
        border: 0;
        margin-inline-start: 5px;
        line-height: 1;
      }
    }
  }
  .separator {
    margin: 20px 0;
    height: 0;
    overflow: visible;
    border-top: 1px solid #efefef;
    line-height: 0;
    text-align: center;
    span {
      background: #fff;
      padding: 5px 20px;
      translate: 0 -50%;
      display: inline-block;
      [data-theme="dark"] & {
        background: #1b293f;
        color: #fff;
      }
      svg {
        margin-inline-end: 5px;
      }
    }
    [data-theme="dark"] & {
      border-color: #4d4d4d;
    }
  }

  .preview {
    margin-top: 30px;
    padding: 20px;
    background: rgba(128, 128, 128, 0.23);
    border-radius: 10px;
    [data-theme="dark"] & {
      h4 {
        color: #fff;
      }
    }
  }
  // .tox-edit-area__iframe {
  //   padding: 1;
  //   body * {
  //     [data-theme="dark"] & {
  //       color: #fff;
  //     }
  //   }
  // }
}

.card-inner {
  position: relative;
  min-height: 155px;
  &.bank {
    min-height: 85px;
    .switcher {
      width: 42px;
      height: 19px;
      .knobs:before {
        width: 12px;
        height: 12px;
      }
    }
    .symbol {
      max-width: 100%;
      h5 {
        font-size: 12px;
        margin-bottom: 0;
        max-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;

        &.muted {
          opacity: 0.8;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .text.icon {
      button {
        padding: 0;
        background: none;
        border: 0;
        transition: 0.3s;
        width: 25px;
        height: 25px;
        svg {
          position: unset;
          transform: none;
          width: 100%;
          height: 100%;
        }
        &:hover {
          filter: invert(1);
        }
      }
    }
  }
  .symbol {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 24px;
  }
  .checker {
    position: absolute;
    left: 0;
    top: 0;
    input[type="checkbox"] {
      background-color: #f5f7ff;
      border: 0;
    }
  }
  .text {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  [data-theme="dark"] .text,
  [data-theme="dark"] .checker,
  [data-theme="dark"] .symbol {
    color: white;
  }
  [data-theme="dark"] .checker label {
    color: inherit;
  }
}

.settingItem {
  text-align: center;
  transition: 0.3s;
  height: 90% !important;
  gap: 30px;

  .c-img {
    background: #f4f7fe;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin: 0 auto 16px;
    transition: 0.3s;
  }

  .form-head {
    margin-bottom: 0 !important;
    i {
      background: none !important;
      width: 24px;
      height: 24px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .btn {
    width: 108px;
    padding-inline: 0;
    line-height: normal;
    border-radius: 8px;
    &:disabled {
      background: #f6f6f6;
      color: black;
    }
  }

  h4 {
    font-family: "Bahij-semibold";
    font-size: 14px;
    transition: 0.3s;
    margin-bottom: 0;
    span {
      color: #a3aed0;
      font-size: 16px;
    }
  }
  h5 svg {
    position: unset !important;
    transform: none !important;
    margin-inline-start: 10px;
    & * {
      fill: #000;
      [data-theme="dark"] & {
        fill: #fff;
      }
    }
  }

  span {
    color: #a3aed0;
    font-size: 12px;
    transition: 0.3s;
    overflow: hidden;
    max-height: 36px;
    min-height: 36px;
  }
  &.balance {
    position: relative;
    padding-bottom: 60px;
    //   justify-content: space-between;
  }
  .records-link {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    color: #0d53d3;
    [data-theme="dark"] & {
      color: #6792e2;
    }
  }

  &:hover .c-img {
    background-color: #fff;
    transform: scale(1.1);
  }
  .card {
    padding: 30px;
    margin: 0;
    z-index: 1;
    overflow: hidden;
    a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .overlay {
      position: absolute;
      width: 100px;
      height: 100px;
      object-fit: contain;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: 0.3s;
      filter: brightness(0) invert(1);
      z-index: -1;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
      opacity: 0;
      transition: 0.3s;
      z-index: -1;
    }

    &:hover::before {
      opacity: 1;
    }
    &:hover h4 {
      color: white;
    }
    &:hover span {
      color: #d4daec;
    }

    &:hover .overlay {
      opacity: 0.1;
    }
  }
}

form.balance-modal {
  .card.settingItem {
    flex-direction: row;
    justify-content: space-between;
  }
  .form-group {
    padding-bottom: 5px;
    margin-bottom: 5px;
    .form-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    label {
      h5 {
        svg {
          position: unset;
          transform: none !important;
          margin-inline-end: 5px;
        }
      }
    }
  }
  label {
    cursor: pointer;
    &.method {
      padding: 10px 15px;
      border-radius: 8px;
      background-color: #686a6d1c;
      margin-bottom: 0 !important;

      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
    }
    // &.active {
    // }
  }
  .bank-info {
    padding: 10px 20px;
    background: #ced6e945;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .card {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding: 10px 15px;
      .info {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      input[type="radio"] {
        border-color: #dcdcdc !important;
        color: #dcdcdc;
        &:before {
          content: "✔" !important;
          font-size: 12px !important;
          line-height: 1.6;
          color: inherit;
        }
        &:checked {
          border-color: #0dd35c !important;
          color: #0dd35c;
        }
      }
      &.active {
        border-color: #0dd35c;
      }
    }
    .form-group {
      border: 0;
    }
    .image-uploader {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    p:first-child {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    svg {
      position: unset;
      transform: none;
      color: black;
      [data-theme="dark"] & {
        color: white;
      }
    }
  }
}
.store-settings-page {
  &.store-paymentmethods {
    .bank-accounts {
      padding: 20px !important;
      display: flex;
      align-items: center;
      gap: 20px;
      & > div {
        width: 100%;
      }
      .bank {
        .card {
          margin-bottom: 0;
        }
      }
    }
  }
  &.store-notifications {
    .card {
      border-radius: 8px;
      padding: 0 !important;
      &-head {
        margin: 20px;
        h4 {
          margin-bottom: 0;
        }
      }
    }
    table {
      thead {
        background: rgba(244, 247, 254, 0.6);
      }
      td,
      th {
        text-align: center;
        padding: 20px;
        &:first-child {
          text-align: start;
        }
      }
    }
  }
  &.store-payment-rules {
    .card-title,
    .card-title h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
    }
  }

  .accordion {
    .accordion-item {
      .shipping-methods {
        padding: 0;
      }
      .accordion-header {
        margin-bottom: 0;
        padding: 20px;
        height: 66px;
        &:before {
          content: none;
        }
        .form-head svg {
          width: 24px;
          position: unset;
          transform: none;
        }
        h4 {
          margin-bottom: 0;
        }
      }
      .accordion-body {
        padding: 20px;
        // padding: 0;
        // margin-top: 20px;
        border-color: transparent;
        .no-data {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 22px;
          color: #a3aed0;
          padding: 7vw;
          h4,
          p {
            color: inherit;
            text-align: center;
          }
          h4 {
            font-size: 24px;
          }
          svg {
            width: 66px;
            height: 66px;
          }
        }
      }
    }
    .card {
      border-radius: 8px;
    }
  }

  &.store-receipt {
    .settings-cards {
      .card {
        p {
          font-size: 14px;
        }
      }
    }
    .receipt-template {
      background: #f6f6f6;
      border-radius: 8px;
      padding: 2vw;

      * {
        color: #233654;
        font-size: 12px;
      }

      .logos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          height: 60px;
          width: auto;
        }
      }

      .invoice {
        // max-width: 800px;
        width: 100%;
        margin: auto;
        padding: 20px;
        border-radius: 5px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        &-header,
        &-footer {
          text-align: center;
          margin-bottom: 20px;
        }
        &-header {
          border-bottom: 1px solid #ddd;
          padding-bottom: 10px;

          display: flex;
          // justify-content: space-around;
          align-items: center;
          column-gap: 20px;
          flex-wrap: wrap;
          p {
            width: 30%;
            white-space: nowrap;
            text-align: start;
            span {
              // color: #000;
            }
          }
        }
        .sections-wrapper {
          display: flex;
          justify-content: space-between;
        }
        &-section {
          margin-bottom: 20px;
        }
        &-section h2 {
          margin-bottom: 10px;
          font-size: 12px;
          color: #e74c3c;
          text-align: start;
        }
        &-table {
          width: 100%;
          // border-collapse: collapse;
          tr {
            border-bottom: 1px solid #e4e4e4;
          }
          thead tr {
            border: none;
          }
          th,
          td {
            padding: 10px;
            // border: 1px solid #ddd;
            &.product-img {
              align-content: baseline;
            }
            svg {
              width: 50px;
              height: auto;
            }
            .desc {
              font-size: 10px;
            }
          }
          th {
            background: #fff;
            font-weight: normal;
            color: black;
            &:first-child {
              border-radius: 0 8px 8px 0;
            }
            &:last-child {
              border-radius: 8px 0 0 8px;
            }
          }
          &.total {
            width: auto;
            margin-inline: auto 0;
            td:not(.total) {
              font-weight: bold;
              text-align: start;
            }
            .orange {
              color: #e74c3c;
            }
          }
        }
        .total {
          text-align: left;
        }
        .notes {
          font-size: 12px;
          color: #555;
        }
        .barcode {
          text-align: center;
          margin-bottom: 20px;
        }
        .barcode img {
          width: 100%;
          max-width: 200px;
        }
        ul {
          padding-inline-start: 20px;
          li {
            list-style: disc;
            text-align: start;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  &.store-apps {
    .nav {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    .swiper {
      flex: 1;
      padding-inline: 3px;
      &-slide {
        width: auto;
        .nav-item a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 132px;
          height: 40px;
          border: 1px solid #a0b0f76b;
          border-radius: 12px;

          &:not(.active) {
            background: transparent;
            color: black;
            [data-theme="dark"] & {
              color: white;
            }
          }
        }
      }
      &-button-prev,
      &-button-next {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        background: linear-gradient(131.1deg, #e74c3c 1.8%, #fc6b14 180.84%);
        content: "n";
        flex: none;
        border-radius: 50%;
        rotate: 90deg;
        cursor: pointer;
        svg path {
          fill: white;
        }
        &.swiper-button-disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        &.swiper-button-lock {
          display: none;
        }
      }
      &-button-prev {
        rotate: -90deg;
        order: -1;
      }
      &-wrapper {
        flex: auto;
      }
    }
    .apps {
      margin-top: 30px;
      .row {
        row-gap: 20px;
      }
      .app {
        border: 1px solid #4d5b7028;
        border-radius: 15px;
        overflow: hidden;
        .info {
          background: #cdcdcd33;
          padding: 20px;
          display: flex;
          gap: 10px;
          img {
            width: 44px;
            height: 44px;
            object-fit: contain;
          }
          div {
            flex: 1;
          }
          & > svg {
            width: 24px;
            height: 24px;
            padding: 3px;
            border-radius: 50%;
            border: 1px solid #a3aed0;
            color: #a3aed0;
          }
        }
        .dropdown-toggle {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: black;
          padding: 20px;
          svg {
            width: 10px;
            * {
              fill: currentColor;
            }
          }
          [data-theme="dark"] & {
            color: white;
          }
        }
        .dropdown-item {
          color: #a3aed0 !important;
          &:hover {
            color: #e74c3c !important;
          }
        }
        svg {
          width: 20px;
          height: 20px;
          background: transparent;
          padding: 0;
        }
        &.active {
          svg {
            border-color: #e74c3c;
            color: #e74c3c;
          }
        }
      }
    }
  }
}
.shipping-methods {
  .productDataModal .form-group .form-head i {
    background-color: transparent !important;
    width: 20px;
    height: 20px;
  }

  .switcher-wrapper {
    position: relative;
    padding: 20px;
    .switcher {
      top: 50%;
      left: 50%;
      min-width: 55px;
      translate: -50% -50%;
    }
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  .items.settings-cards a.card {
    cursor: pointer;
    & > svg {
      rotate: 180deg;
    }
  }
  .card {
    &.shipping-card {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      flex-direction: row;
      padding: 10px;
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      width: 100%;
      [data-theme="dark"] & {
        background: #364762;
      }
      @media (min-width: 768px) {
        width: calc(50% - 30px);
      }
      &:hover {
        background: #f9f9f9;
        border-color: transparent;
        box-shadow: none;
      }
      .info {
        display: flex;
        gap: 10px;
        align-items: center;
        img {
          width: 60px;
          padding: 7px;
          aspect-ratio: 1;
          object-fit: contain;
          border-radius: 8px;
          border: 1px solid #e6e6e6;
        }
      }
      .icon {
        width: 60px;
        height: 60px;
        left: 303px;
        gap: 0px;
        border-radius: 8px;
        opacity: 0px;
        background-color: #233654;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 24px;
          height: 24px;
          color: white;
        }
      }
      .desc {
        flex: auto;
      }
      [data-theme="dark"] & {
        &.shipping-card:hover {
          background: #4d4d4d9a;
        }
        .info,
        &.shipping-card {
          border-color: #4d4d4d;
          img {
            border-color: #4d4d4d;
          }
        }
      }
      a {
        color: #2b68d8;
        text-decoration: underline;
      }
      .switcher-wrapper {
        margin-inline: 16px;
      }
    }
  }
  // .card-head {
  //   margin-bottom: 20px !important;
  // }
  .row {
    svg {
      width: 30px;
      height: 30px;
      [data-theme="dark"] & {
        color: white;
      }
    }
    p.small {
      opacity: 0.8;
    }
    &.pointer {
      padding: 5px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .arrow-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 10px;
          rotate: 180deg;
        }
      }
      &:hover {
        background: rgba(128, 128, 128, 0.226);
      }
    }
  }
  &.products-stock-settings {
    .modal-body {
      background: white;
      [data-theme="dark"] & {
        background: #364762;
      }
    }
    .shipping-card {
      border-color: rgba(212, 212, 212, 0.589) !important;
      &:last-child {
        border-color: transparent !important;
      }
      .info {
        p {
          font-size: 14px;
        }
        p.small {
          font-size: 12px;
        }
      }
    }
  }
}

.delivery-companies {
  .btn.add-company {
    height: 100%;
    border-radius: 8px !important;
    margin-bottom: 30px;
  }
}
.store-domains {
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-block: 40px;
    .form-control {
      position: relative;
      width: 100%;
      max-width: 600px;
      input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: 0;
        padding-inline-start: 20px;
        [data-theme="dark"] & {
          color: white;
        }
        // &:enabled:read-write:-webkit-any(:hover, :focus)
        //   + button[type="button"] {
        //   opacity: 1;
        // }
        &::-webkit-search-cancel-button {
          opacity: 0;
        }
      }
      [data-theme="dark"] & {
        border-color: #a3aed0 !important;
      }
      .close,
      button[type="button"],
      .search {
        position: absolute;
        top: 50%;
        translate: 0 -50% !important;
        right: 10px;
        width: 22px;
        height: 22px;
        color: #a3aed0;
      }
      .close,
      button[type="button"] {
        right: auto;
        left: 10px;
        border-radius: 50%;
        // padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #a3aed0;
        // opacity: 0;
        cursor: pointer;
        background: transparent;
      }
      :not([data-theme="dark"]) & {
        transition: all 0.3s;
        border-color: #dfdfdf;
        background-color: white;
      }
    }
  }
  .domain-types {
    &,
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
    div {
      gap: 8px;
      line-height: 1;
      [data-theme="dark"] & {
        color: white;
      }
    }
  }
  hr {
    border-color: #e4e4e4;
    opacity: 1;
    margin-block: 57px 35px;
  }
  .search-results {
    & > h5 {
      margin-bottom: 39px;
    }
    .results {
      row-gap: 17px;
      .result {
        background: #9999991c;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        padding: 10px;
        padding-inline-start: 20px;
        .btn {
          width: 100px;
          height: 36px;
          padding: 0px;
        }
      }
    }
  }
}

.modal-dialog {
  &.free-shipping {
    .add-condition {
      .btn {
        width: auto;
      }
    }
  }
  &.cash-on-deliver {
    .nav {
      justify-content: center;
      .nav-link {
        border: 1px solid #e74c3c;
        color: black;
        [data-theme="dark"] & {
          color: white;
        }
        &.active {
          background: #e74c3c;
          color: white;
        }
      }
    }
  }
  &.delivery-settings {
    .switcher {
      top: 50%;
      translate: 0 -50%;
    }
  }
  &.transfere-domain {
    ul {
      padding-inline: 1.5rem;
      margin-block: 14px;
      li {
        list-style: disc;
        margin-bottom: 7px;
        opacity: 0.7;
        [data-theme="dark"] &::marker {
          color: white;
        }
      }
    }
    .radio-inputs {
      .inputs-wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        label {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          margin-bottom: 19px;
          cursor: pointer;
          h5 {
            font-size: 16px;
            margin-bottom: 0;
          }
          p {
            font-size: 14px;
          }
          input {
            flex: none;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

.storePayment {
  h5 svg,
  .bank-info svg {
    position: unset !important;
    transform: none !important;
  }
  .image-uploader {
    margin-inline: auto !important;
  }
  .table-wrapper.records {
    border-radius: 8px;
    overflow: auto;
    max-height: 500px;
    .table {
      margin-bottom: 0;
      tr {
        border-bottom: 1px dashed #cecece3d;
      }
      & > :not(caption) > * > * {
        background-color: #cecece3d;
        [data-theme="dark"] & {
          color: white;
        }
      }
    }
  }
}

.store-langs {
  .card {
    border-radius: 8px;
    margin-bottom: 25px;
  }
  .langs {
    .lang {
      // display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      gap: 7px;
      // background: #364762;
      border-radius: 8px;
      padding: 10px 15px;
      .switcher {
        position: relative;
      }
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      label {
        cursor: pointer;
        p {
          font-size: 12px;
          white-space: nowrap;
          &.inactive {
            display: block;
            color: #0d53d3;
            [data-theme="dark"] & {
              color: #81a9f3;
            }
          }
          &.active {
            display: none;
            opacity: 0.7;
          }
        }
        input[type="radio"] {
          display: none;
          &:checked {
            & ~ .inactive {
              display: none;
            }
            & ~ .active {
              display: block;
            }
          }
        }
      }
    }
  }
}

.store-activity {
  .heading {
    margin-bottom: 24px;
  }
  h5 {
    svg {
      position: unset !important;
      transform: none !important;
      margin-inline-end: 8px;
    }
  }
  .activities {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 35px;
    .activity {
      display: inline-flex;
      span {
        width: 116px;
        height: 42px;
        border-radius: 8px;
        background-color: #f9f9f9;
        padding: 10px 20px;
        font-size: 14px;
        transition: 0.3s;
        text-align: center;
        cursor: pointer;
        [data-theme="dark"] & {
          background-color: rgba(128, 128, 128, 0.289);
          color: white;
        }
      }
      input:checked + span,
      span:hover {
        background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
        color: white;
      }
      input {
        opacity: 0;
        visibility: hidden;
        width: 0;
        height: 0;
      }
    }
  }
}

.store-seo {
  .keywords-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 7px;
    margin: 10px;
    .item {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      border-radius: 8px;
      padding: 5px 10px;
      background: #f6f6f693;
    }
  }
}
