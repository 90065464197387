@import "./responsive.scss";

@font-face {
  font-family: "Bahij-light";
  src: url("../assets/fonts/Bahij_TheSansArabic-Light.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bahij-regular";
  src: url("../assets/fonts/Bahij_TheSansArabic-Plain.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bahij-semibold";
  src: url("../assets/fonts/Bahij_TheSansArabic-SemiBold.ttf");
  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: "Bahij-bold";
  src: url("../assets/fonts/Bahij_TheSansArabic-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bahij-extrabold";
  src: url("../assets/fonts/Bahij_TheSansArabic-ExtraBold.ttf");
  font-weight: normal;
  font-style: normal;
}

*:not(svg):not(svg *) {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Bahij-regular";
  color: #0b1e2b;
  font-size: 16px;
  // border: 1px solid red;
}

body {
  overflow-x: hidden;
}

.owl-carousel {
  direction: ltr;
}

.tooltip {
  z-index: 1100 !important;
}

.tooltip-inner {
  max-width: 200px;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  background-color: #2f4657;
  border-radius: 0.25rem;
  font-size: 11px;
  text-transform: uppercase;
}

.tooltip {
  &.in {
    opacity: 1;
  }

  .tooltip-arrow {
    display: none;
  }

  &.top {
    padding: 5px 0;
  }
}

body {
  padding: 0;
  margin: 0;
}

select {
  display: inline-block;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: middle;
  background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

iframe {
  width: 100%;
  border: 0;
  height: 100%;
}

::-moz-selection,
::selection {
  background: #2c3e50;
  color: #fff;
}

a {
  text-decoration: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:visited,
  &:link {
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }

  display: inline-block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

p {
  line-height: 1.7;
}

.modal-open {
  overflow-y: auto !important;
  padding: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: normal;
  line-height: 1.3;
}

.modal-backdrop.in {
  opacity: 0.9;
}

input,
select {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  outline: 0;
}

textarea {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  outline: 0;
  resize: none;
}

.row {
  margin-bottom: 0;
}

.col-xs-12 {
  padding: 0;
}

i {
  color: inherit;
  font-style: normal;
  font-size: inherit;
}

.scrollTop {
  position: fixed;
  right: 0;
  bottom: 50px;
  z-index: 9;
  width: 40px;
  height: 40px;
  border: 0;
  padding: 0;
  background-color: #00c6bb;
  transition: all 0.3s;
  opacity: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #002a4d;
    transition: all 0.3s;
  }

  &.active {
    opacity: 1;
    transition: all 0.3s;
    right: 50px;
  }

  svg {
    fill: #fff;
  }
}

// svg * {
//   color: inherit;
// }

.menu-item-has-children {
  position: relative;

  .sub-menu {
    position: absolute;
    right: 0;
    left: auto;
    top: 120%;
    opacity: 0;
    visibility: hidden;
    display: block;
    transition: all 0.3s;
    min-width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    z-index: 11;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  }

  &:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
    transition: all 0.3s;
  }

  .sub-menu li {
    display: block !important;
    margin: 0 !important;

    &:after {
      display: none;
    }

    a {
      display: block;
      padding: 10px;
      font-weight: normal;
      font-size: 14px;
      color: #7d7d7d;
      border-radius: 0;
      border-bottom: 1px solid #f1f1f1;
      position: relative;
      transition: all 0.3s;

      &:before {
        display: none;
      }
    }

    &:hover > a {
      color: #fc6b14;
      transition: all 0.3s;
      padding: 10px 15px;
    }
  }

  &:after {
    content: "";
    display: inline-block;
    margin-right: 5px;
    transition: all 0.3s;
    font-weight: 900;
    vertical-align: middle;
    font-size: 11px;
  }

  &:hover:after {
    transform: rotate(-180deg);
    transition: all 0.3s;
  }
}

html,
body,
#__next,
.wrap,
#root,
.main-wrapper {
  height: 100%;
}

.main-head {
  border-bottom: 1px solid #f2f2f2;
  position: fixed;
  width: 100%;
  background-color: #fff;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  transition: all 0.3s;
  flex-wrap: wrap;
}

.burgerBtn {
  margin-left: 25px;
  display: flex;
  align-items: center;
  column-gap: 15px;

  .toggleSidebar {
    border: 0;
    background: transparent;
    padding: 0;
    width: 46px;
    height: 46px;
    line-height: 46px;
    outline: none;
  }

  .btn {
    font-size: 14px;
    height: 45px;
    line-height: 45px;
  }
}

.searchWrap .form-group {
  position: relative;
  margin: 0;
}

.form-control,
.react-tel-input .form-control {
  height: 46px;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 0 20px;
  border: 1px solid #f9f9f9;
  box-shadow: none !important;
  transition: all 0.3s;
  font-size: 14px;
  text-transform: none;
}

.react-tel-input .form-control {
  padding-left: 48px;
  width: 100%;
}

.basic-single.form-control {
  padding: 0;
}

.form-control:focus {
  transition: all 0.3s;
  border-color: #dfdfdf;
}

.timeWrap {
  display: flex;
  align-items: center;

  span {
    font-size: 17px;
    width: 57px;
    height: 57px;
    line-height: 57px;
    font-family: "Bahij-semibold";
    direction: ltr;
    background-color: #f8f8f8;
    border-radius: 100%;
    margin: 0 5px;
    text-align: center;
  }
}

.authWrap {
  .au-img {
    width: 62px;
    height: 62px;
    border-radius: 100%;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  li .au-flow {
    display: flex;
    align-items: center;
  }

  .dropdown {
    min-width: 160px;
  }

  .dropdown-menu {
    min-width: 100%;
    right: auto !important;
    transform: translate(0, 37px) !important;
  }

  .dropdown-toggle {
    display: flex;
    height: auto;
    line-height: normal;
    justify-content: space-between;
    width: 100%;

    svg {
      margin-top: 17px;
    }
  }

  .au-data {
    padding: 0 12px;
    text-align: initial;

    h4 {
      font-size: 14px;
      font-family: "Bahij-bold";
      text-transform: capitalize;
    }

    p {
      color: #8f8f8f;
      font-size: 12px;
    }
  }
}

.form-control::placeholder {
  color: #8f9bba;
  text-transform: inherit;
}

.authWrap li.menu-item-has-children {
  display: flex;
  align-items: center;
}

.noificationWrap {
  .menu-item-has-children {
    display: flex;

    .dropdown-toggle span {
      width: 35px;
      height: 35px;
      background-color: #f4f7fe;
      display: block;
      line-height: 35px;
      border-radius: 100%;
      text-align: center;
      position: relative;

      svg {
        width: 20px;
      }
    }

    > span {
      width: 35px;
      height: 35px;
      background-color: #f4f7fe;
      display: block;
      line-height: 35px;
      border-radius: 100%;
      text-align: center;
      position: relative;
    }

    .dropdown .btn {
      overflow: visible;
    }
  }

  .dropdown-menu {
    min-width: 350px;
  }
}

.badgo {
  position: absolute;
  right: -8px;
  top: -8px;
  font-style: normal;
  width: 20px;
  height: 20px;
  background-color: #fc6b14;
  line-height: 20px;
  color: #fff;
  border-radius: 100%;
  font-size: 11px;
}

.main-sidebar {
  width: 292px;
  position: fixed;
  height: 100%;
  top: 0;
  background-color: #fff;
  right: 0;
  z-index: 100;
  transition: all 0.3s;
}

.content-wrap {
  min-height: 100%;
  background-color: #f9f9f9;
  padding: 22px;
  margin: 116px 292px 0 0;
  transition: all 0.3s;
  width: auto !important;
  margin: 0 270px 0 0;
  padding: 89px 22px 22px;
  /* width        : calc(100% - 270px); */
}

.main-sidebar {
  .logo {
    padding: 36px 38px;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    align-items: center;
  }

  .side-nav {
    padding: 40px 23px 40px 10px;
    height: calc(100% - 300px);

    ul {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      padding: 0 0 0 15px;

      li a span {
        color: inherit;
        font-size: inherit;
        transition: all 0.3s;
      }
    }
  }
}

.side-actions a span {
  color: inherit;
  font-size: inherit;
  transition: all 0.3s;
}

.main-sidebar .side-nav ul li a,
.side-actions a {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 50px;
  transition: all 0.3s;
  color: #a3aed0;
  margin-bottom: 12px;
}

.main-sidebar .side-nav ul li a svg,
.side-actions a svg {
  margin: 0 0 0 13px;
}

.main-sidebar .side-nav {
  ul li a {
    &.active {
      background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
      transition: all 0.3s;
      color: #fff;

      svg {
        filter: brightness(0) invert(1);
        transition: all 0.3s;
      }

      ~ .children {
        background: #fff;
        transition: max-height 300ms ease, background-color 300ms linear;
        max-height: inherit;
        cursor: pointer;

        > li > {
          a {
            color: #929dbe;
            margin: 0;
          }

          .active-child {
            color: #fc6b14;
          }

          a:hover {
            color: #fc6b14;

            > span:before {
              background-color: #fc6b14 !important;
            }
          }
        }

        .active-child span:before {
          background-color: #fc6b14 !important;
        }
      }
    }

    ~ .children {
      max-height: 0;
      transition: max-height 300ms ease, background-color 300ms linear;
    }
  }

  .side-nav-child {
    position: relative;

    .arrow {
      position: absolute;
      transform: scale(1.3);
      cursor: pointer;
      left: 15px;
      top: 10px;
    }
  }

  ul li a.active ~ .children > li > a > span {
    position: relative;
    margin-right: 40px;
    font-family: "Bahij-light";
    font-size: 14px;
    font-weight: 200;
    line-height: 22px;
    letter-spacing: 0em;

    &:before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      right: -15px;
      top: 7px;
      background-color: #929dbe;
    }
  }
}

.side-actions {
  padding: 23px;
}

.themetoggle {
  width: 60px;
  height: 30px;
  border-radius: 50px;
  border: 0.5px solid #a3aed0;
  transition: all 0.3s;
  position: relative;
  background-color: #fff;

  i {
    width: 20px;
    height: 20px;
    position: absolute;
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    border-radius: 100%;
    line-height: 20px;
    top: 1px;
    left: 8px;
    transition: all 0.3s;

    svg path {
      transition: all 0.3s;
    }
  }

  &.fire {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    border-color: #fc6b14;
    transition: all 0.3s;

    i {
      transition: all 0.3s;
      left: calc(100% - 25px);
      transform: rotate(360deg);

      svg path {
        fill: #fc6b14;
        transition: all 0.3s;
      }
    }
  }
}

.main-sidebar .side-nav ul::-webkit-scrollbar,
.employees-list::-webkit-scrollbar,
.noificationWrap .dropdown ul::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.main-sidebar .side-nav ul::-webkit-scrollbar-track,
.employees-list::-webkit-scrollbar-track,
.noificationWrap .dropdown ul::-webkit-scrollbar-track {
  background-color: #f7f7f7;
  border-radius: 30px;
}

.main-sidebar .side-nav ul::-webkit-scrollbar-thumb,
.employees-list::-webkit-scrollbar-thumb,
.noificationWrap .dropdown ul::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
  border-radius: 30px;
}

.card {
  border-radius: 15px;
  border-color: #fff;
  padding: 30px;
  margin-bottom: 30px;
  transition: all 0.3s;
}

.topCards .card {
  text-align: center;

  i {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 13px;
    background-color: #f4f7fe;
    border-radius: 100%;
  }

  h4 {
    font-size: 14px;
    font-family: "Bahij-semibold";
  }

  span {
    color: #a3aed0;
    font-size: 12px;
    margin-top: 5px;
  }
}

.card:hover {
  box-shadow: 0 0 45px rgb(0 0 0 / 5%);
  transition: all 0.3s;
}

.card-blue-bg {
  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
}

.card-green-bg {
  background: linear-gradient(135deg, #2cc156 0%, #71d68e 100%);
}

.card-red-bg {
  background: linear-gradient(135deg, #868cff 0%, #f89b99 0.01%, #f46d6a 100%);
}

.projects-blocks .card {
  flex-wrap: wrap;
  position: relative;
  flex-direction: row;

  &:before {
    content: "";
    position: absolute;
    width: 35%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      186.12deg,
      #ffffff -9.77%,
      #ffffff -9.76%,
      rgba(255, 255, 255, 0) 88.02%
    );
    opacity: 0.2;
    -webkit-clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
    border-radius: 15px;
  }

  .b-info {
    text-align: initial;
    flex-grow: 1;

    h4 {
      color: #fff;
      font-size: 13px;
    }

    p {
      font-size: 37px;
      font-family: "Bahij-bold";
      color: #fff;
    }
  }

  > svg {
    position: absolute;
    left: 20px;
    bottom: 26px;
  }
}

.dropdown-toggle {
  border: 0;
  background: transparent !important;
  padding: 0;
  box-shadow: none !important;
  color: black;

  &:after {
    display: none;
  }
}

.dropdown-menu {
  border-radius: 10px;
  border: 1px solid #f7f7f7;
  box-shadow: 0 3px 20px rgb(0 0 0 / 8%);
  padding: 0;
  overflow: hidden;
  text-align: initial;

  a {
    transition: all 0.3s;
    padding: 8px 15px;
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    text-decoration: none !important;

    &:hover {
      transition: all 0.3s;
    }
  }
}

.recharts-surface {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.reports-blocks {
  .card h4 {
    font-size: 17px;
    font-family: "Bahij-semibold";
    margin-bottom: 35px;
  }

  .recharts-legend-wrapper {
    bottom: auto !important;
    top: -60px;
    left: 0 !important;
  }
}

.recharts-default-legend {
  text-align: left !important;
}

.recharts-legend-item {
  svg {
    width: 17px;
    height: 17px;
    border-radius: 5px;
    margin: 0 9px 0 0 !important;

    path {
      width: 100%;
      height: 100%;
    }
  }

  direction: ltr;
  align-items: center;
  display: inline-flex !important;
  margin: 0 30px 0 0 !important;
}

.recharts-legend-item-text {
  font-size: 13px;
  color: #4f4f4f !important;
  text-transform: none;
}

.pieChart {
  .recharts-legend-wrapper {
    left: auto !important;
    right: 0 !important;
    top: 50% !important;
    bottom: auto !important;
    transform: translateY(-50%);
    width: auto !important;
  }

  .recharts-default-legend {
    text-align: right !important;
  }

  .recharts-legend-item {
    direction: rtl !important;
    display: flex !important;
    margin: 0 0 11px !important;

    svg {
      margin: 0 0 0 9px !important;
    }

    &:last-child {
      margin: 0 !important;
    }
  }
}

.card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  flex-wrap: wrap;

  h4 {
    font-size: 20px;
    font-family: "Bahij-semibold";
    margin-bottom: 5px;
  }

  p {
    color: #a3aed0;
    font-size: 13px;
    width: 100%;
  }

  path {
    fill: currentColor;
  }
}

.project-item {
  border-radius: 15px;
  padding: 20px;
  position: relative;
  margin-bottom: 24px;

  .b-action {
    text-align: left;
    margin-bottom: 15px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 35%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      186.12deg,
      #ffffff -9.77%,
      #ffffff -9.76%,
      rgba(255, 255, 255, 0) 88.02%
    );
    opacity: 0.2;
    -webkit-clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
    border-radius: 15px;
  }

  .i-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    a {
      color: #fff;
      font-family: "Bahij-semibold";
      font-size: 17px;
      max-width: 70%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span {
      font-size: 13px;
      color: #fff;
    }
  }

  .i-body {
    margin-bottom: 10px;

    h5 {
      font-size: 15px;
      color: #fff;
      margin-bottom: 5px;
    }

    p {
      color: #fff;
      font-size: 13px;
      font-family: "Bahij-light";
    }
  }

  .i-footer ul {
    display: flex;
    align-items: center;

    li {
      color: #fff;
      margin-left: 13px;
      font-size: 13px;
      display: inline-flex;
      align-items: center;

      svg {
        margin-left: 7px;
      }
    }
  }
}

.customers-list {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 193px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f4f7fe;
  }

  &::-webkit-scrollbar-thumb {
    background: #a3aed0;
    border-radius: 10px;
  }

  .c-item {
    border-left: 1px solid #f2f2f2;
    text-align: center;
    margin-bottom: 30px;
  }

  .col-xl-6:nth-of-type(even) .c-item {
    border: 0;
  }

  .c-item {
    .i-img {
      width: 104px;
      height: 104px;
      background-color: #f4f7fe;
      border-radius: 100%;
      margin: 0 auto 17px;

      img {
        border-radius: 50%;
      }
    }

    .i-data {
      h4 {
        margin-bottom: 6px;
        font-size: 17px;
      }

      p {
        color: #a3aed0;
        font-size: 13px;
      }
    }
  }

  > {
    :last-child .c-item,
    :nth-last-child(2) .c-item {
      margin-bottom: 0;
    }
  }
}

.employees-list {
  .employee-item {
    &:last-child {
      margin-bottom: 0;
    }

    margin-bottom: 30px;
    display: flex;
    align-items: center;

    .i-img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }

    img {
      border-radius: 50%;
      object-fit: contain;
    }

    .i-info {
      padding: 0 15px;

      h4 {
        font-size: 15px;
        margin-bottom: 2px;
      }

      p {
        font-size: 13px;
        color: #a3aed0;
      }
    }
  }

  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 193px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f4f7fe;
  }

  &::-webkit-scrollbar-thumb {
    background: #a3aed0;
    border-radius: 10px;
  }
}

.sidebarToggled {
  .main-head .head-wrp {
    padding-right: 125px;
    transition: all 0.3s;
  }

  .main-sidebar {
    width: 125px;
    transition: all 0.3s;

    .logo .logo-info {
      opacity: 0;
      transition: all 0.3s;
      position: absolute;
    }

    .side-nav ul li a span {
      transition: all 0.3s;
      opacity: 0;
      position: absolute;
    }
  }

  .side-actions a span {
    transition: all 0.3s;
    opacity: 0;
    position: absolute;
  }

  .main-sidebar .side-nav ul li a,
  .side-actions a {
    text-align: center;
    justify-content: center;
    transition: all 0.3s;
    overflow: hidden;
  }

  .main-sidebar .side-nav ul li a svg,
  .side-actions a svg {
    margin: 0;
    transition: all 0.3s;
  }

  .content-wrap {
    margin-right: 125px;
    transition: all 0.3s;
    width: calc(100% - 90px);
  }

  .side-actions {
    text-align: center;
  }
}

.logo .inner {
  overflow: hidden;
  width: 120px;
  display: flex;
  align-items: center;
  column-gap: 10px;

  a {
    overflow: hidden;
    width: 120px;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  position: relative;
}

.head-wrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 29px 292px 29px 38px;
  transition: all 0.3s;
  position: relative;
  z-index: 1;
}

.main-head .logo {
  display: none;
  justify-content: center;
  padding: 18px;
  background-color: #f9f9f9;
  width: 100%;
}

.card-body {
  padding: 0;
}

.overlay-s {
  position: fixed;
  z-index: 99;
  background-color: rgb(4 13 40 / 55%);
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.show-btn,
.edit-btn,
.delete-btn {
  width: 35px !important;
  height: 35px !important;
  text-align: center;
  line-height: 35px;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px;
}

.show-btn svg,
.edit-btn svg,
.delete-btn svg {
  width: 15px;
  height: auto;
}

.show-btn {
  background-color: #259c46;
  margin-left: 10px;
}

.edit-btn {
  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
}

.delete-btn {
  background-color: #ff2625;
}

.btn {
  height: 45px;
  padding: 0 25px;
  line-height: 38px;
  border-radius: 8px;
  font-size: 15px;
  position: relative;
  box-shadow: none !important;

  &:before {
    content: "";
    position: absolute;
    display: block;
    background: var(--ripple-background, white);
    border-radius: 50%;
    pointer-events: none;
    top: calc(var(--y) * 1px);
    left: calc(var(--x) * 1px);
    width: calc(var(--d) * 1px);
    height: calc(var(--d) * 1px);
    opacity: calc(var(--o, 1) * var(--ripple-opacity, 0.3));
    transition: calc(var(--t, 0) * var(--ripple-duration, 600ms))
      var(--ripple-easing, linear);
    transform: translate(-50%, -50%) scale(var(--s, 1));
    transform-origin: center;
  }

  &.btn-blue {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 0;
  }

  &.btn-green {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    color: #fff;
  }

  &.btn-red {
    background-color: #ff2625;
    color: #fff;
  }

  &.btn-purple {
    background-color: #92a5f9;
    color: #fff;
  }

  &.btn-big {
    height: 67px;
    line-height: 67px;
    width: 100%;
  }

  &.btn-outline {
    border: 1.31429px solid rgba(86, 103, 137, 0.26);
    background-color: transparent;
    color: #000;
  }

  &.disabled {
    background: #f5f8fe;
    color: #a3aed0;
    border-color: #f5f8fe;
  }

  &.btn-big svg {
    margin-left: 24px;
  }
}

.control-wrp .card {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border-radius: 50px;

  h5 {
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  span svg {
    margin-left: 21px;
  }

  h5 img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    object-fit: contain;
  }
}

.card-inner .c-img img {
  object-fit: scale-down;
}

.control-wrp .card .btn {
  margin-right: 15px;
}

@keyframes click-wave {
  0% {
    height: 100%;
    width: 100%;
    opacity: 0.35;
    position: relative;
  }

  100% {
    height: 150%;
    width: 150%;
    opacity: 0;
    margin: -5px;
  }
}

.form-group .download-file {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 100px;
  background-color: #e9ecef;
  border-radius: 8px;
  padding: 8px 14px;
  transition: all ease 0.45s;

  &:hover {
    transform: scale(0.9);
    box-shadow: 0px 3.55168px 9.47116px rgba(0, 0, 0, 0.15),
      0px 3.55168px 1.18389px rgba(0, 0, 0, 0.06);
  }

  svg {
    position: inherit;
    top: unset;
    right: unset;
    transform: unset;
    transform: scale(1.3);
  }
}

// input {

//   &[type="checkbox"]:not(.form-check-input),
//   &[type="radio"]:not(.form-check-input) {
//     -webkit-appearance: none;
//     -moz-appearance   : none;
//     -ms-appearance    : none;
//     -o-appearance     : none;
//     appearance        : none;
//     position          : relative;
//     height            : 26px;
//     width             : 26px;
//     transition        : all 0.15s ease-out 0s;
//     background        : #fff;
//     border            : none;
//     color             : #fff;
//     cursor            : pointer;
//     display           : inline-block;
//     outline           : none;
//     z-index           : 1;
//     border-radius     : 4px;
//     border            : 1px solid #dfdfdf;
//     box-shadow        : none !important;
//   }

//   &[type="checkbox"]:not(.form-check-input):checked,
//   &[type="radio"]:not(.form-check-input):checked {
//     background  : #fff;
//     border-color: #f1f1f1;
//   }

//   &[type="checkbox"]:not(.form-check-input)::before,
//   &[type="radio"]:not(.form-check-input)::before {
//     width     : 100%;
//     position  : absolute;
//     text-align: center;
//     height    : 100%;
//   }

//   &[type="checkbox"]:not(.form-check-input):checked::before,
//   &[type="radio"]:not(.form-check-input):checked::before {
//     width      : 100%;
//     height     : 100%;
//     content    : "✔";
//     display    : inline-block;
//     font-size  : 15px;
//     line-height: 50%;
//     color      : #fc6b14;
//   }

//   &[type="checkbox"]:not(.form-check-input):checked::after {
//     -webkit-animation: click-wave 0.65s;
//     -moz-animation   : click-wave 0.65s;
//     animation        : click-wave 0.65s;
//     background       : #fc6b14;
//     content          : "";
//     display          : block;
//     position         : relative;
//     border-radius    : 4px;
//     z-index          : 2;
//   }

//   &[type="radio"] {
//     width       : 20px !important;
//     height      : 20px !important;
//     background  : transparent !important;
//     border-color: #fc6b14 !important;

//     &:checked::after {
//       -webkit-animation: click-wave 0.65s;
//       -moz-animation   : click-wave 0.65s;
//       animation        : click-wave 0.65s;
//       background       : #fc6b14;
//       content          : "";
//       display          : block;
//       position         : relative;
//       border-radius    : 4px;
//       z-index          : 2;
//     }

//     &::after {
//       border-radius: 100% !important;
//     }

//     border-radius: 100% !important;

//     &:checked::before {
//       content  : "•" !important;
//       font-size: 40px !important;
//     }
//   }
// }

input {
  &[type="checkbox"]:not(.form-check-input),
  &[type="radio"]:not(.form-check-input) {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    height: 26px;
    width: 26px;
    transition: all 0.15s ease-out 0s;
    background: #fff;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    outline: none;
    z-index: 1;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    box-shadow: none !important;

    // Adjust size for smaller screens
    @media (max-width: 768px) {
      height: 22px;
      width: 22px;
    }

    @media (max-width: 480px) {
      height: 18px;
      width: 18px;
    }
  }

  &[type="checkbox"]:not(.form-check-input):checked,
  &[type="radio"]:not(.form-check-input):checked {
    background: #fff;
    border-color: #f1f1f1;
  }

  &[type="checkbox"]:not(.form-check-input)::before,
  &[type="radio"]:not(.form-check-input)::before {
    width: 100%;
    position: absolute;
    text-align: center;
    height: 100%;
  }

  &[type="checkbox"]:not(.form-check-input):checked::before,
  &[type="radio"]:not(.form-check-input):checked::before {
    width: 100%;
    height: 100%;
    content: "✔";
    display: inline-block;
    font-size: 1rem; // Responsive font size
    line-height: 50%;
    color: #fc6b14;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }

    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }

  &[type="checkbox"]:not(.form-check-input):checked::after {
    -webkit-animation: click-wave 0.65s;
    -moz-animation: click-wave 0.65s;
    animation: click-wave 0.65s;
    background: #fc6b14;
    content: "";
    display: block;
    position: relative;
    border-radius: 4px;
    z-index: 2;
  }

  &[type="radio"] {
    width: 20px !important;
    height: 20px !important;
    background: transparent !important;
    border-color: #fc6b14 !important;

    @media (max-width: 768px) {
      width: 18px !important;
      height: 18px !important;
    }

    @media (max-width: 480px) {
      width: 16px !important;
      height: 16px !important;
    }

    &:checked::after {
      -webkit-animation: click-wave 0.65s;
      -moz-animation: click-wave 0.65s;
      animation: click-wave 0.65s;
      background: #fc6b14;
      content: "";
      display: block;
      position: relative;
      border-radius: 4px;
      z-index: 2;
    }

    &::after {
      border-radius: 100% !important;
    }

    border-radius: 100% !important;

    &:checked::before {
      content: "•" !important;
      font-size: 1.5rem !important; // Scaled font size

      @media (max-width: 768px) {
        font-size: 1.3rem !important;
      }

      @media (max-width: 480px) {
        font-size: 1.1rem !important;
      }
    }
  }
}

.custom-radio-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    input[type="radio"] {
      display: none;
    }

    span {
      position: relative;
      padding-right: 30px;
      font-size: 16px;
      display: flex;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #fc6b14;
        transition: background-color 0.3s ease, transform 0.3s ease;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%) scale(0);
        width: 10px;
        height: 10px;
        background-color: #fc6b14;
        border-radius: 50%;
        transition: transform 0.3s ease;
      }
    }

    input[type="radio"]:checked + span::after {
      transform: translateY(-50%) scale(1);
    }

    input[type="radio"]:hover + span::before {
      transform: translateY(-50%) scale(1.1);
    }
  }

  .text-danger {
    font-size: 12px;
    color: red;
    margin-top: 5px;
  }
}

.noificationWrap .menu-item-has-children .btn {
  height: auto;
  line-height: normal;
  padding: 0;
}

.table-wrap {
  .edit-btn {
    margin-left: 10px;
  }

  overflow: auto;
  min-height: 50vh;
}

.sc-dmlpXa.koyZDM {
  overflow: visible;
}

.rdt_Pagination {
  justify-content: space-between !important;
  padding: 30px 0 !important;
  border: 0 !important;

  span {
    margin: 0;
  }

  > div {
    border: 1px solid #fc6b14;
    border-radius: 50px;
  }
}

.table-wrap .card-body {
  border-top: 1px solid #f4f7fe;
}

.rdt_Pagination > div button svg {
  fill: #fc6b14;
}

.rdt_TableCell {
  padding: 19px !important;
  border: 0;
}

.rdt_TableRow,
.rdt_TableHeadRow {
  border-bottom: 2px solid #f2f2f2 !important;
}

.rdt_TableRow {
  &:nth-of-type(odd) {
    background-color: #f6f6f6;
  }

  &:last-child {
    border: 0 !important;
  }
}

.rdt_TableCol,
.rdt_TableCell:first-child {
  padding: 19px 0;
}

.table-wrap .card-body header {
  min-height: auto !important;
  padding: 0 !important;
}

.filter-wrp {
  margin-top: -100px;

  .form-group {
    position: relative;

    svg {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      right: auto;
    }
  }
}

.table-pager {
  padding: 40px 0;
  display: flex;
  justify-content: flex-end;

  .pagination {
    justify-content: end;
    border: 1px solid #fc6b14;
    border-radius: 50px;
    padding: 5px;
    overflow: hidden;

    .page-link {
      border: 0 !important;
      color: #fc6b14 !important;
      width: 37px;
      height: 37px;
      border-radius: 100%;
      background: transparent;
      font-size: 14px;
      line-height: 37px;
      padding: 0;
      font-size: 18px;
      box-shadow: none;
    }

    .active .page-link {
      color: #fff !important;
      background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    }

    .page-link {
      &:disabled {
        color: #bdbdbd !important;
      }

      &.prev-link,
      &.nxt-link {
        border-radius: 0;
        width: auto;
        padding: 0 20px;
        font-size: 14px;
      }
    }
  }
}

.table-select select {
  border: 1px solid #fc6b14;
  border-radius: 50px;
  padding: 5px;
  overflow: hidden;
  background: #fff;
  color: #fc6b14;
}

.form-check-input:not(.form-check-input):checked[type="checkbox"] {
  background-image: none !important;
}

.dropdown-toggle {
  padding: 0;
}

.form-control.form-outline {
  border-color: #efefef;
  background-color: transparent;
}

.form-group {
  &.required {
    h5:after,
    label:after:not(h5 + .img-pat) {
      content: "*";
      color: #ff0303;
      margin: 0 3px;
      font-size: 17px;
      line-height: 0;
    }

    h5 {
      label:after {
        content: "";
      }

      &.no-require:after {
        content: none;
      }
    }
  }

  &.has-error .form-control {
    border-color: #ff0303;
    color: #ff0303;
  }

  margin-bottom: 17px;

  h5 {
    font-size: 14px;
    margin-bottom: 11px;
  }

  svg {
    &:not(.leaflet-zoom-animated):not(.removeImg > svg) {
      // position : absolute;
      // right    : 15px;
      // top      : 50%;
      // transform: translateY(-50%);
    }

    + .form-control {
      padding: 0 45px;
    }
  }

  > div {
    position: relative;
  }
}

textarea.form-control {
  height: 211px;
  padding: 15px 20px;
}

.form-group {
  strong {
    position: absolute;
    left: 20px;
    font-weight: 300;
    color: #545454;
    font-size: 13px;
    top: 50%;
    transform: translateY(-50%);
  }

  .btn {
    height: 46px;
    line-height: 46px;
    width: 100%;
    border: 0;
  }
}

.flatpickr-day {
  font-size: 13px !important;
}

.flatpickr-months {
  .flatpickr-prev-month,
  .flatpickr-next-month {
    padding: 2px 10px !important;
    line-height: 34px !important;
  }
}

.flatpickr-current-month {
  padding: 7px 0 !important;
  font-size: 15px !important;

  .numInputWrapper {
    font-size: 15px !important;
  }
}

.flatpickr-day {
  &.selected,
  &.startRange,
  &.endRange,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected:focus,
  &.startRange:focus,
  &.endRange:focus,
  &.selected:hover,
  &.startRange:hover,
  &.endRange:hover,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay {
    background: linear-gradient(
      90deg,
      #e74c3c -0.02%,
      #fc6b14 99.99%
    ) !important;
    border-color: #fc6b14;
  }
}

span.flatpickr-weekday {
  font-size: 13px;
  font-family: "Bahij-bold";
  color: #000;
}

.numInputWrapper {
  // width: 100% !important; /* Ensure full width for inputs */
  direction: ltr;
}

.flatpickr-day.today {
  border-color: #fc6b14;

  &:hover {
    border-color: #fc6b14 !important;
    background: #fc6b14 !important;
  }
}

.modal-content {
  border-radius: 15px;
  border: 0;
}

.modal-header {
  padding: 35px;
  border: 0;

  .btn-close {
    opacity: 1 !important;
    color: #676767 !important;
    box-shadow: none !important;
  }
}

.modal-body {
  padding: 35px;
}

.modal-wrp {
  text-align: center;

  .modal-icon {
    width: 117px;
    height: 117px;
    margin: 0 auto 40px;
    position: relative;
  }

  h4 {
    font-size: 24px;
    margin-bottom: 14px;
    font-family: "Bahij-semibold";
    color: #000;
  }

  p {
    color: #3f3f3f;
    font-size: 12px;
  }
}

.modal-btns {
  display: flex;
  align-items: center;
  margin-top: 40px;
  justify-content: center;

  .btn {
    margin: 0 6px;
    height: 55px;
    padding: 0 50px;
    font-size: 18px;
    line-height: 55px;
  }
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.switcher {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 57px;
  height: 29px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 100px;

  .layer {
    border-radius: 100px;
  }

  .switcher-input {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #f5f7ff;
  transition: 0.3s ease all;
  z-index: 1;
}

.switcher {
  .knobs:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 4px;
    width: 22px;
    height: 22px;
    box-shadow: 0px 3.55168px 9.47116px rgba(0, 0, 0, 0.15),
      0px 3.55168px 1.18389px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  }

  .switcher-input {
    &:active + .knobs:before {
      width: 33px;
      border-radius: 100px;
    }

    &:checked {
      &:active + .knobs:before {
        margin-left: -25px;
      }

      .storePayment & + .knobs:before {
        content: "";
        background: url(../assets/svgs/house.svg);
        background-size: 8px;
        background-position: center;
        background-repeat: no-repeat;
        left: 29px;
      }
    }
  }
}

.switcher .switcher-input {
  &:checked ~ .layer {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
  }

  &:before,
  &:after {
    display: none !important;
  }
}

.payment-name {
  position: absolute;
  right: 0;
  bottom: 0;
  font-family: "Bahij-bold";
  font-size: 17px;
}

.form-group .show-pass {
  position: absolute;
  left: 10px;
  top: 0;
  border: 0;
  background-color: transparent;
  height: 46px;
  line-height: 46px;
  padding: 0 10px;

  svg {
    position: relative;
    right: auto;
    left: auto;
    transform: none;
    top: auto;
  }
}

.image-uploader {
  position: relative;
  width: 160px;
  height: 160px;
  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
  border-radius: 11px;
  margin-bottom: 45px;
  z-index: 1;
  padding: 0;
}

.img-pat {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 1;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: rgba(35, 54, 84, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px;

  img {
    object-fit: contain;
  }
}

.image-uploader {
  label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    cursor: pointer;
    display: none;
  }
}

.removeImg {
  position: absolute;
  left: -10px;
  top: -10px;
  padding: 0;
  border: 1px solid #ff2625;
  height: 25px;
  width: 25px;
  background-color: #ff2625;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;

  svg {
    fill: #fff;
    position: relative;
    right: auto;
    left: auto;
    transform: none;
    top: auto;
  }
}

.image-uploader label div {
  overflow: hidden;
  border-radius: 10px;
  background: #233654;
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
  }
}

.status {
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: #000;
  left: 0;
  border-radius: 100%;
  margin: 0 10px;
  top: 50%;
  transform: translateY(-50%);

  &.green {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
  }

  &.red {
    background-color: #f68583;
  }

  &.blue {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
  }
}

.form-uploader {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  padding: 0 20px;
  border: 2px dashed #efefef;
  box-shadow: none !important;
  transition: all 0.3s;
  font-size: 14px;
  text-transform: none;
  line-height: 46px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    position: absolute;
    left: 20px;
    right: auto;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 0 20px;
  }

  span {
    display: block;
    background: #fff;
    z-index: 1;
    position: absolute;
    width: calc(100% - 80px);
    right: 20px;
    top: 0;
    height: 44px;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

input[type="file"]::-webkit-file-upload-button {
  display: none;
}

.images-wrp {
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .img-holder {
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    overflow: hidden;
    margin: 7px;
    min-width: 96px;
    width: 96px;
  }
}

.activate {
  margin: 14px 0 60px;
}

.employee-wrap .form-group {
  margin-bottom: 30px;
}

.activate {
  span {
    color: #bbbbbb;
    font-size: 13px;
  }

  h5 {
    margin-bottom: 0;
    padding-top: 10px;
  }

  .switcher {
    bottom: auto;
    top: 0;
    position: relative;

    .switcher-input:checked ~ .layer {
      background-color: #49ca6e;
    }
  }
}

.searchWrap .form-group button svg {
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  transform: none;
}

.card-head.with-icon {
  > div {
    display: flex;
    align-items: center;
  }

  i {
    width: 37px;
    height: 37px;
    background-color: #f9f9f9;
    border-radius: 100%;
    text-align: center;
    line-height: 37px;
    margin-left: 19px;
  }

  > div {
    flex-wrap: wrap;
    align-items: flex-start;
  }

  h4 {
    width: 100%;
    font-size: 24px;
    color: #000;
  }

  p {
    font-size: 12px;
  }
}

.report-item {
  border-radius: 7px;
  background-color: #f7f7f7;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 20px 35px;
  transition: all 0.3s;
  min-height: 140px;

  &:hover {
    transform: scale(0.97);
    transition: all 0.3s;
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
  }

  .overlay-icon {
    opacity: 0.05;
    position: absolute;
    left: 11px;
    bottom: -5px;
    filter: brightness(0) invert(1);
  }

  .r-img {
    filter: brightness(0) invert(1);
  }

  .r-data {
    padding: 0 17px;

    h4 {
      font-size: 18px;
      font-family: "Bahij-semibold";
      margin-bottom: 8px;
      color: #fff;
    }

    p {
      color: #fff;
      font-size: 12px;
    }
  }
}

.itemTheme1 {
  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
}

.itemTheme2 {
  background-color: #dc42cf;
}

.itemTheme3 {
  background-color: #ff4d99;
}

.itemTheme4 {
  background-color: #ff8669;
}

.itemTheme5 {
  background-color: #ffc353;
}

.itemTheme6 {
  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
}

.report-item svg {
  position: absolute;
  left: 20px;
  top: 20px;
}

.customer-reports .card-head.with-icon i {
  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);

  svg {
    filter: brightness(0) invert(1);
  }
}

.customer-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #f5f7ff;
  border-radius: 10px;
  padding: 17px 36px;
  transition: all 0.3s;

  &:hover {
    background-color: #fff;
    box-shadow: 0 1px 10px rgb(0 0 0 / 10%);
    transition: all 0.3s;
  }

  &:last-of-type {
    margin: 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 5px;
    height: 30px;
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    border-radius: 15px 0 0 15px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  .i-img {
    background-color: #fff;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 33px;

    img {
      object-fit: contain;
    }
  }

  .i-data {
    h4 {
      font-size: 16px;
      color: #000;
    }

    p {
      color: #a3aed0;
      font-size: 13px;
    }
  }

  .i-action button {
    margin-right: 18px;
    border: 0;
    background: transparent;
    padding: 0;
  }
}

.radios-shape-buttons {
  label {
    width: 100%;
  }

  .form-group .btn {
    cursor: pointer;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    transition: all 0.3s;
  }
}

.btn.btn-gray {
  background-color: #f5f7ff;
  color: #090e25;
}

.radios-shape-buttons label input {
  display: none;

  &:checked + span {
    background-color: #53cc75 !important;
    color: #fff !important;
    transition: all 0.3s;
  }
}

.filter-wrp .form-group .form-control {
  min-width: 341px;
  padding-inline-start: 45px;
}

.employees-wrap .filter-wrp {
  margin: 0;
}

.employee-itm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f1f1f1;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 15px;
  position: relative;

  .i-img {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 0 0 15px;

    img {
      object-fit: cover;
    }
  }

  .i-data {
    h4 {
      font-family: "Bahij-bold";
      font-size: 16px;
      margin-bottom: 3px;
    }

    p {
      font-size: 13px;
    }
  }
}

.customer-itm:before {
  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
}

.employee-itm {
  .emp-times span {
    font-size: 14px;
    margin-right: 20px;
    cursor: default;
    direction: ltr;
  }

  .emp-data {
    display: flex;
    align-items: center;
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 30px;
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    border-radius: 13px 0 0 13px;
  }
}

.customer-itm {
  &[status] .emp-data:before {
    width: 9px;
    height: 9px;
    position: absolute;
    right: 0;
    bottom: 5px;
    content: "";
    border-radius: 100%;
    box-shadow: 0 0 0 2px #fff;
    z-index: 1;
    background-color: #ddd;
  }

  &[status="active"] .emp-data:before {
    background-color: #01e50a;
  }

  &[status="away"] .emp-data:before {
    background-color: #f91715;
  }
}

.itemTheme7 {
  background-color: #f68987;
}

.itemTheme8 {
  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
}

.permission-wrp {
  .report-item {
    border-radius: 10px;
    min-height: 70px;

    svg {
      display: none;
    }
  }

  .card-body .row {
    justify-content: center;
  }
}

.itemGray {
  background-color: #f5f7ff;
}

.bigCard {
  border-radius: 20px !important;
  min-height: 156px !important;
}

.itemGray {
  .r-img {
    width: 62px;
    height: 62px;
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    filter: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 15px;

    * {
      filter: brightness(0) invert(1);
    }
  }

  .r-data {
    h4 {
      color: #000;
    }

    p {
      color: #a3aed0;
    }
  }

  .overlay-icon {
    display: none;
  }

  &:hover {
    background-color: #fff;
  }
}

.error-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;

  h1 {
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type 0.5s alternate infinite;
  }
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }

  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

.page_404 {
  padding: 40px 0;
  background: #fff;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .text-center,
  img {
    width: 100%;
  }
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;

  h1,
  h3 {
    font-size: 80px;
  }
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

.noificationWrap {
  li {
    a {
      display: flex;
      align-items: center;
      padding: 15px;
      transition: all 0.3s;
      border-bottom: 1px solid #f7f7f7;
      background-color: #f6f6f6;
      [data-theme="dark"] & {
        border-color: #2d3e59;
        background-color: #233654;
      }
      &[data-notification-read="true"] {
        background-color: #fff;
        [data-theme="dark"] & {
          background-color: #1b293f;
        }
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  .i-img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: contain;
      width: 25px;
      height: 25px;
    }
  }

  .i-data p {
    font-size: 13px;
    overflow: hidden;
    max-height: 41px;
    color: #1b293f;
  }

  li:last-of-type {
    border: 0;
  }

  .dropdown ul {
    overflow: hidden;
    overflow-y: auto;
    margin: 0;
    max-height: 330px;
    padding: 0;
  }
}

.pieChart .recharts-layer text {
  transform: translate(20px, 1px);
}

.recharts-yAxis .recharts-layer text {
  transform: translate(-20px, -5px);
}

.logo-icon {
  width: 30px;
  height: 30px;
  min-width: 30px;
}

.logo .logo-info {
  width: 61px;
  min-width: 61px;
  padding: 0;
}

[data-animation] {
  position: relative;
  overflow: hidden;
}

.ripple {
  width: 2px;
  height: 2px;
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-animation: rippleEffect 0.5s ease-in-out;
  animation: rippleEffect 0.5s ease-in-out;
}

@-webkit-keyframes rippleEffect {
  0% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(var(--scale));
  }
}

@keyframes rippleEffect {
  0% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(var(--scale));
  }
}

.recharts-default-tooltip * {
  font-size: inherit;
  padding: 0 !important;
  text-transform: none;
  direction: ltr;
}

tspan,
.recharts-default-tooltip {
  font-size: 14px;
}

.form-check {
  padding-right: 0;
}

.images-wrp .img-holder img {
  object-fit: cover;
}

.offset-lg-5 {
  margin-right: 0;
}

line {
  stroke: #a3aed0;
}

.recharts-cartesian-axis line {
  stroke: #ebebeb;
}

.recharts-rectangle.recharts-tooltip-cursor {
  fill: #ebebeb;
}

.noificationWrap li li {
  display: flex;
  align-items: center;
  padding: 10px 0;
  transition: all 0.3s;
  column-gap: 10px;
}

.login-wrp {
  background: #233654;
  text-align: center;
  position: relative;
  padding: 200px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 155px;
    background-image: url(../assets/images/vectors.png);
    opacity: 0.4;
  }

  .login-logo {
    margin-bottom: 40px;

    img {
      width: 150px;
      object-fit: contain;
    }
  }

  .login-data {
    margin-bottom: 35px;

    h3 {
      color: #fff;
      font-family: "Bahij-bold";
      font-size: 26px;
      margin-bottom: 2px;
    }

    p {
      font-size: 18px;
      color: #fff;
      font-family: "Bahij-light";
    }
  }

  .login-form {
    margin: 0 auto;
    min-width: 310px;

    .form-group {
      position: relative;

      .form-control {
        height: 60px;
        border-radius: 50px;
        text-align: right;
        text-transform: none;
        border: 0;
        padding: 0 58px 0 20px;

        &::placeholder {
          color: rgb(51 51 51 / 30%);
        }
      }

      .btn {
        background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
        border-radius: 50px;
        height: 60px;
        line-height: initial !important;
        padding: 0 20px;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
}

.login--inner {
  position: relative;
  z-index: 1;
}

.form-group.branches {
  > h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Bahij-bold";
    font-size: 17px;
    margin-bottom: 20px;
  }

  h5 .btn {
    width: auto;
    font-size: 14px;
    font-family: "Bahij-regular";
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    padding: 0 20px;
  }

  border-radius: 10px;
  padding: 20px;
  border: 1px solid #efefef;

  .form-control {
    background: #fff;
  }
}

.add-multi-component {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 15px 15px 0;
  border: 1px solid #efefef;
  margin: 0 0 20px;
}

.store-activity .add-multi-component {
  position: relative;
}

.add-multi-component:last-of-type {
  margin-bottom: 0;
}

.error-hint {
  color: #ff5f5f;
  font-size: 11px;
  margin-top: 5px;
}

.has-tooltip:before {
  content: attr(title);
  position: absolute;
  top: 20px;
  transform: translateX(-50%);
  left: 50%;
  padding: 5px 10px;
  background: #3e3e3e;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  transition: all 0.3s;
  font-size: 12px;
  min-width: 100px;
  opacity: 0;
}

.bsgpsK div:first-child {
  overflow: visible !important;
  white-space: normal !important;
}

.has-tooltip {
  position: relative;

  &:hover:before {
    top: -35px;
    transition: all 0.3s;
    opacity: 1;
  }
}

.sidebarToggled .main-sidebar .side-nav ul li a ~ .children {
  max-height: 0;
  transition: max-height 300ms ease, background-color 300ms linear;
}

.branch-block {
  border-bottom: 1px solid #dfdfdf;
  padding: 15px 0;

  h5 {
    font-family: "Bahij-semibold";
    font-size: 16px;
    margin-bottom: 10px;
  }

  ul li {
    font-family: "Bahij-light";
    font-size: 13px;
    margin-bottom: 5px;
  }

  &:last-of-type {
    border: 0;
    padding-bottom: 0;
  }

  &:first-of-type {
    padding-top: 0;
  }

  ul li:last-child {
    margin: 0;
  }
}

.rdt_TableCell div {
  width: 100%;
}

.rdt_TableRow:last-of-type {
  border: 0 !important;
}

[type="email"],
[type="number"],
[type="date"] {
  direction: inherit;
}

.store-logo {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  object-fit: cover;
  min-width: 70px;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  min-height: 70px;
  max-width: 70px;
  overflow: hidden;
  font-size: 12px;
  text-align: center;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  border-width: 2px;
  border-radius: 10px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.thumbs {
  display: flex;
  align-items: center;
  margin: 20px 0;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;

  .thumb {
    width: 128px;
    height: 128px;
    position: relative;
    border: 1px solid #dfdfdf;
    border-radius: 10px;

    img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: #80808033;
    }

    .btn-remove {
      position: absolute;
      left: -10px;
      top: -10px;
      cursor: pointer;
      border: 1px solid #ff2625;
      height: 25px;
      width: 25px;
      background: #ff2625;
      border-radius: 50%;
      display: flow-root;
      align-items: center !important;
      justify-content: center !important;
      z-index: 2;
      box-shadow: 0 0 0 3px #fff;
      display: flex;

      svg {
        position: static !important;
        // position : relative;
        // right    : auto !important;
        // left     : auto;
        // top      : auto !important;
        // transform: none;
      }
    }

    &.file {
      .btn-remove {
        position: absolute;
        left: -10px;
        top: -10px;
        cursor: pointer;

        border: 1px solid #ff2625;
        height: 25px;
        width: 25px;
        background: #ff2625;
        border-radius: 50%;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        z-index: 2;
        box-shadow: 0 0 0 3px #fff;
      }
    }
  }
}

[type="color"] {
  padding: 0;
  overflow: hidden;
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
    width: 200px;
  }
}

.basic-single {
  svg {
    position: relative;
    top: auto;
    transform: none;
    right: auto;
  }

  .select__control {
    border-color: #efefef;
    min-height: 46px;
    border-radius: 10px;
    box-shadow: none;

    &:hover {
      border-color: #efefef;
    }
  }

  .select__placeholder {
    color: #8f9bba;
    font-size: 14px;
  }

  .select__value-container {
    padding: 2px 10px;
  }

  .select__option {
    font-size: 14px;
  }

  .select__menu-list {
    padding: 10px 0;
  }

  .select__multi-value {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    border-radius: 50px;
    padding: 2px 5px;

    .select__multi-value__remove svg {
      fill: #fff;
    }

    .select__multi-value__label {
      color: #fff;
      font-size: 13px;
    }

    .select__multi-value__remove {
      background: transparent;
    }
  }
}

.payment-logo {
  width: 160px;
  object-fit: contain;
}

.image-contain {
  background: #fff;

  label {
    border: 1px solid #efefef;
    border-radius: 10px;

    > div {
      background-color: #dfdfdf;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        object-fit: cover;
      }
    }
  }

  .img-pat img {
    filter: brightness(0.8);
  }
}

body .rdt_TableRow:last-of-type {
  border: 0 !important;
}

.topCards {
  svg {
    fill: #33c35c;
  }

  .col-xl-4:first-of-type {
    display: none;
  }
}

.dropdown-menu a svg {
  width: 25px;
  height: 25px;
  background: #eeeeee;
  border-radius: 100%;
  padding: 6px;
  overflow: visible;
}

[data-theme="dark"] .dropdown-menu a svg {
  background: #3a3a3a;
}

.dropdown-menu a svg {
  &.edit {
    background: linear-gradient(135deg, #2cc156 0%, #71d68e 100%);
  }

  &.delete {
    background: linear-gradient(135deg, #ff2625 0%, #f77776 100%);
  }
}

.lang-switcher .dropdown-toggle {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: #000 !important;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    min-width: 20px;
  }
}

.sidebarToggled .main-sidebar .side-nav ul li a.active ~ .arrow svg path {
  fill: #fff;
}

.main-sidebar .side-nav .side-nav-child .arrow svg path {
  fill: #a3aed0;
}

.basic-single {
  svg {
    position: relative;
    top: auto;
    transform: none;
    right: auto;
  }

  .select__control {
    border-color: #efefef;
    min-height: 46px;
    border-radius: 10px;
    box-shadow: none;

    &:hover {
      border-color: #efefef;
    }
  }

  .select__placeholder {
    color: #8f9bba;
    font-size: 14px;
  }

  .select__value-container {
    padding: 2px 10px;
    flex-wrap: unset;
  }

  .select__option {
    font-size: 14px;
  }

  .select__menu-list {
    padding: 10px 0;
  }

  .select__multi-value {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    border-radius: 50px;
    padding: 2px 5px;

    .select__multi-value__remove svg {
      fill: #fff;
    }

    .select__multi-value__label {
      color: #fff;
      font-size: 13px;
    }

    .select__multi-value__remove {
      background: transparent;
    }
  }
}

.stores-selection {
  .dropdown-toggle {
    height: 46px;
    border-radius: 10px;
    padding: 0 5px 0 15px;
    border: 1px solid transparent;
    box-shadow: none !important;
    transition: all 0.3s;
    font-size: 14px;
    text-transform: none;
    line-height: 46px;
    min-width: 250px;
    text-align: initial;
    display: flex;
    align-items: center;
    column-gap: 15px;
    color: #000;
    font-family: "Bahij-semibold";

    span {
      background-color: #f9f9f9;
      width: 46px;
      height: 46px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &::before {
      position: absolute;
      left: 18px;
      top: 18px;
      width: 9px;
      height: 9px;
      border-width: 1px 1px 0 0;
      border-style: solid;
      border-color: #a3aed0;
      border-radius: 0;
      transform: rotate(135deg);
      opacity: 1;
    }
  }

  .show .dropdown-toggle {
    border-color: #f9f9f9;
    background: #f9f9f9 !important;
    transition: all 0.3s;
    color: #8f9bba;
  }

  .dropdown-menu {
    width: 500px;
    right: 0 !important;
    left: auto !important;
    border-color: #fc6b14;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px 0 0 15px;
    min-height: 216px;
    background: #f9f9f9;

    a {
      &:hover {
        background: rgba(44, 133, 244, 0.1);
        color: #fc6b14;
      }

      border-radius: 5px;
      padding: 6px 13px;
      width: 50%;
      font-size: 16px;
      margin-bottom: 10px;

      img {
        position: fixed;
        left: 35px;
        top: 20px;
        width: 175px;
        height: calc(100% - 40px);
        object-fit: cover;
        border-radius: 10px;
        opacity: 0;
        transition: all 0.3s;
        background-color: #eee;
      }

      &:hover img {
        opacity: 1;
        transition: all 0.3s;
      }

      svg {
        fill: #fc6b14;
        position: absolute;
        left: 52%;
        margin-top: 2px;
        opacity: 0;
        transition: all 0.3s;
      }

      &:hover svg {
        opacity: 1;
        transition: all 0.3s;
      }
    }
  }
}

.drop-scrollable {
  overflow: hidden;
  position: relative;
  max-height: 180px;
  overflow-y: auto;
  padding: 0 25px;
}

.stores-selection .dropdown-menu .drop-scrollable::before {
  content: "";
  position: fixed;
  left: 35px;
  top: 20px;
  height: calc(100% - 40px);
  width: 175px;
  border-radius: 10px;
  background-color: #293e63;
  background-image: url(../assets/svgs/shop.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
  z-index: -1;
}

.drop-scrollable {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    border-radius: 15px;
  }
}

.stores-selection .dropdown-menu {
  .active-store {
    img,
    svg {
      opacity: 1;
    }

    background: rgb(251 106 22 / 16%);
    color: #fc6b14;
  }

  a svg {
    background: transparent;
    width: auto;
    height: auto;
    padding: 0;
    margin-left: 0;
    margin-top: 4px;
  }
}

.hkMDrI {
  overflow: visible !important;
}

.dropdown-menu a svg.show {
  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
}

.hkMDrI {
  overflow: visible !important;
}

.dropdown-menu a svg.show {
  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
}

.acc-form .nav {
  margin-bottom: 30px;
  column-gap: 15px;
  justify-content: space-between;

  .nav-link {
    background-color: #f7f7f7;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    color: #707070;
    transition: all 0.3s;

    &.active {
      background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
      color: #fff;
      transition: all 0.3s;
    }
  }

  .nav-item {
    width: 49%;
  }
}

.themes-img {
  height: 250px;
  border-bottom: 1px solid #f4f4f4;
  position: relative;

  .theme-price {
    position: absolute;
    left: 20px;
    top: 20px;
    background: #f42f41;
    border-radius: 50px;
    padding: 2px 20px;
    color: #fff;
    box-shadow: 0 3px 3px rgb(0 0 0 / 10%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Bahij-semibold";
    font-size: 14px;
  }
}

.themes-item {
  .themes-content {
    position: relative;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 65px;

    .btn {
      border-radius: 50px;
      height: 35px;
      line-height: 35px;
      font-size: 13px;
      color: #fff;

      &.btn-green {
        border-color: #fc6b14;
        color: #fff;
      }

      &:hover {
        background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
        border-color: #fc6b14;
        color: #fff;
      }

      &.btn-green:hover {
        border-color: #fc6b14;
        color: #fc6b14;
        color: #fff;
      }
    }
  }

  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
}

.themes-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.themes-item .themes-content h5 {
  font-family: "Bahij-bold";
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60%;
}

.themes-wrp {
  margin-bottom: 50px;

  .row {
    row-gap: 24px;
  }
}

.main-sidebar .side-nav .side-nav-child .active ~ .arrow svg path {
  fill: #fff;
}

.themes-item .themes-content span {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: #53cc75;

  svg {
    fill: #53cc75;
  }
}

/* .table-wrap .card-body header + div {
  overflow: visible !important;
} */

.users-wrap .table-wrap .card-body header + div {
  overflow: auto !important;
}

.themes-wrap {
  .col-xl-4 {
    display: none;
  }

  .col-xl-8 {
    width: 100%;
  }

  .row {
    row-gap: 24px;
  }

  .themes-item {
    background: #fff;
  }
}

.mce-content-body [class="btn"] {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

.tox-menubar,
.tox-statusbar__branding {
  display: none !important;
}

.tox-notifications-container {
  display: none !important;
  scale: 0;
  visibility: hidden;
}

.tox svg,
.creatable-select svg {
  transform: none;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  position: unset;
}

.side-slogan {
  font-size: 14px;
  font-family: "Bahij-semibold";
  background-color: #a3aed0;
  padding: 10px 35px;
  margin: 0 -25px 0 -10px;
  color: #fff;
}

.view-lines {
  text-transform: none;
}

.custom-css {
  background: #233654;
  border-radius: 50px;
  color: #fff !important;
  padding: 2px 13px;
  font-size: 15px;
  transition: 0.3s;
}

.monaco-editor * {
  text-transform: none;
  font-weight: normal !important;
}

.monaco-list-rows *,
.monaco-editor .view-line * {
  font-size: 14px;
  font-weight: normal;
}

.reports-blocks {
  .card {
    .card-head {
      padding-bottom: 15px;
      border-bottom: 1px solid #ece9f1;
      margin-bottom: 15px;

      h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
        width: 100%;

        a {
          font-family: "Bahij-semibold";
          font-size: 12px;
          color: #fc6b14;
          border-bottom: #fc6b14 solid 1px;
          padding-bottom: 2px;
        }
      }
    }

    h5 {
      margin-bottom: 15px;
      color: #4a4f5f;
      font-size: 14px;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    column-gap: 20px;

    .i-text {
      h4 {
        font-size: 32px;
        margin: 0 0 15px;
        line-height: 1;
      }

      p {
        font-size: 12px;
        line-height: 1;

        i {
          font-family: "Bahij-semibold";
        }

        img {
          margin: 0 0 0 5px;
        }
      }
    }

    .i-icon {
      width: 75px;

      img {
        object-fit: contain;
      }
    }

    &.visits .i-text p i {
      color: #05c3fb;
    }

    &.sales .i-text p i,
    &.visits .i-text p i.down {
      color: #f46ef4;
    }

    &.orders .i-text p i {
      color: #4ecc48;
    }

    &.target .i-text p i {
      color: #f7ba48;
    }
  }
}

.recharts-default-tooltip {
  background: #ffffff;
  box-shadow: 0px 5.64609px 5.64609px rgb(50 50 71 / 8%),
    0px 5.64609px 11.2922px rgb(50 50 71 / 6%);
  border-radius: 15px;
  border: 0 !important;
  padding: 10px 15px !important;
}

.reports-blocks .card .card-head {
  p {
    width: 100%;
  }

  flex-wrap: wrap;
}

.orders-list li {
  display: flex;
  align-items: center;
  column-gap: 22px;
  border-bottom: 1px solid #f4f7fe;
  padding: 22px;
  position: relative;

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .order-img {
    width: 65px;
    height: 65px;
    min-width: 65px;
    overflow: hidden;
    border-radius: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .order-data {
    flex-grow: 1;

    h4 {
      margin-bottom: 10px;
      font-size: 14px;
      font-family: "Bahij-semibold";

      a {
        color: inherit;
        font-size: inherit;
        font-family: inherit;
        transition: 0.3s;
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    div {
      column-gap: 14px;
      display: flex;
      align-items: center;
    }

    span {
      color: #a3aed0;
      font-size: 12px;
    }

    strong {
      color: #a3aed0;
      font-weight: normal;
      font-size: 12px;
    }

    i {
      color: #53cc75;
      font-family: "Bahij-semibold";
      font-size: 12px;
    }

    strong {
      &::before {
        content: "";
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background-color: #f7f7f7;
        margin: 0 0 0 7px;
      }

      &.paid::before {
        background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
      }

      &.onDelivery::before {
        background-color: #edd246;
      }

      &.canceled::before {
        background-color: #f13434;
      }
    }

    span img {
      margin: 0 0 0 5px;
    }
  }

  &:first-child {
    padding-top: 0;
  }
}

.more-orders {
  text-align: center;
  margin-top: 22px;

  a {
    font-size: 12px;
    color: #fc6b14;
    font-family: "Bahij-semibold";
  }
}

.orders-wrap .more-orders {
  display: none;
}

.products-list li {
  border-bottom: 1px solid #f4f7fe;
  padding: 10px 0;
  display: flex;
  align-items: center;
  column-gap: 13px;

  &:first-child {
    padding-top: 0;
  }

  .product-img {
    width: 41px;
    height: 41px;
    min-width: 41px;
    border-radius: 100%;
    background: #ecebe9;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .product-data {
    a {
      font-size: 12px;
      font-family: "Bahij-semibold";
      color: #ff2625;
    }

    h4 {
      margin: 0;
      font-size: inherit;
      color: inherit;
      font-family: inherit;
    }

    p {
      font-size: 12px;
      color: #a3aed0;
      font-family: "Bahij-light";
    }
  }

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }
}

.order-top {
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;

  .order-table {
    background: #f8f8f8;
    border: 0.652268px solid #f6f6f6;
    border-radius: 8px 8px 0px 0px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    margin-bottom: 40px;
    row-gap: 20px;

    li {
      font-family: "Bahij-semibold";
      font-size: 14px;

      span {
        display: block;
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }

  .orderslist {
    > h4 {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 14px;
      font-size: 14px;
      font-family: "Bahij-bold";
    }

    ul li {
      display: flex;
      align-items: center;
      column-gap: 30px;
      border-bottom: 1px solid #eeeeee;
      padding: 20px 0;

      .order-img {
        background: #f6f6f6;
        border-radius: 3px;
        width: 98px;
        height: 90px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .order-data {
        h4 {
          font-size: 14px;
          font-family: "Bahij-bold";
          color: #000;
          margin-bottom: 7px;

          span {
            font-size: inherit;
            margin: 0 0 0 10px;
          }

          a {
            font-size: inherit;
            font-family: inherit;
            color: inherit;
            transition: 0.3s;
          }
        }

        p {
          font-family: "Bahij-semibold";
          font-size: 13px;
        }

        h4 a:hover {
          color: #fc6b14;
        }
      }

      &:last-child {
        border: 0;
        padding-bottom: 0;
      }
    }
  }
}

.order-bottom {
  border: 1.10795px solid #eeeeee;
  border-radius: 8px;
  overflow: hidden;

  h4 {
    background: #f8f8f8;
    padding: 15px 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Bahij-bold";
    font-size: 17px;

    span {
      color: #fc6b14;
      font-family: "Bahij-semibold";
    }
  }

  li {
    border-bottom: 1.10795px solid rgba(226, 226, 226, 0.6);
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.85);
    font-family: "Bahij-semibold";
    font-size: 13px;
  }

  ul {
    padding: 15px 35px;
  }

  li {
    &:last-child {
      border: 0;
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: "Bahij-bold";
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }

    span {
      font-family: "Bahij-light";
      font-size: inherit;
    }

    &:last-child span {
      font-family: "Bahij-semibold";
    }
  }
}

.orders-wrap {
  .orders-list li:last-child {
    border: 0;
  }

  .orders-statuses {
    display: flex;
    align-items: center;
    column-gap: 10px;

    div {
      position: relative;
      cursor: pointer;
      border: 1px solid transparent;

      &.active {
        border-color: #fc6b14;
      }

      .removeBtn {
        background-color: #ff2625;
        border-radius: 50px;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 7px;

        .btn-close {
          filter: brightness(0) invert(1);
          opacity: 1;
        }
      }
    }
  }
}

.main-head .stores-selection {
  display: none;

  .dropdown-toggle {
    color: #000;

    &::before {
      border-color: #a3aed0;
    }
  }

  .dropdown-menu {
    background: #f9f9f9;
  }
}

.authWrap .dropdown-menu .dropdown-item {
  &:last-child {
    border-top: 1px solid #f4f4f4;
    color: #f44336 !important;
  }

  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 12px 15px;
  font-size: 13px;

  &.active img {
    filter: brightness(0) invert(1);
  }

  img {
    transition: 0.3s;
  }
}

.head-wrp .left-actions {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.dropdown-item {
  &:focus,
  &:hover {
    background-color: #f7f7f7;
  }
}

.notification-head {
  padding: 12px 10px;
  border-bottom: 1px solid #ece9f1;

  span {
    font-family: "Bahij-semibold";
  }
}

.notification-footer {
  padding: 12px 10px;
  border-top: 1px solid #ece9f1;
  text-align: center;
}

.noificationWrap {
  .notification-footer a {
    color: #fc6b14;
    font-size: 12px;
    font-family: "Bahij-semibold";
    display: inline-flex;
    padding: 0;
    column-gap: 6px;
    background: transparent !important;
  }

  .dropdown-menu {
    padding: 0;
  }

  .i-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
    flex-grow: 1;

    h5 {
      font-family: "Bahij-semibold";
      font-size: 12px;
      margin-bottom: 5px;
    }

    strong {
      color: #a3aed0;
      font-size: 12px;
      font-weight: normal;
      font-family: "Bahij-light";
    }

    .product-img {
      width: 35px;
      height: 35px;
      background-color: #ecebe9;
      border-radius: 100%;
      padding: 6px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    > div:not(.product-img) {
      flex-grow: 1;
    }
  }
}

.darkSwitcher {
  width: 35px;
  height: 35px;
  border: 0;
  border-radius: 100%;
  background: transparent;
  margin: 0 10px;
  padding: 0;
  color: #000;

  svg {
    fill: #fff;

    line {
      stroke: #fff;
    }
  }

  img {
    transition: 0.3s;
  }
}

[data-theme="dark"] .darkSwitcher img,
.darkSwitcher.fire img {
  filter: brightness(0) invert(1);
}

.right-actions {
  display: flex;
  align-items: center;
}

.stores-selection {
  .dropdown {
    display: flex;
    align-items: center;
    column-gap: 12px;

    span {
      width: 83px;
      height: 73px;
      border-radius: 14px;
      position: relative;
      transition: 0.3s;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: inherit;
        background-color: #293e63;
        background-image: url(../assets/svgs/shop.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 30px;
      }

      .rippon {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -8px;
        width: 60px;
        height: 30px;
        object-fit: contain;
        max-width: none;
        border-radius: 0;
        z-index: 2;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
        position: relative;
        z-index: 1;
      }
    }

    .dropdown-toggle {
      min-width: 135px;
      display: block;
      height: auto;
      line-height: 1;
      overflow: visible;
      margin-bottom: 5px;
      font-size: 14px;
    }

    a {
      font-size: 12px;
      color: #e1e6f4;
      font-family: "Bahij-light";
      transition: 0.3s;
    }
  }

  .dropdown-toggle::before {
    top: 5px;
    left: 0;
  }

  .dropdown span i {
    position: absolute;
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
    top: -4px;
    font-size: 11px;
    font-family: "Bahij-semibold";
    z-index: 3;
  }

  .dropdown-toggle::before {
    border: 0;
    clip-path: polygon(50% 65%, 0 0, 100% 0);
    transform: none;
    background: #aeaeae;
  }
}

.sidebarToggled .main-sidebar .stores-selection .dropdown {
  a {
    font-size: 0;
  }

  span {
    width: 73px;
    height: 63px;
    border-radius: 8px;
  }
}

.searchToggle {
  border: 0;
  background: transparent;
  padding: 0;
  width: 35px;
  height: 35px;
  background-color: #f4f7fe;
  border-radius: 100%;
  display: none;

  img {
    filter: brightness(0);
  }
}

.main-head .logo > div {
  display: flex;
  column-gap: 10px;
}

.head-wrp .stores-selection .dropdown a {
  color: #000;
}

.searchWrap .form-group {
  min-width: 445px;
  background-color: #f9f9f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;
  height: 40px;

  input.form-control {
    width: 300px;
    border: 0;
    padding: 0 0 0 14px;
    border-radius: 0;
    height: auto;
    border-left: 1px solid rgb(117 117 117 / 20%);
    background-color: transparent !important;
  }

  select.form-control {
    border-radius: 0;
    padding: 0;
    text-align: center;
    width: 90px;
    height: auto;
    background: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    border: 0;
  }

  button {
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 8px;
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);

    svg {
      fill: #fff;
    }
  }

  select.form-control option {
    font-size: inherit;
  }
}

.breadcrumb {
  margin-bottom: 20px;

  ul {
    display: flex;
    align-items: center;

    li {
      color: #233654;
      font-size: 13px;
      display: flex;
      align-items: center;

      a {
        font-size: inherit;
        display: flex;
        align-items: center;
        column-gap: 5px;
        color: #fc6b14;
      }

      &.parent a {
        color: #233654;
      }
    }

    li:not(:last-child)::after {
      content: "";
      background-image: url(../assets/svgs/angle-left.svg);
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      filter: brightness(0);
      margin: 0 3px;
    }
  }
}

/* .breadcrumb ul *:last-child::after {
  display: none;
} */

.ordersActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.categories-page .ordersActions,
.brands-wrap .ordersActions {
  gap: 20px;
}

.categories-page .ordersActions .filter-toolbar__search,
.brands-wrap .ordersActions .filter-toolbar__search {
  flex: auto;
}

.ordersActions {
  .btn {
    border: 0;
    font-size: 12px;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff !important;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
  }
}

.ordersServices {
  position: relative;

  .form-control {
    background: #ffffff;
    border-radius: 8px;
    height: 38px;
    padding: 0 25px;
    border: 0;
    text-align: center;
    font-size: 12px;
    min-width: 130px;

    option {
      font-size: 13px;
    }
  }
}

.ordersActions .btn-black {
  background: #0b1e2b;
  color: #fff;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 0 15px;
  min-width: 130px;
  font-size: 12px;
  height: 40px;
}

.select div::before {
  content: "";
  position: absolute;
  top: 15px;
  clip-path: polygon(50% 80%, 0 0, 100% 0);
  left: 15px;
  width: 8px;
  height: 7px;
  background: #aeaeae;
}

.ordersFilterSidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 293px;
  z-index: 101;
  background: #fff;
  height: 100%;
  transition: 0.3s;
  transform: translateX(-150%);
  padding: 16px;

  &.active {
    transform: none !important;
  }
}

.overlay-g {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(11 30 43 / 70%);
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.sidebarHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 25px;
  border-bottom: 1px solid #f4f7fe;

  span {
    display: flex;
    align-items: center;
    column-gap: 12px;
    font-family: "Bahij-semibold";

    img {
      filter: brightness(0);
    }
  }

  button {
    border: 0;
    padding: 0;
    background: transparent;
  }
}

.sidebarFooter {
  display: flex;
  align-items: center;
  column-gap: 20px;
  justify-content: space-between;
  padding: 25px 0 10px;
  border-top: 1px solid #f4f7fe;

  .btn {
    height: 40px;
    font-size: 12px;
    font-family: "Bahij-semibold";
    border-radius: 8px;
    border: 1px solid;
    width: 50%;
    padding: 0 10px;

    &.btn-red {
      background: transparent;
      color: #ff5f5f;
    }
  }
}

.sidebarBody {
  height: calc(100% - 185px);
  overflow: hidden;
  overflow-y: auto;
  max-width: 100%;
  padding: 0 0 0 10px;
  margin: 23px 0;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    border-radius: 15px;
  }
}

.accordion-item {
  border: 0;
  border-radius: 0 !important;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
}

.accordion-header {
  .accordion-button {
    border: 0;
    padding: 0;
    column-gap: 11px;
    font-family: "Bahij-semibold";
    font-size: 14px;
    border-radius: 0 !important;
    box-shadow: none;
    outline: none;
    color: #000;
    background: transparent;

    &::after {
      background: transparent;
      height: 2px;
      width: 14px;
      background-color: #000;
      position: absolute;
      left: 0;
      top: 50%;
      translate: 0 -50%;
      content: "";
      transition: 0.3s;
    }

    &::before {
      background: transparent;
      height: 2px;
      width: 14px;
      background-color: #000;
      position: absolute;
      left: 0;
      top: 50%;
      translate: 0 -50%;
      content: "";
      transition: 0.3s;
      transform: none;
    }
  }

  margin-bottom: 20px;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    right: 0;
    width: calc(100% - 35px);
    height: 1px;
    background-color: #f4f7fe;
    z-index: -1;
  }
}

.accordion-item::before {
  content: "";
  position: absolute;
  top: 35px;
  left: 0;
  width: 1px;
  height: calc(100% - 35px);
  background-color: #f4f7fe;
  z-index: -1;
}

.accordion-header .accordion-button.collapsed::before {
  transform: rotate(90deg);
}

.accordion-body {
  padding: 0 0 20px 20px;
  border-bottom: 1px solid #f4f7fe;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin: 0;
    }

    label {
      display: inline-flex;
      align-items: center;
      column-gap: 12px;
      cursor: pointer;
      user-select: none;
    }

    input[type="checkbox"] {
      border: 1px solid #d1d1d1;
      box-shadow: inset 0px 1px 1px rgb(0 0 0 / 20%) !important;
      background: #f8f8f8;
      width: 13px;
      height: 13px;
      border-radius: 4px;
      overflow: hidden;
    }

    span {
      font-size: 14px;
    }

    input[type="checkbox"]::before {
      width: 100%;
      height: 100%;
      font-size: 11px;
      line-height: 13px;
    }
  }
}

input[type="checkbox"]:not(.form-check-input):checked::before {
  background: #fc6b14;
  color: #fff;
}

.accordion-body li input[type="checkbox"]:checked {
  border-color: #fc6b14;
  box-shadow: inset 0px 1px 1px rgb(52 108 244 / 20%) !important;
  background: #fc6b14;
  transition: 0.3s;
}

.accordion-header .accordion-button span {
  display: inline-flex;
  column-gap: 12px;
  background: #fff;
  padding: 0 0 0 15px;
  font-size: inherit;
  font-family: inherit;
  align-items: center;
}

.accordion-body li input {
  &[type="radio"] {
    &:checked::before {
      background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
      color: #fff;
      width: 100%;
      height: 100%;
      font-size: 15px;
      line-height: 0.5;
    }

    width: 13px;
    height: 13px;
    border-radius: 100% !important;
    border: 1px solid #d1d1d1;
    box-shadow: inset 0px 1px 1px rgb(0 0 0 / 20%) !important;
    transition: 0.3s;
    overflow: hidden;
  }

  &[type="checkbox"] {
    width: 13px;
    height: 13px;
    border-radius: 100% !important;
    border: 1px solid #d1d1d1;
    box-shadow: inset 0px 1px 1px rgb(0 0 0 / 20%) !important;
    transition: 0.3s;
    overflow: hidden;
  }

  &[type="radio"]:checked,
  &[type="checkbox"]:checked {
    border: 1px solid #fc6b14;
    box-shadow: inset 0px 1px 1px rgb(52 108 244 / 20%) !important;
    background: #fc6b14;
  }
}

input[type="datetime-local"] {
  text-align: right;
  position: relative;
  padding: 0 45px 0 20px;

  &::-webkit-inner-spin-button {
    z-index: 1;
  }

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  &::after {
    position: absolute;
    content: "";
    right: 20px;
    background-image: url(../assets/svgs/calendar.svg);
    width: 15px;
    height: 15px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.5;
    transition: 0.3s;
  }

  &:hover::after {
    opacity: 1;
  }
}

.accordion-item:last-of-type {
  margin: 0;
}

.accordion-body li .form-group {
  .form-control {
    border: 1px solid #dfe9ff;
    border-radius: 4px;
    background: transparent;
    font-family: "Bahij-light";
  }

  label {
    column-gap: 0;
    font-family: "Bahij-semibold";
    font-size: 14px;
    margin-bottom: 14px;
  }
}

.head-wrp {
  padding: 10px 280px 10px 15px;
}

.sidebarToggled .main-head .head-wrp {
  padding: 10px 95px 10px 15px;
}

.ordersServices {
  .dropdown-menu {
    min-width: 200px;
  }

  .dropdown-toggle {
    background: #ffffff !important;
    padding: 0 20px;
    border-radius: 8px !important;
    color: #000 !important;
    font-family: "Bahij-semibold";
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 12px;
    height: 40px;

    &::after {
      display: inline-block !important;
      border-top-color: #aeaeae;
    }
  }

  .show .dropdown-toggle {
    color: #fc6b14;
  }
}

.order-btns {
  .ordersServices {
    .dropdown-toggle {
      border: 1px solid #dfe9ff;
      height: 45px;
    }

    &.quickAction .btn {
      background: #ff2873 !important;
      border-color: #ff2873 !important;
      color: #fff;
      display: flex;
      align-items: center;
      column-gap: 10px;

      &::after {
        display: none !important;
      }
    }
  }

  display: flex;
  align-items: center;
  column-gap: 10px;
}

.homeOrders .order-btns {
  display: none;
}

.settings .row {
  row-gap: 24px;
}

.settingsHead {
  .card {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  div {
    display: flex;
    align-items: center;
    column-gap: 15px;
    font-size: 20px;
    font-family: "Bahij-semibold";

    span {
      width: 35px;
      height: 35px;
      background: #f9f9f9;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    }
  }

  .nav {
    column-gap: 0;

    .nav-link {
      color: #a3aed0;
      font-size: 13px;
      font-family: "Bahij-semibold";
      transition: 0.3s;
      padding: 12px 25px;
      border-radius: 8px;

      &.active {
        background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
        color: #fff;
      }
    }
  }
}

.stores-selection .dropdown span img[src=""] {
  display: none;
}

.no-store {
  .row {
    justify-content: center;
  }

  padding: 50px 0;

  .card {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;

    &:last-of-type {
      margin-bottom: 0;
    }

    h4 {
      font-size: 24px;
      font-family: "Bahij-semibold";
      margin-bottom: 15px;
    }

    p {
      color: #707070;
      font-family: "Bahij-light";
      font-size: 14px;
    }

    .s-img {
      width: 200px;
      height: 200px;
      border-radius: 12px;
      overflow: hidden;
    }
  }

  .preview {
    height: 425px;
    padding: 0;
    display: block;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}

.orderTop {
  margin-bottom: 50px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      text-align: center;
      font-size: 14px;
      font-family: "Bahij-semibold";

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        font-size: 12px;
        font-family: inherit;
        color: #a3aed0;
        margin-bottom: 10px;
      }

      .dropdown-toggle {
        border-radius: 22px;
        background: linear-gradient(
          90deg,
          #e74c3c -0.02%,
          #fc6b14 99.99%
        ) !important;
        box-shadow: 0px 4px 9px rgb(23 216 170 / 25%) !important;
        color: #fff !important;
        font-size: 12px;

        img {
          filter: invert(1);
        }

        &::after {
          border-top-color: #fff;
        }
      }
    }
  }
}

.orderBlocks .card {
  padding: 0;
  border: 0;
  min-height: 486px;
  height: 100%;

  .c-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f8fe;
    padding: 15px 20px;
    border-radius: 15px 15px 0 0;

    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      column-gap: 10px;
      font-family: "Bahij-semibold";
      color: #a3aed0;
    }

    .ordersServices .dropdown-toggle {
      font-size: 12px;
      background: #fc6b14 !important;
      box-shadow: 0px 2.66667px 6px rgb(52 107 244 / 25%) !important;
      color: #fff;
      font-family: "Bahij-semibold";
      height: 40px;

      img {
        filter: invert(1);
      }

      &::after {
        border-top-color: #fff;
      }
    }
  }

  .c-body {
    padding: 20px 15px;
  }

  .userInfo {
    display: flex;
    column-gap: 15px;

    .userImg {
      width: 55px;
      height: 55px;
      overflow: hidden;
      border-radius: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .userDetails {
      h4 {
        font-size: 14px;
        font-family: "Bahij-semibold";
        margin-bottom: 7px;
      }

      p {
        color: #fc6b14;
        font-size: 15px;
        font-family: "Bahij-semibold";
        margin-bottom: 12px;
        line-height: 1;
      }

      .btn {
        background: #fc6b14;
        box-shadow: 0px 2.66667px 6px rgb(52 107 244 / 25%) !important;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        font-family: "Bahij-semibold";
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 0 20px;
      }

      a img {
        width: 20px;
        height: 20px;
        background-color: #fc6b14;
        border-radius: 100%;
        padding: 4px;
      }
    }
  }

  .c-head .btn {
    display: flex;
    align-items: center;
    column-gap: 12px;
    font-size: 12px;
    font-family: "Bahij-semibold";
    box-shadow: 0px 2.66667px 6px rgb(52 107 244 / 25%) !important;

    img {
      filter: brightness(0) invert(1);
    }
  }
}

.shipInfo {
  border-bottom: 1px solid #f4f7fe;
  padding-bottom: 25px;
  margin-bottom: 30px;

  h5 {
    font-size: 13px;
    font-family: "Bahij-semibold";
    margin-bottom: 7px;
  }
}

.reviewShip h5 {
  font-size: 13px;
  font-family: "Bahij-semibold";
  margin-bottom: 7px;
}

.shipInfo {
  > img {
    margin-bottom: 20px;
  }

  p {
    font-size: 12px;
    font-family: "Bahij-light";
  }

  a {
    font-size: 12px;
    font-family: "Bahij-bold";
    color: #fc6b14;
    margin-bottom: 5px;
    text-decoration: underline;
  }
}

.reviewShip {
  a {
    font-size: 12px;
    font-family: "Bahij-bold";
    color: #fc6b14;
    margin-bottom: 5px;
    text-decoration: underline;
  }

  h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 15px;

      button {
        border: 0;
        background: transparent;
        display: flex;
        align-items: center;
        column-gap: 6px;
        font-size: 12px;
        font-family: "Bahij-semibold";
        color: #fc6b14;

        + button {
          color: #ff3f78;
        }
      }
    }

    a {
      margin: 0;
      text-decoration: none;
    }
  }

  .btn {
    background: #fc6b14;
    box-shadow: 0px 2.66667px 6px rgb(52 107 244 / 25%) !important;
    font-size: 12px;
    font-family: "Bahij-semibold";
    display: flex;
    align-items: center;
    margin-top: 45px;
  }
}

.payInfo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  column-gap: 20px;
  font-size: 12px;
  font-family: "Bahij-semibold";

  i {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #f5f8fe;
  }
}

.payDetails p {
  font-size: 12px;
  font-family: "Bahij-light";

  a {
    font-family: "Bahij-semibold";
    font-size: inherit;
    color: #fc6b14;
  }
}

.shipInfo {
  border-bottom: 1px solid #f4f7fe;
  padding-bottom: 25px;
  margin-bottom: 30px;

  h5 {
    font-size: 13px;
    font-family: "Bahij-semibold";
    margin-bottom: 7px;
  }
}

.reviewShip h5 {
  font-size: 13px;
  font-family: "Bahij-semibold";
  margin-bottom: 7px;
}

.shipInfo {
  > img {
    margin-bottom: 20px;
  }

  p {
    font-size: 12px;
    font-family: "Bahij-light";
  }

  a {
    font-size: 12px;
    font-family: "Bahij-bold";
    color: #fc6b14;
    margin-bottom: 5px;
    text-decoration: underline;
  }
}

.reviewShip {
  a {
    font-size: 12px;
    font-family: "Bahij-bold";
    color: #fc6b14;
    margin-bottom: 5px;
    text-decoration: underline;
  }

  h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 15px;

      button {
        border: 0;
        background: transparent;
        display: flex;
        align-items: center;
        column-gap: 6px;
        font-size: 12px;
        font-family: "Bahij-semibold";
        color: #fc6b14;

        + button {
          color: #ff3f78;
        }
      }
    }

    a {
      margin: 0;
      text-decoration: none;
    }
  }

  .btn {
    background: #fc6b14;
    box-shadow: 0px 2.66667px 6px rgb(52 107 244 / 25%) !important;
    font-size: 12px;
    font-family: "Bahij-semibold";
    display: flex;
    align-items: center;
    margin-top: 45px;
  }
}

.payInfo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  column-gap: 20px;
  font-size: 12px;
  font-family: "Bahij-semibold";

  i {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #f5f8fe;
  }
}

.payDetails p {
  font-size: 12px;
  font-family: "Bahij-light";

  a {
    font-family: "Bahij-semibold";
    font-size: inherit;
  }
}

.productsTable {
  th {
    background: #f5f8fe;
    padding: 20px;
    font-size: 14px;
    font-family: "Bahij-semibold";
    font-weight: normal;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  td {
    padding: 25px 20px;
    font-size: 14px;
    font-family: "Bahij-semibold";
    border-bottom: 1px solid #f4f7fe;

    b {
      font-weight: normal;
      font-family: "Bahij-semibold";
      font-size: 14px;
    }

    strong {
      font-weight: normal;
      font-family: "Bahij-semibold";
      font-size: 14px;
      color: #fc6b14;
    }
  }

  .product {
    display: flex;
    align-items: center;
    column-gap: 30px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 2px;
      overflow: hidden;
    }

    a {
      color: #000;
      font-size: 14px;
      font-family: "Bahij-semibold";
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  margin: -25px -15px;
}

.orderBlocks .card.block.productsWrapper {
  .c-head {
    background: #fff;
    border-bottom: 1px solid #f5f8fe;
  }

  min-height: auto;
}

.noData {
  text-align: center;
  justify-content: center;
  display: flex;

  .alert {
    font-family: "Bahij-semibold";
  }
}

.productsWrapper ul.productsInfo {
  li {
    border-top: 1px solid #f4f7fe;
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: "Bahij-semibold";

    span {
      font-size: inherit;
      font-family: inherit;
    }

    &:last-child {
      color: #fc6b14;
    }
  }

  margin-top: 25px;
}

.historyInfo li {
  display: flex;
  align-items: center;
  column-gap: 17px;
  padding: 16px 0;
  border-bottom: 1px solid #f4f7fe;

  .h-img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    box-shadow: 0px 2.57436px 5.14873px rgba(58, 207, 249, 0.25);

    img {
      filter: brightness(0) invert(1);
    }

    &.paid {
      background-color: #17d8aa;
    }

    &.canceled {
      background-color: #e3503e;
    }

    &.delivered {
      background-color: #3acff9;
    }

    &.onDelivery {
      background-color: #fca11e;
    }
  }

  .h-info {
    h5 {
      font-family: "Bahij-semibold";
      font-size: 14px;
      margin-bottom: 9px;
    }

    p {
      font-size: 12px;
      font-family: "Bahij-light";
      color: #a3aed0;
      line-height: 1;
    }
  }

  &:last-child {
    border: 0;
  }
}

.productList .card {
  padding: 0;
  border: 0;
}

.sidebarToggled .productCard .p-img {
  height: 350px;
}

.productCard .p-data .form-group:last-child {
  margin-bottom: 0;
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  &[type="number"] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      opacity: 1;
    }
  }
}

.productCard .p-data .form-group .form-control option {
  font-size: inherit;
}

.orderLayout {
  display: flex;
  align-items: center;
  column-gap: 10px;

  button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 0;
    padding: 0;
    background: transparent;
    transition: 0.3s;

    &.active {
      background: #fc6b14;
      box-shadow: 0px 1px 4px rgba(52, 108, 244, 0.25);
      cursor: default;

      svg {
        fill: transparent;
        stroke: #fff;
      }
    }
  }
}

.listView {
  .productCard {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 30px;

    .p-img {
      height: 110px;
      background: transparent;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      column-gap: 10px;
      width: 120px;
      border-radius: 10px;

      .mySwiper2 .img-holder {
        padding: 0;
        background: #f6f6f6;
        width: 100%;
        height: 86px;
        border-radius: 10px;
        overflow: hidden;
      }

      .btn {
        font-size: 0;
        width: 25px;
        min-width: 25px;
        height: 25px;
        padding: 5px;
        column-gap: 0;
        left: 10px;
        bottom: 10px;
      }

      input[type="checkbox"] {
        width: 14px;
        height: 14px;
        border-radius: 4px;
        position: relative;
        right: auto;
        top: auto;
        background: #f8f8f8;
        min-width: 14px;
      }
    }

    .p-data {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      width: calc(100% - 120px);
      padding: 0;

      .form-group {
        width: calc(20% - 10px);
        order: 1;
        margin: 0;

        &:nth-of-type(1) {
          order: 1;
        }

        &:nth-of-type(2) {
          order: -1;
          width: calc(80% - 10px);
        }

        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          order: 1;
        }

        &:nth-of-type(6) {
          order: 0;
        }
      }
    }

    .p-img input[type="checkbox"]::before {
      width: 100%;
      height: 100%;
      line-height: 14px;
      font-size: 10px;
    }
  }

  .col-12 {
    width: 100%;
  }
}

.productDataModal {
  &.modal-xl {
    min-width: 90vw;
  }

  .modal-content {
    border-radius: 18px;
    background: #f9f9f9;
  }

  .modal-header {
    background: #f5f7ff;
    border-radius: 18px 18px 0 0;
    border-bottom: 1px solid #dfdfdf;
    padding: 20px;
    justify-content: space-between;
  }
}

[data-theme="dark"] .productDataModal .modal-header {
  border-bottom: 0;
}

.productDataModal {
  .modal-header {
    .btn-close {
      margin: 0;
      background-size: 11px;
      padding: 0;
    }

    .modal-title {
      font-size: 14px;
      font-family: "Bahij-semibold";
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }

  .modal-footer {
    background: #f5f7ff;
    border-radius: 0px 0px 18px 18px;
    border-top: 1px solid #dfdfdf;
    padding: 20px;
    column-gap: 15px;

    .btn {
      height: 40px;
      min-width: 120px;
      font-size: 12px;
      margin: 0;
      padding: 0;
      font-family: "Bahij-semibold";

      &.btn-outline {
        background-color: #233654;
        color: #fff;
      }

      &.btn.btn-secondary {
        background-color: transparent;
        order: -1;
        color: black;
        border-color: transparent;

        [data-theme="dark"] & {
          color: #fff;
        }
      }
    }
  }

  .form-group {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 30px;
    margin-bottom: 30px;

    .form-head {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 30px;
      justify-content: space-between;

      > div {
        flex-grow: 1;
      }

      i {
        background: rgba(250, 104, 23, 0.08);
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;

        svg {
          position: unset;
          top: auto;
          transform: none;
        }
      }

      h5 {
        font-size: 14px;
        font-family: "Bahij-semibold";
        margin-bottom: 5px;
      }

      span {
        color: #b3b3b3;
        font-size: 12px;
        font-family: "Bahij-light";
      }

      .head-right {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-grow: unset;
      }

      .head-left {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-grow: unset;

        .link {
          display: inline-flex;
          align-items: center;
          gap: 10px;
          padding: 0;
          border: 0;
          background-color: transparent;

          i {
            background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
          }

          svg {
            position: unset;
            transform: none;
          }
        }
      }
    }

    .form-body {
      label {
        font-size: 12px;
        font-family: "Bahij-semibold";
        margin-bottom: 11px;
      }

      .col-12 > {
        div,
        .selectHolder > div {
          display: flex;
          align-items: center;
          position: relative;
        }
      }

      .form-control {
        border: 1px solid #e4e4e4;
        border-radius: 8px;
        background: #fff;
        font-size: 12px;
        width: 100%;
        padding: 0 50px;
      }

      .col-12 {
        > div::after,
        .MultiLangsWrapper .fields-wrapper::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 12px;
          background: #e4e4e4;
          right: 42px;
          top: 50%;
          translate: 0 -50%;
          opacity: 0.5;
        }

        > {
          div svg,
          .selectHolder > div svg {
            right: 15px;
            transition: 0.3s;
            transform: none;
            top: 50%;
            transform: translate(0, -50%);
            // translate: none;
          }
        }
      }

      .form-control {
        &:focus {
          border-color: #fc6b14 !important;

          + svg {
            fill: #fc6b14;
          }
        }

        option {
          font-size: inherit;
        }
      }

      .extraSelect {
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 1;
        width: 100px;
        background: rgba(250, 104, 23, 0.08);
        color: #fc6b14;
        font-family: "Bahij-semibold";
        height: 36px;
        padding: 0 10px;
        text-align: center;
        cursor: pointer;
      }

      .row {
        row-gap: 20px;
      }
    }

    .form-hint {
      font-size: 12px;
      font-family: "Bahij-light";
      margin-top: 10px;
      color: #aaa1a1;
    }

    .form-body label.has-state {
      display: inline-flex;
      align-items: center;
      column-gap: 14px;
      user-select: none;
      cursor: pointer;
      margin-bottom: 0;

      input[type="checkbox"] {
        background: #f8f8f8;
        width: 20px;
        height: 20px;
      }
    }
  }
}

input[type="checkbox"]:not(.form-check-input):checked::before {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  line-height: normal;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productDataModal .form-group .form-body {
  .controllerHolder {
    &::after,
    div::after {
      display: none;
    }
  }

  .selectHolder {
    &::after,
    div::after {
      display: none;
    }
  }

  .controllerHolder div {
    box-shadow: none;
    align-items: unset;

    svg {
      right: auto;
    }
  }
}

.tox-tinymce {
  border-radius: 15px !important;
  border-color: #e4e4e4 !important;
}

.tox .tox-toolbar__group {
  border: 0 !important;
}

#tinymce {
  overflow-x: hidden !important;
}

.tox {
  .tox-dialog {
    border-color: #e4e4e4 !important;
    border-radius: 15px !important;
  }

  .tox-dialog__title {
    font-family: "Bahij-semibold" !important;
    font-size: 15px !important;
  }

  .tox-dialog__header .tox-button {
    padding: 0 !important;
    border: 0 !important;
    background: transparent !important;
  }

  .tox-button {
    background: linear-gradient(
      90deg,
      #e74c3c -0.02%,
      #fc6b14 99.99%
    ) !important;
  }
}

.tox-button.tox-button--secondary {
  background: transparent !important;
  border: 1px solid #fc5296 !important;
  color: #fc5296 !important;
}

.tox {
  .tox-form__group {
    margin-bottom: 15px !important;

    label {
      font-family: "Bahij-regular" !important;
      font-size: 12px !important;
      margin-bottom: 5px !important;
      font-weight: normal !important;
      color: #000 !important;
    }

    .tox-textfield {
      border-radius: 50px !important;
      padding: 0 20px !important;
      height: 40px !important;
      border-color: #e4e4e4 !important;
      font-size: 13px !important;
      font-family: "Bahij-regular" !important;
    }
  }

  .tox-listboxfield .tox-listbox--select {
    border-radius: 50px !important;
    padding: 0 20px !important;
    height: 40px !important;
    border-color: #e4e4e4 !important;
    font-size: 13px !important;
    font-family: "Bahij-regular" !important;
  }

  .tox-collection__item-label {
    font-size: 12px !important;
    font-family: "Bahij-regular" !important;
  }

  .tox-collection--list .tox-collection__group {
    border-color: #e4e4e4 !important;
  }

  .tox-menu {
    border-color: #e4e4e4 !important;
    border-radius: 10px !important;
    margin-top: 3px !important;
  }

  .tox-button {
    border-radius: 50px !important;
    font-weight: normal !important;
    font-family: "Bahij-semibold" !important;
    font-size: 12px !important;
  }
}

.tox-dialog__footer-end .tox-button {
  min-width: 100px !important;
}

.tox .tox-dialog__footer {
  border-color: #e4e4e4 !important;
  padding: 16px !important;
}

.productDataModal .form-group .form-body {
  .controllerHolder {
    .tox-sidebar-wrap {
      display: flex;
      position: static;
      box-shadow: none;
    }

    .tox-toolbar {
      display: flex;
      flex: auto;
      background: #f5f7ff;
      border-radius: 15px 15px 0px 0px;
    }
  }

  .col-12 .selectHolder {
    display: block;

    > div {
      display: block;
    }

    .basic-single {
      display: block;

      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 12px;
        background: #e4e4e4;
        right: 42px;
        display: block;
        top: 17px;
        z-index: 1;
      }

      .select__control {
        border-radius: 8px;
        padding: 0 40px 0 5px;
      }

      .select__menu {
        display: block;

        * {
          display: block;
        }
      }
    }

    > div svg {
      transform: none;
      top: 22px;
    }
  }

  textarea.form-control {
    height: 103px;
    border-radius: 15px;
    padding: 15px 50px 10px;
  }

  .col-12 .seoPreview {
    display: block;
    text-align: right;

    h1 {
      font-size: 14px;
      font-family: "Bahij-semibold";
      color: #000;
      margin-bottom: 8px;
    }

    a {
      color: #fc6b14;
      font-size: 14px;
      font-family: "Bahij-semibold";
      direction: ltr;
    }

    p {
      margin-top: 8px;
      color: #3e3e3e;
      font-size: 12px;
      font-family: "Bahij-light";
    }
  }
}

.seoPreview p * {
  display: inline-block;
  margin: 0 !important;
}

.productDataModal .form-group .form-body .col-12 .seoPreview::after {
  display: none;
}

/* .productDataModal .form-group:last-child {
  padding: 0;
  border: 0;
} */

.activate .switcher {
  width: 60px;
}

.coupons-wrap .activate {
  margin: 0;
}

.productDataModal {
  .nav {
    flex-grow: 1;
    justify-content: flex-end;
    margin: 0 20px;

    .btn {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
      font-family: "Bahij-semibold";
      border: 0;
      padding: 0 24px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;

      &.active {
        color: #fff;
        background: #233654;
      }
    }
  }

  .form-group .form-body .col-12 .marketCoupon {
    div {
      flex-wrap: wrap;
    }

    &::after,
    div::after {
      display: none;
    }

    h4 {
      width: 100%;
      font-size: 16px;
      font-family: "Bahij-semibold";
      margin-bottom: 8px;
    }

    p {
      font-size: 12px;
      font-family: "Bahij-light";
    }

    margin-top: 30px;
    justify-content: space-between;

    .switcher {
      .switcher-input:checked ~ .layer {
        background-color: #49ca6e;
      }

      .layer,
      .knobs {
        position: absolute;
      }
    }
  }
}

#drag {
  /* position: absolute;
  left: -5px;
  top: 0;
  bottom: 0;
  width: 10px; */
  /* cursor: col-resize; */
  /* user-select: none; */
}

.productCard .p-data .form-group.has-btns {
  .dropdown-menu {
    display: none;
    transform: translate(0px, 48px) !important;

    &.show {
      display: block;

      a svg {
        position: unset;
        width: auto;
        height: auto;
        background: transparent;
        border-radius: 0;
        padding: 0;
        transform: none;
        top: auto;
        right: auto;
      }
    }
  }

  .dropdown::after {
    display: none;
  }
}

.couponCode {
  border: 0;
  background: transparent;
  padding: 0;
  line-height: normal;
  color: #fc6b14;
  font-family: "Bahij-semibold";
}

.productDataModal .form-group .form-body .col-12 .qty {
  position: absolute;
  left: 15px;

  span {
    font-family: "Bahij-light";
    font-size: 13px;
    color: #000;
  }

  &::after {
    right: -12px;
    top: 4px;
  }
}

.btn-delete,
.btn-edit {
  border: 0;
  background: linear-gradient(135deg, #ff2625 0%, #f77776 100%);
  border-radius: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0;
  width: 30px;
  justify-content: center;
  min-width: 30px;
}

.btn-delete svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 7px;
}

.btn-edit {
  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 7px;
  }

  background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
}

.coupons-wrap .activate .layer {
  background-color: #e3e3e3;
}

.select__menu {
  position: absolute !important;
  z-index: 2 !important;
}

.basic-single {
  .select__multi-value .select__multi-value__remove {
    svg {
      position: unset;
      right: auto;
      left: auto;
      top: auto;
      transform: none;
    }

    padding: 0;
  }

  svg {
    position: unset !important;
    top: auto !important;
    translate: unset !important;
    transform: unset !important;
  }
}

.historyInfo li .h-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  span {
    color: #a3aed0;
    font-size: 12px;
  }
}

.productDataModal .form-group .form-body .multiUploadWrapper {
  .form-group {
    display: block;
    margin: 0;

    &::after,
    div::after {
      display: none;
    }

    label {
      margin: 0;
    }

    div {
      flex-wrap: wrap;
    }

    svg {
      right: auto;
      left: 20px;
      top: auto;
      transform: none;
      z-index: 2;
    }

    .thumb svg {
      position: unset;
    }
  }

  position: relative;
  padding: 0;
}

.modal-backdrop.show {
  opacity: 0.8;
  z-index: 100 !important;
}

.productDataModal .form-group .form-body .multiUploadWrapper {
  .form-uploader span {
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    flex-direction: column;
    right: 0;
    width: 100%;

    svg {
      position: unset;
      display: block;
      width: 100%;
      left: auto;
      margin-top: 15px;
    }
  }

  .form-group {
    div {
      &.thumb {
        width: 100%;
        height: 250px;
        border: 0;
        border-radius: 0;
        display: block;

        label {
          display: flex;
          align-items: center;
          column-gap: 10px;
          background: #f5f7ff;
          width: 100%;
          border-radius: 0 0 10px 10px;
          padding: 10px;
          cursor: pointer;

          &:after {
            content: "";
          }

          span {
            font-size: 12px;
          }

          input[type="radio"] {
            width: 18px;
            height: 18px;
          }
        }

        img {
          border-radius: 10px 10px 0 0;
        }

        label input[type="radio"]::before {
          width: 100%;
          height: 100%;
          line-height: 20px;
          font-size: 15px;
        }
      }

      &.thumbs {
        width: 100%;
        column-gap: 0;
        row-gap: 0;
        display: block;
      }
    }

    .row {
      row-gap: 65px;
    }
  }
}

.swiper-thumbs {
  position: absolute;
  left: 15px;
  bottom: 15px;
  width: calc(100% - 30px);

  .img-holder {
    border-radius: 5px;
    overflow: hidden;
    border: 2px solid transparent;
    transition: 0.3s;
    min-height: 50px;
    max-height: 60px;
    display: flex;
    align-items: center;
    background: #dbdbdb;

    img {
      object-fit: cover;
    }
  }

  .swiper-slide-thumb-active .img-holder {
    border-color: #fc6b14;
  }
}

.listView .productCard .p-img .swiper-thumbs {
  display: none;
}

.stores-selection .dropdown span img + img {
  background: #ffffff;
  object-fit: cover;
}

.stickyLoader {
  position: fixed;
  left: 30px;
  bottom: 30px;
  z-index: 9;
}

.modal-dialog .stickyLoader {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute !important;
  width: 100%;
  height: 100%;
  justify-content: center;
  background: rgb(187 187 187 / 51%);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    // left    : auto;
    // position: unset;
    display: block;
  }
}

.productDataModal .form-group .form-body {
  .multiUploadWrapper .form-group {
    div.thumbs,
    > div {
      position: unset;
    }

    position: unset;
  }

  position: unset;
}

.delete {
  color: #f00;
}

.swal2-icon.swal2-warning {
  border-color: #e3503e;
  color: #e3503e;
  margin: 20px auto 0;

  .swal2-icon-content {
    color: inherit;
  }
}

.swal2-title {
  font-size: 18px;
  font-weight: normal;
  font-family: "Bahij-bold";
}

.swal2-actions {
  button {
    height: 40px;
    font-size: 14px !important;
    min-width: 100px;
    border-radius: 50px !important;
    margin: 0 !important;
    box-shadow: none !important;
    outline: none;
  }

  column-gap: 15px;
}

.swal2-popup {
  border-radius: 18px;
}

.emptyPhotos {
  min-height: 200px;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  row-gap: 15px;
  color: #a3a3a3;
  font-family: "Bahij-light";
  font-size: 14px;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    position: unset !important;
    width: 70px;
    height: 70px;
  }
}

.productCard .p-img .mySwiper2 {
  height: 100%;

  .img-holder {
    height: 100%;
    padding: 14px;
  }
}

.productsSelection {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  column-gap: 10px;

  .btn {
    color: #333 !important;
    height: auto;
    border-radius: 0;
    font-size: 13px;
    line-height: normal;
    display: flex;
    align-items: center;
    column-gap: 5px;

    i {
      width: 20px;
      height: 20px;
      background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
      border-radius: 100%;
      color: #fff;
      font-size: 12px;
    }
  }

  .dropdown {
    min-width: 170px;

    .dropdown-menu {
      .dropdown-item svg {
        background: transparent;
        width: auto;
        height: auto;
        border-radius: 0;
        padding: 0;
      }

      transform: none !important;
      border-radius: 5px;
      padding: 7px 0;
      left: 0 !important;
      right: auto !important;
      top: 100% !important;
      width: 100%;
    }
  }

  input[type="checkbox"] {
    width: 16px;
    height: 16px;

    &::before {
      font-size: 10px;
    }
  }
}

.productCard .p-data .form-group {
  div .qty label {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 0;
    background: rgba(250, 104, 23, 0.08);
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    transition: 0.3s;

    &.active {
      background: #fc6b14;
    }

    svg {
      position: unset;
      right: auto;
      top: auto;
      transform: none;
      translate: none;
    }

    input[type="checkbox"] {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .unlimited-qty {
    background: #fff;
    position: absolute;
    right: 45px;
    font-size: 12px;
    padding: 5px;
    width: calc(100% - 90px);
    transition: 0.3s;
    cursor: default;
  }
}

.productCard.brandCard {
  position: relative;

  .deleteNewProduct {
    position: absolute;
    left: -10px;
    top: -10px;
    right: auto;
  }
}

.deleteNewProduct {
  width: 30px;
  height: 30px;
  background-color: #f00;
  position: absolute;
  left: -10px;
  top: -10px;
  border-radius: 100%;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.listView .brandCard {
  .newProduct.p-img {
    background: #f1f1f1;
  }

  .deleteNewProduct {
    left: -10px;
    width: 25px;
    height: 25px;
  }
}

.productCard {
  .p-img .img-holder {
    height: 100%;
  }

  .p-data .form-group .form-control.disabled {
    background: #ededed;
    cursor: not-allowed;
    user-select: none;
  }
}

.brandDataModal {
  .form-group {
    .form-body .multiUploadWrapper .form-group div {
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: center;
      display: flex;

      .emptyPhotos {
        row-gap: 0;
        min-height: auto;
        display: block;

        svg {
          display: block;
          margin: 0 auto 15px;
        }
      }

      label.form-uploader,
      .emptyPhotos,
      .thumbs {
        width: 48%;
        margin: 0;
      }

      label.form-uploader {
        min-height: 250px;
      }

      &.thumb img {
        border-radius: 10px;
      }
    }

    margin: 0;
  }

  .modal-footer .btn {
    min-width: 120px;
  }

  .form-group .image-uploader {
    margin: 0 auto;
  }

  .modal-footer {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.productCard .p-data .form-group.has-btns.disabled {
  position: relative;
  overflow: hidden;
  cursor: not-allowed;
  user-select: none;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  button {
    color: #000;
    opacity: 0.5;

    svg {
      filter: brightness(0);
    }
  }
}

// img.newProduct {
// padding: 100px;
// filter: brightness(0);
// opacity: 0.15;
// }

.multiUploadWrapper img {
  width: 100%;
  height: 100%;
}

.productCard .p-data .form-group.has-btns button[disabled] {
  cursor: not-allowed;
  filter: brightness(0);
  opacity: 0.4;
  user-select: none;
}

.productsList {
  .save-arrangement {
    border-radius: 8px;
  }

  & > .row > .col-12 {
    // &:hover,
    &:has(.dropdown.show) // ,

    // .dropdown:hover
    {
      position: relative;
      z-index: 1;
    }
  }

  .table {
    margin: 0;
    border: 1px solid #dfdfdf;

    th {
      background: #f7f7f7;
      font-weight: normal;
      font-family: "Bahij-semibold";
      font-size: 14px;
      padding: 10px;
      border: 0;
      text-align: center;
    }

    td {
      border: 1px solid #dfdfdf;
      text-align: center;
      padding: 15px;
      align-items: center;
    }

    .listItem {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 15px;

      .listItemImg {
        border-radius: 10px;
        overflow: hidden;
      }

      .listItemInfo a {
        color: #000;
        font-family: "Bahij-semibold";
      }
    }

    tbody {
      border: 0;
    }
  }

  width: 100%;
}

.brands-wrap .listView .productCard .p-data .form-group {
  width: 49% !important;
  order: 1 !important;

  &:last-of-type {
    width: 100% !important;
  }
}

.edit-theme {
  margin: -22px;

  .builder-header {
    display: none;
  }
}

.tox-pop__dialog {
  display: none;
}

.btn:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.select__option {
  &.select__option--is-focused {
    background: rgba(250, 104, 23, 0.15);
  }

  &:active {
    background: rgba(250, 104, 23, 1) !important;
    color: #fff;
  }
}

.reports .card-head h4 a {
  color: #fc6b14;
  font-size: 14px;
  font-family: "Bahij-bold";
  border-bottom: 1px solid;
  vertical-align: middle;
  margin: 0 5px;
}

.seo-form {
  margin: 20px 0;

  h4 {
    font-size: 17px;
    font-family: "Bahij-semibold";
    margin-bottom: 20px;
  }
}

[data-theme="dark"] {
  .seo-form h4,
  .dark-white {
    color: white;
  }
}

.productCard .p-data .seo-form {
  background: #f7f7f7;
  border-radius: 20px;
  padding: 15px;

  h4 {
    font-size: 13px;
    margin-bottom: 0;
  }

  .form-group {
    h5 {
      display: none;
    }

    margin-top: 15px;

    .form-control {
      padding: 0 20px;
    }
  }
}

.brandSettingModal {
  .image-uploader {
    margin: 0;
    width: 100%;
    user-select: none;

    label {
      margin: 0 !important;
    }
  }

  .form-group {
    border: 0;
    padding: 0;
  }

  .modal-body {
    background: #fff;
    border-radius: inherit;
  }
}

[data-theme="dark"] .brandSettingModal .modal-body {
  background: transparent;
}

.pagination {
  gap: 15px;
  padding-block: 20px 50px;
  justify-content: center;

  .page-item {
    .page-link {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: #fff;
      box-shadow: 0px 4px 30px 0px rgba(178, 178, 178, 0.25);
      border: 0;
      color: #000;
      font-family: "Bahij-semibold";
      transition: 0.3s;
    }

    &.active .page-link {
      background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
      color: #fff;
    }

    &.disabled .page-link {
      box-shadow: none;
      background: transparent;
      font-size: 17px;
      cursor: not-allowed;
    }

    &:first-child .page-link,
    &:last-child .page-link {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }

    &:first-child .page-link span,
    &:last-child .page-link span {
      background-image: url(../assets/svgs/arrow.svg);
      background-position: center;
      background-size: 20px;
      font-size: 0;
      filter: brightness(0);
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      cursor: inherit;
    }
  }
}

.categoriesModal {
  .card {
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    border: 0;
    margin: 0;

    .form-group {
      .form-control {
        background-color: #fff;
      }

      .layer {
        background-color: #dfdfdf;
      }
    }
  }

  .nav {
    justify-content: flex-start;
    margin: 0 0 20px;
    gap: 30px;

    .nav-item {
      width: auto;

      .nav-link {
        color: rgb(44 62 80 / 30%);
        font-family: "Bahij-semibold";
        padding: 0;
        background: transparent;

        &.active {
          color: #2c3e50;
        }
      }
    }
  }

  .card .form-group .image-url {
    text-align: right;
    direction: ltr;
  }
}

.form-select {
  background-position: left 0.75rem center;
}

.categoriesModal .card .form-group .form-select option {
  font-size: 14px;
}

.productsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;

  .productsSelection {
    margin: 0;
  }
}

.productsFilterParams {
  .filterParam {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    color: #fff;
    font-size: 12px;
    padding: 8px 15px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      padding: 0;
      border: 0;
      background: transparent;
      filter: brightness(0) invert(1);
    }
  }

  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}

.brands-wrap {
  .ordersServices .btn {
    background: #fff;
    color: #000 !important;
  }

  .card {
    padding: 0;
    border: 0;
    box-shadow: 0 3px 6px rgb(0 0 0 / 7%);
  }
}

.brandsGlobalSettingsModal .form-group {
  &.flex {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &:last-child,
  h5 {
    margin-bottom: 0;
  }
}

.main-sidebar .stores-selection {
  padding: 25px 15px 20px;
  border: 0;
  border-bottom: 1px solid rgb(255 255 255 / 13%);
}

.stores-selection .dropdown > div {
  overflow: hidden;
}

.burgerBtn .btn {
  height: 35px;
  line-height: 35px;
  font-size: 13px;
  padding: 0 20px;
  border-radius: 8px;
}

.authWrap {
  .au-img {
    height: 35px;
    width: 35px;
    background-color: #f4f7fe;
  }

  .au-data {
    p {
      line-height: 1;
    }

    h4 {
      font-size: 13px;
      margin-bottom: 1px;
    }
  }

  .dropdown-toggle svg {
    margin-top: 5px;
  }
}

.stores-selection .dropdown-toggle {
  span {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }

  column-gap: 10px;
  min-width: 205px;
  color: #fff;
  padding: 0;
  border: 0;
}

.main-sidebar {
  .logo {
    padding: 22px;
    border-bottom-color: rgb(255 255 255 / 13%);
  }

  width: 270px;
  background: #233654;
}

.stores-selection {
  .show .dropdown-toggle {
    background: transparent !important;
    border-color: transparent !important;
  }

  .dropdown-menu {
    background: #233654;
  }
}

.drop-scrollable {
  max-height: 185px;
}

.stores-selection .dropdown-menu {
  a {
    color: #a5a5a5;
    font-size: 14px;
  }

  .active-store {
    color: #fc6b14;
  }
}

.logo .logo-info {
  filter: brightness(0) invert(1);
}

.main-sidebar .side-nav {
  padding: 20px 15px 40px 10px;
  height: calc(100% - 160px);

  ul li a {
    font-size: 14px;
  }
}

.side-actions a {
  font-size: 14px;
}

.main-sidebar .side-nav ul {
  li a.active ~ .children {
    background-color: #1f3646;
  }

  &::-webkit-scrollbar-track {
    background-color: #2a506b;
  }
}

/*** toggeled ***/

.sidebarToggled {
  .main-sidebar {
    .stores-selection .dropdown-toggle {
      font-size: 0;
      justify-content: center;
      min-width: auto;
      text-align: center;
      column-gap: 0;
      width: 0;

      &::before {
        transform: scale(0);
      }
    }

    .logo {
      padding: 10px;
      justify-content: center;

      .inner {
        justify-content: center;

        a {
          justify-content: center;
        }
      }
    }

    width: 90px;

    .stores-selection {
      padding: 25px 10px 15px;
      text-align: center;

      .dropdown {
        display: flex;
        justify-content: center;
        column-gap: 0;
      }
    }

    .side-nav ul li a {
      padding: 9px 0;
      border-radius: 0;
    }
  }

  .side-actions a {
    padding: 9px 0;
    border-radius: 0;
  }

  .main-sidebar .side-nav {
    padding: 0;

    > ul {
      padding: 0;
      overflow: visible;
    }
  }

  .content-wrap {
    margin-right: 90px;
    padding: 89px 22px 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

body > iframe[style*="2147483647"] {
  display: none;
}

.checkbox-show-next {
  + div {
    display: none !important;
  }

  &:checked + div {
    display: unset !important;
  }
}

.tooltip.show {
  opacity: 1;
}

[data-color-mode*="light"] {
  --color-canvas-subtle: #fff;

  body {
    --color-canvas-subtle: #fff;
  }
}

.productsList .card {
  padding: 0;
  border: 0;
}

.productDataModal .form-group .form-head .head-left .link svg {
  position: unset;
  transform: none;
}

.Toastify__toast-icon svg,
.Toastify__close-button svg {
  fill: #000;
}

.Toastify__toast-body div {
  color: #000;
  font-size: 14px;
  direction: initial;
  white-space: pre-wrap;
}

.Toastify__close-button {
  opacity: 1;
}

.filter-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 15px;
  padding: 20px;
  background: #fff;
  margin-bottom: 25px;
  border-radius: 18px;
}

[data-theme="dark"] .filter-toolbar {
  background: #7590bb;
}

.filter-toolbar__right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.filter-toolbar__selection label {
  display: flex;
  align-items: center;
  gap: 15px;

  input[type="checkbox"] {
    border: 2px solid #d1d1d1;
    border-radius: 5px;
    width: 20px;
    height: 20px;
  }

  span {
    font-family: "Bahij-bold";
  }
}

.filter-toolbar__layout {
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 100%;
    border: 0;
    background: transparent;
    transition: 0.3s;

    &.active {
      background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);

      svg {
        transition: 0.3s;
        stroke: #fff;
      }
    }
  }
}

.filter-toolbar__left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;

  .react-select__control {
    position: relative;
    border-radius: 8px;
    padding-inline-start: 15px;
    width: 150px;

    span {
      position: absolute;
      top: calc(50% - 3.5px);
      right: 9px;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      display: inline-block;
      margin: 0;

      &.paid {
        background-color: #05c3fb;
      }

      &.onDelivery {
        background-color: #fc6b14;
      }

      &.delivered {
        background-color: #01e50a;
      }

      &.canceled {
        background-color: #ff0000;
      }
    }

    svg {
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top-color: #aeaeae;
      translate: 0 35%;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .form-control,
  .btn {
    height: 40px;
  }

  .form-control {
    width: auto;
  }
}

.filter-toolbar__action .btn {
  height: 40px;
  background: #233654;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  padding: 0;
  min-width: 90px;
  justify-content: center;

  &:active {
    background: linear-gradient(
      90deg,
      #e74c3c -0.02%,
      #fc6b14 99.99%
    ) !important;
    border-color: transparent;
    color: #fff;
  }
}

.filter-toolbar__settings {
  .btn {
    &:active {
      background: linear-gradient(
        90deg,
        #e74c3c -0.02%,
        #fc6b14 99.99%
      ) !important;
      border-color: transparent;
      color: #fff;
    }

    background: #f9f9f9 !important;
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000;

    &::after {
      display: block;
      border-top-color: #aeaeae;
    }
  }

  .dropdown-menu {
    border-radius: 10px;
    background: #f6f6f6;
    min-width: 230px;
    box-shadow: none;
    border: 0;

    .dropdown-item {
      padding: 10px 20px;
      color: rgba(35, 54, 84, 0.6);
      font-size: 14px;

      &:hover {
        background: rgb(44 62 80 / 8%);
        color: #2c3e50;
      }

      svg {
        width: auto;
        height: auto;
        background: transparent;
        border-radius: 0;
        padding: 0;
      }
    }
  }
}

.filter-toolbar__search {
  position: relative;

  .form-control {
    border-radius: 10px;
    background: #f9f9f9;
    height: 40px;
  }

  button {
    position: absolute;
    border-radius: 6px;
    border: 0;
    width: 30px;
    height: 30px;
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 5px;
    top: 5px;
  }
}

.productCard .p-img input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 2px solid #d1d1d1;
}

.basic-single .select__option {
  color: #000;

  &.select__option--is-selected {
    color: #fff;
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
  }
}

.filter-params {
  width: 100%;
  border-top: 1px solid #f8f8f8;
  margin-top: 20px;
  padding-block: 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

.filter-toolbar {
  flex-wrap: wrap;
}

.filter-params {
  .param-item {
    display: flex;
    align-items: center;
    gap: 10px;
    background: rgba(35, 54, 84, 0.1);
    border-radius: 50px;
    padding: 0 15px;
    font-size: 14px;
    font-family: "Bahij-semibold";
    color: #2c3e50;
    height: 35px;
    line-height: 35px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-break: anywhere;
    max-width: 200px;

    button {
      padding: 0;
      line-height: 1;
      border: 0;
      background: transparent;
    }
  }

  .btn {
    height: 35px;
    border: 1px solid rgba(35, 54, 84, 0.5);
    background: transparent;
    color: #2c3e50;
    padding: 0 15px;
    display: flex;
    align-items: center;
    font-family: "Bahij-semibold";
    white-space: nowrap;
  }
}

.swal2-popup {
  .swal2-title {
    font-size: 18px;
    color: #000;
    font-weight: normal;
    font-weight: 600;
  }

  button.swal2-styled {
    margin-block: 0;
    margin-inline: 0 15px;
    min-width: 100px;
    height: 45px;
    font-size: 14px;
    border-radius: 7px;
    box-shadow: none !important;

    &.swal2-confirm {
      background-color: var(--tj-icon-color);
    }
  }
}

.swal2-container {
  &.swal2-backdrop-show,
  &.swal2-noanimation {
    background: rgb(0 0 0 / 91%) !important;
  }
}

body.swal2-height-auto {
  height: 100% !important;
}

.swal2-icon {
  margin: 30px auto 20px;
  border: 2px solid;
}

.setting-item {
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;

  h5 {
    font-size: 13px;
    font-family: "Bahij-semibold";
  }

  .image-uploader {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    margin: 0;

    div {
      border-radius: 5px;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .removeImg {
      width: 15px;
      height: 15px;
      background: #dadfe9;
      border: 0;

      svg {
        fill: #000;
        width: 8px;
        height: 8px;
      }
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: 0;
  }
}

.tjaara-toast * {
  font-size: 13px;
}

/* ************* DARK ************ */

[data-theme="dark"] {
  .swal2-popup {
    background-color: #2c2c2c;
    color: #fff;

    .swal2-title {
      color: #fff;
    }
  }

  .main-head {
    background: #233654;
    transition: all 0.3s;
    border-bottom-color: #1b293f;
  }

  .form-control {
    background-color: #1b293f !important;
    border-color: #4d4d4d;
    color: #fff;
  }

  .searchWrap .form-group button {
    background-color: #1b293f;
  }

  .timeWrap span,
  .noificationWrap .menu-item-has-children > span {
    background-color: #3c4045;
    color: #fff;
  }

  .main-sidebar .logo,
  .customers-list .c-item {
    border-color: #1b293f;
  }

  .content-wrap {
    background-color: #1b293f;
  }

  .card {
    background-color: #233654;
    border-color: #374965;
  }

  .card-blue-bg,
  .card-green-bg,
  .card-red-bg {
    border-color: transparent;
  }

  .main-sidebar .side-nav ul::-webkit-scrollbar-track,
  .employees-list::-webkit-scrollbar-track,
  .noificationWrap .dropdown ul::-webkit-scrollbar-track {
    background-color: #1b293f;
  }

  .rdt_Table,
  .rdt_TableHeadRow {
    background-color: #1b293f !important;
  }

  .rdt_TableRow {
    background-color: #1b293f !important;

    &:nth-of-type(odd) {
      background-color: #1a273b !important;
    }

    // &:only-child {
    //   margin-bottom: 90px;
    // }

    border-bottom: 2px solid #1b293f !important;
  }

  .rdt_TableHeadRow {
    border-bottom: 2px solid #1b293f !important;
  }

  .table-wrap .card-body {
    border-color: #1b293f;
  }

  input {
    &[type="checkbox"],
    &[type="radio"] {
      background-color: #394b65;
      border-color: #1b293f;
    }
  }

  .rdt_TableRow:nth-of-type(odd),
  .rdt_Table > div {
    background-color: #1b293f;
    color: #fff;
  }

  .dropdown-menu,
  .main-head .stores-selection .dropdown-menu {
    background-color: #1b293f;
    border-color: #1b293f;
  }

  .dropdown-item {
    &:focus,
    &:hover {
      background-color: #394b65;
    }
  }

  .noificationWrap .menu-item-has-children {
    .dropdown-toggle span,
    > span {
      background-color: #394b65;
    }
  }

  .topCards .card i {
    background-color: #394b65;
  }

  .rdt_Table > div > div,
  .rdt_TableCell div {
    color: #fff !important;
    fill: #fff;
  }

  .card-head {
    h4,
    p {
      color: #fff !important;
      fill: #fff;
    }
  }

  .rdt_TableCol *,
  .control-wrp .card h5,
  .dropdown-menu a,
  .authWrap .au-data h4,
  .topCards .card h4,
  .reports-blocks .card h4,
  .recharts-legend-item-text,
  .customers-list .c-item .i-data h4,
  .employees-list .employee-item .i-info h4 {
    color: #fff !important;
    fill: #fff;
  }

  .form-group {
    h5,
    strong {
      color: #fff !important;
      fill: #fff;
    }
  }

  .modal-header .btn-close,
  .customer-item .i-data h4,
  .itemGray .r-data h4 {
    color: #fff !important;
    fill: #fff;
  }

  .employee-itm .i-data {
    h4,
    p {
      color: #fff !important;
      fill: #fff;
    }
  }

  .flatpickr-months {
    .flatpickr-prev-month,
    .flatpickr-next-month,
    .flatpickr-month {
      color: #fff !important;
      fill: #fff;
    }
  }

  .flatpickr-current-month .numInputWrapper,
  span.flatpickr-weekday,
  .flatpickr-day {
    color: #fff !important;
    fill: #fff;
  }

  .modal-wrp {
    h4,
    p {
      color: #fff !important;
      fill: #fff;
    }
  }

  .form-group .show-pass svg,
  .authWrap li svg path,
  .noificationWrap .i-data p {
    color: #fff !important;
    fill: #fff;
  }

  .rdt_Pagination {
    span,
    select {
      color: #fff !important;
      fill: #fff;
    }
  }

  .rdt_TableCell {
    span,
    li {
      color: #fff !important;
      fill: #fff;
    }
  }

  .branch-block h5,
  .recharts-default-tooltip * {
    color: #fff !important;
    fill: #fff;
  }

  .flatpickr-calendar {
    background-color: #1b293f !important;
    border: 1px solid #1b293f !important;
    box-shadow: 0 0 25px rgb(0 0 0 / 32%);
  }

  .flatpickr-day {
    &.inRange,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.today.inRange,
    &.prevMonthDay.today.inRange,
    &.nextMonthDay.today.inRange,
    &:hover,
    &.prevMonthDay:hover,
    &.nextMonthDay:hover,
    &:focus,
    &.prevMonthDay:focus,
    &.nextMonthDay:focus {
      background-color: #3c4045;
      border-color: #3c4045;
    }
  }

  .flatpickr-current-month .numInputWrapper span {
    &.arrowDown:after {
      border-top-color: #fff;
    }

    &.arrowUp:after {
      border-bottom-color: #fff;
    }
  }

  .flatpickr-day.nextMonthDay {
    color: #686868;
  }

  .flatpickr-calendar.arrowBottom {
    &:after,
    &:before {
      border-top-color: #1b293f;
    }
  }

  .modal-content {
    background-color: #1b293f;
    border: 1px solid #1b293f;
  }

  .btn.btn-outline {
    border-color: #1b293f;
    color: #fff;
  }

  .removeImg {
    box-shadow: 0 0 0 3px #1b293f;
  }

  .card-head i,
  .customer-item,
  .layer,
  .card-inner .checker input[type="checkbox"],
  .itemGray,
  .noificationWrap li a:hover {
    background-color: #3c4045;
  }

  .card-head i svg,
  .main-sidebar .logo .logo-info,
  .noificationWrap .menu-item-has-children .dropdown-toggle span svg,
  .filter-wrp .form-group svg,
  .modal-header .btn-close,
  .searchWrap .form-group button svg,
  .noificationWrap .menu-item-has-children > span svg,
  .burgerBtn .toggleSidebar svg,
  .logo .logo-info {
    filter: brightness(0) invert(1);
  }

  .customer-item {
    &:hover,
    .i-img {
      background-color: #1b293f;
    }
  }

  .table-wrap .card-body header {
    background-color: #1b293f;
  }

  .switcher {
    .knobs:before {
      background-color: #626262;
    }

    .switcher-input:checked + .knobs:before {
      background-color: #ffffff;
    }
  }

  .btn.btn-gray {
    background-color: #3c4045;
    color: #a3aed0;
  }

  .main-head .logo {
    background-color: #1b293f;
  }

  .employee-itm {
    border-color: #1b293f;
  }

  .customer-itm[status] .emp-data:before {
    box-shadow: 0 0 0 2px #1b293f;
  }

  .noificationWrap li {
    border-color: #3c4045;
  }

  .recharts-default-tooltip {
    background-color: #3c4045 !important;
    border-color: #1b293f !important;
  }

  .recharts-rectangle.recharts-tooltip-cursor {
    fill: #3c4045;
  }

  .recharts-cartesian-grid-horizontal {
    fill: #fff !important;
  }

  line,
  .recharts-sector {
    stroke: #a3aed0;
  }

  .main-sidebar .side-nav ul li a.active ~ .children {
    background-color: transparent !important;
  }

  .table-select select {
    background-color: transparent !important;

    option {
      background-color: #0b1e2b !important;
      color: #fff;
    }
  }

  .form-group.branches {
    border-color: #4d4d4d;
  }

  .add-multi-component {
    border-color: #4d4d4d;
    background-color: #3c4045;
  }

  .form-group.branches .form-control {
    background: #1b293f;
  }

  .control-wrp .card h5 img {
    filter: brightness(0) invert(1);
  }

  .rdt_TableCell .branch-block {
    border-color: #3c4045;
  }

  .dropzone {
    background-color: #3c4045;
    border-color: #515458;
    color: #fff;

    p {
      color: inherit;
    }
  }

  .thumbs .thumb {
    border: 1px solid #4d4d4d;
  }

  .rdt_Pagination {
    background: #1b293f;

    svg {
      fill: #fff;
    }
  }

  .basic-single svg,
  .form-uploader svg path {
    fill: #fff;
  }

  .store-logo {
    background-color: #1b293f;
    border-color: #3c4045;
  }

  .basic-single {
    .select__control {
      border-color: #4d4d4d;
      background-color: transparent;
    }

    .select__menu {
      background-color: #3c4045;
    }

    .select__option {
      background-color: transparent;
      color: #fff;
    }

    .select__option--is-focused {
      background-color: #1b293f;
    }
  }

  .loader {
    background-color: #1b293f;
  }

  .image-contain label {
    border-color: #4d4d4d;

    > div {
      background-color: #1b293f;
    }
  }

  &.sidebarToggled .main-sidebar .side-nav ul li a {
    &.active ~ .children,
    ~ .children {
      background: #3c4045 !important;
    }
  }

  .overlay-s {
    background-color: rgb(27 29 32 / 90%);
  }
}

body[data-theme="dark"] {
  background: #1b293f;
}

[data-theme="dark"] {
  .lang-switcher .dropdown-toggle,
  .productsSelection .btn {
    color: #fff !important;
  }

  .lang-switcher .dropdown-toggle img {
    filter: invert(1);
  }

  .stores-selection .dropdown-toggle span {
    background-color: #1b293f;
  }

  .acc-form .nav .nav-link {
    background-color: #1b293f;
    color: #fff;

    &.active {
      background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
    }
  }

  .themes-item {
    background: #233654;
  }

  .themes-img {
    border-bottom-color: #1b293f;
  }

  .themes-item .themes-content h5 {
    color: #fff;
  }

  .acc-form .nav .nav-link.active {
    background: linear-gradient(90deg, #e74c3c -0.02%, #fc6b14 99.99%);
  }

  .stores-selection .dropdown-menu a img {
    background-color: #474a4e;
    filter: none;
  }

  .themes-wrap .themes-item {
    background: #1b293f;
  }

  .form-uploader {
    span {
      background: #1b293f;
      color: #fff;
    }

    border-color: #4d4d4d;
  }

  .thumbs .thumb .btn-remove {
    box-shadow: 0 0 0 3px #1b293f;
  }

  .authWrap .dropdown-menu .dropdown-item {
    &:last-child {
      border-color: #1b293f;
    }

    img {
      filter: brightness(0) invert(1);
    }

    &:last-child img {
      filter: none;
    }
  }

  .reports-blocks .card .card-head,
  .orders-list li,
  .products-list li {
    border-color: #1b293f;
  }

  .recharts-layer tspan {
    fill: #878787;
  }

  .order-top,
  .order-bottom,
  .order-top .orderslist > h4,
  .order-bottom li {
    border-color: #1b293f;
  }

  .order-top .order-table,
  .order-bottom h4 {
    border-color: #1b293f;
    background-color: #1b293f;
  }

  .order-top {
    .order-table li {
      color: #fff;
    }

    .orderslist {
      > h4 {
        color: #fff;
      }

      ul li .order-data {
        h4,
        p {
          color: #fff;
        }
      }
    }
  }

  .order-bottom {
    h4 {
      color: #fff;
    }

    li {
      color: #fff;

      span {
        color: #fff;
      }
    }
  }

  .notification-head span,
  .noificationWrap .i-data h5,
  .stores-selection .dropdown a,
  .head-wrp .stores-selection .dropdown a,
  .orders-list li .order-data h4,
  .breadcrumb ul li,
  .ordersServices .form-control option {
    color: #fff !important;
  }

  .reports-blocks {
    .card h5,
    .inner .i-text p {
      color: #fff;
    }
  }

  .stores-selection .dropdown-toggle,
  .select__single-value {
    color: #fff;
  }

  .order-top {
    .order-table li span,
    .orderslist ul li .order-data h4 span {
      color: inherit;
    }
  }

  .notification-head {
    border-color: #2d3e59;
  }

  .notification-footer {
    border-color: #2d3e59;

    a img {
      filter: brightness(0) invert(1);
    }
  }

  .searchToggle,
  .searchWrap .form-group {
    background: rgba(249, 249, 249, 0.1);
  }

  .searchToggle svg {
    filter: brightness(0) invert(1);
  }

  .ordersServices .form-control,
  .ordersActions .btn-black {
    background: #3c4045;
  }
}

.dar .ordersServices img {
  filter: brightness(0) invert(1);
}

[data-theme="dark"] {
  .breadcrumb ul li::after {
    filter: brightness(0) invert(1);
  }

  .stores-selection .dropdown-menu .drop-scrollable::before {
    filter: invert(1);
    opacity: 0.8;
  }

  .ordersServices .dropdown-toggle {
    background: #3c4045 !important;
    color: #fff !important;
  }

  .order-btns .ordersServices .dropdown-toggle {
    border-color: #1b293f;
  }

  .ordersServices .dropdown-toggle img {
    filter: brightness(0) invert(1);
  }

  .ordersFilterSidebar,
  .accordion-item:not(.delivery-companies),
  .accordion-header .accordion-button span {
    background: #203049;
  }

  .accordion-item.delivery-companies {
    background: transparent;
  }

  .sidebarHead,
  .accordion-body,
  .sidebarFooter,
  .accordion-body li .form-group .form-control,
  .order-top .orderslist ul li {
    border-color: #1b293f;
  }

  .accordion-item::before,
  .accordion-header::before,
  .order-top .orderslist ul li .order-img,
  .products-list li .product-img {
    background: #1b293f;
  }

  .accordion-header .accordion-button {
    &::after,
    &::before {
      background: #fff;
    }
  }

  .accordion-body li input {
    &[type="checkbox"],
    &[type="radio"] {
      border-color: #1b293f;
      background: #1b293f;
    }
  }

  .sidebarBody::-webkit-scrollbar-track {
    background-color: #2c2c2c;
  }

  .sidebarFooter .btn {
    border-color: #3c4045;

    &.btn-red {
      border-color: #ff2625;
    }
  }

  .no-store .card {
    p {
      color: #a3aed0;
    }

    .s-img img {
      filter: invert(1);
    }
  }

  .settingsHead div span img {
    filter: invert(1);
  }

  .sidebarHead img,
  input[type="datetime-local"]::after,
  .burgerBtn .toggleSidebar img,
  .searchToggle img,
  .dropdown-menu a img {
    filter: brightness(0) invert(1);
  }

  .settingsHead div span {
    background-color: #1b293f;
  }

  .sidebarHead span,
  .accordion-header .accordion-button span {
    color: #fff;
  }

  .accordion-body li {
    span,
    .form-group label {
      color: #fff;
    }
  }

  .settingsHead div,
  .settingItem h4,
  .orderTop ul li,
  .orderBlocks .card .userInfo .userDetails h4 {
    color: #fff;
  }

  .userSocial {
    span,
    ul li a {
      color: #fff;
    }
  }

  .settingItem {
    .c-img {
      background-color: #3c4045;
    }

    &:hover .c-img {
      background: #fff;
    }
  }

  .orderTop ul li .dropdown-toggle {
    background: linear-gradient(
      90deg,
      #e74c3c -0.02%,
      #fc6b14 99.99%
    ) !important;
  }

  .orderBlocks .card .c-head {
    background-color: #3c4045;
  }

  .userSocial {
    border-color: #1b293f;
  }

  .productCard {
    .p-img {
      background: #233654;
    }

    .p-data .form-group {
      .form-control,
      &.has-btns {
        background: #394b65;
        border-color: #233654;
      }

      > div::after {
        background: #525252;
      }

      div {
        .currency::before,
        .qty button {
          background: #525252;
        }
      }
    }
  }

  .listView .productCard .p-img img {
    background: #525252;
  }

  .productCard .p-data .form-group {
    div {
      .currency,
      .qty span {
        color: #fff;
      }
    }

    &.has-btns button,
    .form-control option {
      color: #fff;
    }

    &.last-row button {
      color: #fc6b14;
    }

    div .qty button svg {
      fill: #fff;
    }

    &.has-btns {
      button svg {
        fill: #fff;
      }

      &.last-row button svg {
        fill: #fc6b14;
      }
    }
  }

  .orderLayout button {
    svg {
      stroke: #fff;
    }

    &.gridLayout svg {
      fill: transparent;
    }
  }

  .listView .productCard .p-img {
    background: transparent;

    input[type="checkbox"] {
      background: #000;

      &:checked {
        background: #fc6b14;
      }
    }
  }

  .productDataModal {
    .modal-header,
    .form-group .form-body .controllerHolder .tox-toolbar,
    .modal-footer {
      background-color: #393c3e;
    }

    .modal-header .modal-title {
      color: #fff;
    }

    .form-group .form-body {
      label,
      .form-control option {
        color: #fff;
      }

      .col-12 .seoPreview {
        h1,
        p {
          color: #fff;
        }
      }
    }

    .modal-header .modal-title svg {
      fill: #fff;
    }

    .form-group {
      .form-head i svg {
        fill: #fff;
      }

      .form-body {
        .form-control,
        label.has-state input[type="checkbox"] {
          background-color: #3c4045 !important;
          border-color: #1b293f;
        }
      }

      border-color: #1b293f;
    }

    .modal-footer {
      border-color: #1b293f;
    }

    .form-group {
      .form-body .extraSelect {
        background: #1d1f20;
        color: #fff;
      }

      .form-head i {
        background: #1b293f;
      }

      .form-body .form-control:focus + svg {
        fill: #999999;
      }
    }
  }

  .tox-tinymce {
    border-color: #1b293f !important;
  }

  .tox .tox-tbtn,
  .tox-statusbar__path-item,
  .tox .tox-statusbar__wordcount,
  .mce-content-body p {
    color: #fff !important;
  }

  .tox .tox-tbtn svg,
  .tox-statusbar__resize-handle svg {
    fill: #fff !important;
  }

  .tox {
    .tox-tbtn:hover {
      background: #6e6e6e !important;
    }

    .tox-edit-area__iframe {
      background: #3c4045 !important;
      color: #fff !important;
    }

    .tox-statusbar {
      background: #3c4045 !important;
      color: #fff !important;
      border-color: #1b293f !important;
    }
  }

  .productDataModal
    .form-group
    .form-body
    .col-12
    .selectHolder
    .basic-single
    .select__control,
  .coupons-wrap .activate .layer {
    background: #3c4045;
  }

  .productDataModal {
    .nav .btn {
      color: #fff;
    }

    .form-group .form-body .col-12 {
      .marketCoupon {
        h4,
        p {
          color: #fff;
        }
      }

      .qty span {
        color: #fff;
      }
    }
  }

  h5,
  p,
  .payInfo {
    color: #fff;
  }

  .productsTable {
    .product a {
      color: #fff;
    }

    td {
      color: #fff;

      b {
        color: #fff;
      }
    }
  }

  .select__input,
  .tox .tox-edit-area__iframe * {
    color: #fff !important;
  }

  .shipInfo,
  .productsTable td,
  .historyInfo li {
    border-color: #1b293f;
  }

  .payInfo i {
    background: #3c4045;

    img {
      filter: brightness(0) invert(1);
    }
  }

  .orderBlocks .card.block.productsWrapper .c-head {
    background: #1b293f;
    border-bottom-color: #1b293f;
  }

  .productsTable th {
    background: #3c4045;
    color: #fff;
  }

  .productDataModal
    .form-group
    .form-body
    .multiUploadWrapper
    .form-group
    div.thumb
    label {
    background: #393c3e;

    span {
      color: #fff;
    }
  }

  .productCard .p-data .form-group .unlimited-qty {
    background: #3c4045;
    color: #fff;
  }

  .listView .productCard .newProduct.p-img {
    background: #525252;
  }

  .alert-warning {
    background-color: #3c4045;
    border-color: #3c4045;
  }

  .tox {
    .tox-toolbar,
    .tox-toolbar__overflow,
    .tox-toolbar__primary {
      background: #2c2c2c;
    }

    .tox-tbtn--enabled {
      background: #4f555c;

      &:hover {
        background: #4f555c;
      }
    }
  }

  .productCard {
    .p-data .form-group .form-control.disabled {
      background: #525252;
    }

    .p-img input[type="checkbox"] {
      // border-color: #1b293f;
      border: 1px solid #e1e1e1;
    }
  }

  .productsList .table th {
    background: #1b293f;
  }
}

.productsList .table {
  border-color: #6e6e6e;

  td {
    border-color: #6e6e6e;
  }
}

[data-theme="dark"] {
  .productsList .table {
    th,
    td,
    .listItem .listItemInfo a {
      color: #fff;
    }
  }

  .categories-wrapper .category-card {
    .item-header {
      background-color: #233654;
    }

    .accordion-item {
      .accordion-header .accordion-button .info {
        h4 {
          color: #fff;
        }

        svg.arrow {
          filter: brightness(0) invert(1);
        }
      }

      .accordion-body .category-card .accordion-item .item-header {
        border-right: 2px solid #32486b;
        background: rgb(37 53 77);
      }

      .actions .dropdown-menu {
        background: #25354d;
        border-color: #2b4267;

        .dropdown-item {
          svg {
            filter: brightness(0) invert(1);
          }

          &:hover {
            background: #1e2a3d;
          }
        }
      }
    }
  }

  .main-sidebar .stores-selection {
    border-bottom: 1px solid #1b293f;
  }

  .authWrap .au-img,
  .image-contain {
    background-color: #394b65;
  }

  .custom-css {
    background: #384964;
  }

  .setting-item {
    border-color: #55575b;

    .image-uploader .removeImg {
      background: #282c31;

      svg {
        fill: #fff;
      }
    }
  }
}

/* *********** LANGUAGE EN ***************** */

[dir="ltr"] {
  .head-wrp {
    padding: 29px 38px 29px 292px;
  }

  .burgerBtn {
    margin: 0 25px 0 0;
  }

  .searchWrap .form-group button {
    right: 5px;
    left: auto;
  }

  .main-sidebar {
    left: 0;
    right: auto;

    .side-nav {
      padding: 40px 10px 40px 23px;

      ul {
        padding: 0 15px 0 0;

        li a svg {
          margin: 0 13px 0 0;
        }
      }
    }
  }

  .side-actions a svg {
    margin: 0 13px 0 0;
  }

  .main-sidebar .side-nav .side-nav-child .arrow {
    right: 15px;
    left: auto;
  }

  .content-wrap {
    margin: 116px 0 0 292px;
  }

  .btn.btn-big svg {
    margin: 0 20px 0 0;
  }

  .control-wrp .card h5 img,
  .dropdown-menu a svg {
    margin: 0 10px 0 0;
  }

  .filter-wrp .form-group svg {
    left: auto;
    right: 20px;
  }

  .removeImg {
    right: -10px;
    left: auto;
  }

  .basic-single {
    direction: inherit;
  }

  .burgerBtn .toggleSidebar {
    transform: scaleX(-1);
  }

  &.sidebarToggled {
    .main-head .head-wrp {
      padding: 29px 38px 29px 125px;
    }

    .content-wrap {
      margin: 116px 0 0 125px;
    }
  }

  text-transform: capitalize;

  .form-select {
    background-position: right 0.75rem center;
  }
}

.no-after:after {
  content: none !important;
}

.dir-ltr {
  direction: ltr !important;
}

[data-theme="dark"] .quill {
  background: #a6a6a6;
}

.controls-header {
  max-width: 100%;

  // flex-wrap: wrap;
  // overflow: hidden;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  // * {
  //   max-height: 99%;
  // }
}

.workspace {
  overflow: auto;

  @media (min-width: 1024px) {
    overflow: hidden;
  }

  .workspace-sidebar.actions-sidebar
    .workspace-sidebar-body
    .uploadWrapper
    .image-uploader {
    padding: 10px;
  }

  .workspace-sidebar.actions-sidebar
    .workspace-sidebar-body
    .form-control[type="color"]::-webkit-color-swatch-wrapper {
    width: 100%;
    height: 100%;
    position: unset;
  }
}

[data-theme="dark"] .workspace-sidebar {
  .accordion-item {
    background: none;
  }

  .accordion-header {
    &:before {
      content: none;
    }
  }

  .accordion-button {
    color: white;
  }
}

button[type="submit"],
.modal-footer button[type="button"] {
  border-radius: 8px;
  width: 129px !important;
  height: 42px !important;
  max-width: 100%;
  padding-inline: 5px;
}

[data-theme="dark"] .text-muted {
  color: rgb(169 189 208 / 75%) !important;
}

.react-tel-input {
  &,
  * {
    direction: ltr;
  }
}

.noificationWrap .i-data h5 i {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.noificationWrap .i-data h5 i img {
  width: 15px;
  object-fit: contain;
  height: 15px;
}

.noificationWrap .i-data p {
  color: rgba(37, 37, 37, 0.7);
  max-height: none;
  overflow: visible;
  line-height: 1.4;
  margin-bottom: 5px;
}

.noificationWrap .i-data p b {
  font-family: "Bahij-semibold";
  font-size: inherit;
  color: inherit;
}

.noificationWrap .i-data h5 {
  font-family: "Bahij-bold";
  display: flex;
  align-items: center;
  gap: 7px;
}

.noificationWrap .i-data .not-footer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.noificationWrap .i-data .not-footer span {
  font-size: 13px;
  color: rgba(37, 37, 37, 0.5);
  [data-theme="dark"] & {
    color: #a3aed0;
  }
}

.noificationWrap .i-data .not-footer strong {
  display: flex;
  align-items: center;
  gap: 5px;
}

.notification-head button {
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 13px;
  font-family: "Bahij-semibold";
  color: #233654a3;
}

.notification-head {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding: 15px;
}

.noificationWrap .i-data .mark-as-read {
  border: 0;
  padding: 0;
  width: 10px;
  height: 10px;
  background: #a3aed0;
  border-radius: 100%;
}

.no-notifications {
  padding: 40px;
  text-align: center;
}
